import './LinkedApp.css';
import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Login from './user/login/Login';
import ReadHistory from './review/read_history/ReadHistory';
import ReportGenerator from './report_generator/ReportGenerator';
import LabTracking from './lab_tracking/LabTracking';
import Badganator from './baganator/Badganator';
// import PricingTool from './price_tool/PriceTool';
import ActiveBatch from './review/active_batch/ActiveBatch';
import PastBatch from './review/past_batch/PastBatch';
import ReviewScan from './review/active_scan/ReviewScan';
import Reports from './reports/Reports';
import BatchSummary from './review/batch_summary/BatchSummary';
import MonitorFreddy from './monitor/MonitorFreddy';
import UserRolesTable from './user/UserManagement';
import LabbClients from './labb_clients/LabbClients';
import Profile from './user/profile/Profile';
import Email from './email/Email';
import Header from './header/Header';
import LoadingTable from './Loading/LoadingTable';
import NoRolesGranted from './user/roles/no_roles_granted/NoRolesGranted';
import LabMroReports from "./reports/mro_reports/LabMroReports";
import Notifications from "./user/notifications/Notifications";
import SpecimenDetails from "./review/specimen_details/SpecimenDetails";
import {msalInstance} from "../index";
import LabBillingReports from "./reports/lab_billing_reports/LabBillingReports";
import CloseIcon from "../assets/icons/Close.svg";

function LinkedApp({updatedProps}) {

    const handleCloseNotificationModal = () => {
        updatedProps.updateShowNotificationModal()
    };


    function getHomePage() {
        if (updatedProps.userRoles.length === 0) {
            return <LoadingTable/>;
        }

        if (updatedProps.userRoles.includes('Labb.Billing.Reports')) {
            return (
                <LabBillingReports
                    userRoles={updatedProps.userRoles}
                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                    setErrorMessage={updatedProps.setErrorMessage}
                    errorMessage={updatedProps.errorMessage}
                    successMessage={updatedProps.successMessage}
                    setSuccessMessage={updatedProps.setSuccessMessage}
                    labStatusScreens={updatedProps.labStatusScreens}
                    setLabStatusScreens={updatedProps.setLabStatusScreens}
                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                />
            );
        } else if (updatedProps.userRoles.includes('Freddy.Inspector')) {
            return <Reports/>;
        } else if (updatedProps.userRoles.includes('Freddy.Pricanator')) {
            return <PricingTool/>;
        } else if (updatedProps.userRoles.includes('Freddy.Badganator')) {
            return <Badganator/>;
        } else if (updatedProps.userRoles.includes('Freddy.Reports')) {
            return (
                <LabTracking
                    userRoles={updatedProps.userRoles}
                    newLinkedEmail={updatedProps.newLinkedEmail}
                    newUnlinkedEmail={updatedProps.newUnlinkedEmail}
                    newSpecimenNotes={updatedProps.newSpecimenNotes}
                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                    setErrorMessage={updatedProps.setErrorMessage}
                    errorMessage={updatedProps.errorMessage}
                    successMessage={updatedProps.successMessage}
                    setSuccessMessage={updatedProps.setSuccessMessage}
                    setSortedScreens={updatedProps.setSortedScreens}
                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                />
            );
        } else {
            return <NoRolesGranted/>;
        }
    }

    return (
        <>
            <div>
                <Header
                    instance={msalInstance}
                    userRoles={updatedProps.userRoles}
                    specimenLinkedEmails={updatedProps.specimenLinkedEmails}
                    specimenUnlinkedEmails={updatedProps.specimenUnlinkedEmails}
                    setSpecimenUnlinkedEmails={updatedProps.setSpecimenUnlinkedEmails}
                    setSpecimenLinkedEmails={updatedProps.setSpecimenLinkedEmails}
                    supportEmailMessages={updatedProps.supportEmailMessages}
                    setSupportEmailMessages={updatedProps.setSupportEmailMessages}
                    unprocessedEmails={updatedProps.unprocessedEmails}
                    setUnprocessedEmails={updatedProps.setUnprocessedEmails}
                    exclusionsEmailList={updatedProps.exclusionsEmailList}
                    exclusionsSubjectList={updatedProps.exclusionsSubjectList}
                    initialState={updatedProps.initialState}
                    newLinkedEmail={updatedProps.newLinkedEmail}
                    setNewLinkedEmail={updatedProps.setNewLinkedEmail}
                    setNewUnlinkedEmail={updatedProps.setNewUnlinkedEmail}
                    newUnlinkedEmail={updatedProps.newUnlinkedEmail}
                    setErrorMessage={updatedProps.setErrorMessage}
                    errorMessage={updatedProps.errorMessage}
                    successMessage={updatedProps.successMessage}
                    setSuccessMessage={updatedProps.setSuccessMessage}
                    userLoginStatus={updatedProps.userLoginStatus}
                    setUserLoginStatus={updatedProps.setUserLoginStatus}
                    setSelectedNotification={updatedProps.setSelectedNotification}
                    selectedNotificationDetails={updatedProps.selectedNotificationDetails}
                    setSelectedNotificationDetails={updatedProps.setSelectedNotificationDetails}
                    specimenNotificationNotes={updatedProps.specimenNotificationNotes}
                    specimenUserNotifications={updatedProps.specimenUserNotifications}
                    setSpecimenNotificationNotes={updatedProps.setSpecimenNotificationNotes}
                    setSpecimenUserNotifications={updatedProps.setSpecimenUserNotifications}
                    setSelectSpecimenId={updatedProps.setSelectSpecimenId}
                    selectSpecimenId={updatedProps.selectSpecimenId}
                    showNotificationModal={updatedProps.showNotificationModal}
                    updateShowNotificationModal={updatedProps.updateShowNotificationModal}
                />
                {
                    updatedProps.showNotificationModal ?
                        <div className="notificaiton-modal-overlay">
                            <div className="notificaiton-modal-container-close-icon" onClick={handleCloseNotificationModal}>
                                <img src={CloseIcon} alt="Close" />
                            </div>
                            <div className="notificaiton-modal-content">
                                <Notifications
                                    userRoles={updatedProps.userRoles}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    userNotifications={updatedProps.userNotifications}
                                    setUserNotifications={updatedProps.setUserNotifications}
                                    newSpecimenNotes={updatedProps.newSpecimenNotes}
                                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                                    setSelectedNotification={updatedProps.setSelectedNotification}
                                    selectedNotificationDetails={updatedProps.selectedNotificationDetails}
                                    setSelectedNotificationDetails={updatedProps.setSelectedNotificationDetails}
                                    specimenNotificationNotes={updatedProps.specimenNotificationNotes}
                                    specimenUserNotifications={updatedProps.specimenUserNotifications}
                                    handleGetUserNotifications={updatedProps.handleGetUserNotifications}
                                    labStatusScreens={updatedProps.labStatusScreens}
                                    setSortedScreens={updatedProps.setSortedScreens}
                                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                                />
                            </div>
                        </div> : null
                }
                <Routes>
                    {updatedProps.userRoles ? (
                        <Route path="/" element={getHomePage}/>
                    ) : (
                        <Route path="/login" element={<Login instance={msalInstance} userLoginStatus={userLoginStatus}
                                                             setUserLoginStatus={setUserLoginStatus}/>}/>
                    )}
                    {updatedProps.userRoles.includes('Freddy.Reviewer') && (
                        <Route
                            path="/read-history"
                            element={<ReadHistory setErrorMessage={updatedProps.setErrorMessage}
                                                  errorMessage={updatedProps.errorMessage}/>}
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Analytics') && (
                        <Route
                            path="/report-generator"
                            element={
                                <ReportGenerator
                                    userRoles={updatedProps.userRoles}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Reports') && (
                        <Route
                            path="/lab-tracking"
                            element={
                                <LabTracking
                                    userRoles={updatedProps.userRoles}
                                    newLinkedEmail={updatedProps.newLinkedEmail}
                                    newUnlinkedEmail={updatedProps.newUnlinkedEmail}
                                    newSpecimenNotes={updatedProps.newSpecimenNotes}
                                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    setSortedScreens={updatedProps.setSortedScreens}
                                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Badganator') && (
                        <Route
                            path="/badganator"
                            element={
                                <Badganator
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Pricanator') && (
                        <Route
                            path="/pricing-tool"
                            element={
                                <PricingTool
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Inspector') && (
                        <Route
                            path="/active-batch"
                            element={
                                <ActiveBatch
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Inspector') && (
                        <Route
                            path="/past-batch"
                            element={
                                <PastBatch
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Reviewer') && (
                        <Route
                            path="/review-scan"
                            element={
                                <ReviewScan
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Inspector') && (
                        <Route
                            path="/reports"
                            element={
                                <Reports
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Inspector') && (
                        <Route
                            path="/batch-summary"
                            element={
                                <BatchSummary
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.Reviewer') && (
                        <Route
                            path="/monitor-freddy"
                            element={
                                <MonitorFreddy
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Freddy.SuperAdmin') && (
                        <Route
                            path="/users-management"
                            element={
                                <UserRolesTable
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Labb.Client.Management') && (
                        <Route
                            path="/clients"
                            element={
                                <LabbClients
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    userRoles={updatedProps.userRoles}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Labb.Billing.Reports') && (
                        <Route
                            path="/lab-billing-reports"
                            element={

                                <LabBillingReports
                                    userRoles={updatedProps.userRoles}
                                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    labStatusScreens={updatedProps.labStatusScreens}
                                    setLabStatusScreens={updatedProps.setLabStatusScreens}
                                    setSortedScreens={updatedProps.setSortedScreens}
                                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Labb.Specimen.Details') && (
                        <Route
                            path="/specimen-details"
                            element={
                                <SpecimenDetails
                                    userRoles={updatedProps.userRoles}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    selectSpecimenId={updatedProps.selectSpecimenId}
                                    setSelectSpecimenId={updatedProps.setSelectSpecimenId}
                                />
                            }
                        />
                    )}
                    {updatedProps.userRoles.includes('Labb.Mro.Reports') && (
                        <Route
                            path="/lab-mro-reports"
                            element={
                                <LabMroReports
                                    userRoles={updatedProps.userRoles}
                                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    labStatusScreens={updatedProps.labStatusScreens}
                                />
                            }
                        />
                    )}
                    <Route
                        path="/profile"
                        element={
                            <Profile
                                userRoles={updatedProps.userRoles}
                                setErrorMessage={updatedProps.setErrorMessage}
                                errorMessage={updatedProps.errorMessage}
                                successMessage={updatedProps.successMessage}
                                setSuccessMessage={updatedProps.setSuccessMessage}
                            />
                        }
                    />
                    {updatedProps.userRoles.includes('Labb.Email') && (
                        <Route
                            path="/email"
                            element={
                                <Email
                                    specimenLinkedEmails={updatedProps.specimenLinkedEmails}
                                    supportEmailMessages={updatedProps.supportEmailMessages}
                                    specimenUnlinkedEmails={updatedProps.specimenUnlinkedEmails}
                                    setSpecimenUnlinkedEmails={updatedProps.setSpecimenUnlinkedEmails}
                                    setSpecimenLinkedEmails={updatedProps.setSpecimenLinkedEmails}
                                    userRoles={updatedProps.userRoles}
                                    setErrorMessage={updatedProps.setErrorMessage}
                                    errorMessage={updatedProps.errorMessage}
                                    successMessage={updatedProps.successMessage}
                                    setSuccessMessage={updatedProps.setSuccessMessage}
                                    newLinkedEmail={updatedProps.newLinkedEmail}
                                    setNewLinkedEmail={updatedProps.setNewLinkedEmail}
                                    newUnlinkedEmail={updatedProps.newUnlinkedEmail}
                                    setNewUnlinkedEmail={updatedProps.setNewUnlinkedEmail}
                                    newSpecimenNotes={updatedProps.newSpecimenNotes}
                                    setNewSpecimenNotes={updatedProps.setNewSpecimenNotes}
                                    setSpecimenShowEmailModal={updatedProps.setSpecimenShowEmailModal}
                                />
                            }
                        />
                    )}
                </Routes>
            </div>
        </>
    );
}

export default LinkedApp;
