import HubspotTicketsList from "./HubspotTicketsList";
import CreateHubspotTicket from "./CreateHubspotTicket";
import LoadingTable from "../Loading/LoadingTable";
import "./HubspotTickets.css"
import axios from "axios";
import {config} from "../Constants";
import {useEffect, useState} from "react";

const HubspotTickets = ({userRoles, selectedAccountId}) => {
    const [hubspotTickets, setHubspotTickets] = useState([]);
    const [loadingHubspotTickets, setLoadingHubspotTickets] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");


    const fetchTickets = async () => {
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem("accessToken");

        axios({
            method: "GET",
            baseURL: url,
            url: "/get-hubspot-tickets",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((response) => {
                setHubspotTickets(response.data.reverse());
                setLoadingHubspotTickets(false);
            })
            .catch((error) => {
                setErrorMessage(`Error: ${error.message}`);
                console.error("There was an error!", error);
            });
    };


    useEffect(() => {
        fetchTickets();
    }, []);

    if (loadingHubspotTickets) {
        return <LoadingTable />;
    }

    return (
        <div className="hubspot-tickets-container">
            <div className="ticket-list-container">
                <div className="create-ticket-container">
                    <CreateHubspotTicket userRoles={userRoles} selectedAccountId={selectedAccountId} setErrorMessage={setErrorMessage}/>
                </div>
                <div className="tickets-list-container">
                    <HubspotTicketsList hubspotTickets={hubspotTickets} />
                </div>
            </div>
        </div>
    );
};

export default HubspotTickets;