import './NotificationList.css';
import { default as axios } from "axios";
import React, {useEffect, useRef, useState} from "react";
import LinkedEmailsList from "../../../email/manage_templates/manage_inbox/linked_emails_list/LinkedEmailsList";
import { config } from "../../../Constants";
import LinkSpecimenEmail from "../../../email/manage_templates/manage_inbox/link_specimen_email/LinkSpecimenEmail";
import NoteList from "../../../notes/note_list/NoteList";
import EmailNotification from "../../../email/email_notification/EmailNotification";
import AddSpecimenNote from "../../../notes/add_specimen_note/AddSpecimenNote";

function NotificationList({
                              setErrorMessage,
                              errorMessage,
                              successMessage,
                              setSuccessMessage,
                              setUserNotifications,
                              userNotifications,
                              selectedNotification,
                              setSelectedNotification,
                              selectedNotificationDetails,
                              setSelectedNotificationDetails,
                              onAddNote, // New prop to handle adding a note
                              onAcknowledgeNotification
                          }) {

    return (
        <div className="notification-list-container">
            {userNotifications.map((notification, index) => (
                <div key={index} className="notification-container">
                    <EmailNotification
                        note={notification.note}
                        created_at={notification.created_at}
                        select_screen_id={notification.select_screen_id}
                        subject={notification.subject}
                        onClick={() => onAddNote(notification.select_screen_id)}
                    />
                    <div className="acknowledge-button-container">
                        <button
                            className="acknowledge-button"
                            onClick={() => onAcknowledgeNotification(notification.user_notification_id)}
                        >
                            ACKNOWLEDGE
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NotificationList;
