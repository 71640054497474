const dev_reign = {
    url: {
        FREDDY_API_URL: 'https://reign.dev.freddy.backend.labbvision.com',
        REDIRECT_URI: 'https://reign.dev.freddy.app.labbvision.com/'
    }
};

const dev_labb = {
    url: {
        FREDDY_API_URL: 'https://labb.dev.freddy.backend.labbvision.com',
        REDIRECT_URI: 'https://labb.dev.freddy.app.labbvision.com/'
    }
};

const dev_remote = {
    url: {
        FREDDY_API_URL: 'http://localhost:5006',
        REDIRECT_URI: 'http://localhost:3001/'
    }
};

const dev = {
    url: {
        FREDDY_API_URL: 'https://dev.freddy.backend.labbvision.com',
        REDIRECT_URI: 'https://dev.freddy.app.labbvision.com/'
    }
};

const uat = {
    url: {
        FREDDY_API_URL: 'https://uat.freddy.backend.labbvision.com',
        REDIRECT_URI: 'https://uat.freddy.app.labbvision.com/'
    }
};

const prod = {
    url: {
        FREDDY_API_URL: 'https://freddy.scan.backend.labbvision.com',
        REDIRECT_URI: 'https://freddy.scan.app.labbvision.com/'
    }
};

let config = null;

console.log(process.env.NODE_ENV)

if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_ENV === 'dev-reign') {
        config = dev_reign;
    } else if (process.env.REACT_APP_ENV === 'dev-labb') {
        config = dev_labb;
    } else if (process.env.REACT_APP_ENV === 'dev-remote') {
        config = dev_remote;
    } else if (process.env.REACT_APP_ENV === 'dev') {
        config = dev;
    } else {
        throw new Error('Invalid development environment');
    }
} else if (process.env.NODE_ENV === 'production') {
    console.log(process.env.REACT_APP_ENV)
    if (process.env.REACT_APP_ENV === 'uat') {
        config = uat;
    } else if (process.env.REACT_APP_ENV === 'prod') {
        config = prod;
    } else {
        throw new Error('Invalid prod/uat environment');
    }
} else {
    throw new Error('Invalid environment');
}

export {config};
