import "./BillingSummaryTree.css"
import React, {useState} from 'react';
import {IconButton, Table} from 'rsuite';
import ContractIcon from "../../../../assets/icons/Contract.svg";
import ExpandIcon from "../../../../assets/icons/Expand.svg";
import ReportCheckIcon from "../../../../assets/icons/ReportCheck.svg";

const {Column, HeaderCell, Cell,} = Table;

const BillingSummaryTreeTable = ({
                                     billingSummaryDetails,
                                 }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [totalHeight, setTotalHeight] = useState();
    const updateTotalHeight = totalHeight => {
        setTotalHeight(totalHeight)
    }
    const rowKey = "id";

    const getCellPosRateTextColor = (value) => {
        return value > 10.00 ? 'red' : 'black';
    };
    const getCellDifferenceTextColor = (value) => {
        return value < 0.00 ? 'red' : 'green';
    };
    const handleRowExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        updateTotalHeight(rowData.lab_screens_info.length * 51 + 50)
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const ExpandCell = ({rowData, dataKey, expandedRowKeys, onChange, ...props}) => (
        <Cell {...props} style={{padding: 5}}>
            <IconButton
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    expandedRowKeys.includes(rowData[rowKey]) ? (
                        <img src={ContractIcon} alt="Expand"/>
                    ) : (
                        <img src={ExpandIcon} alt="Expand"/>
                    )
                }
            />
        </Cell>
    );


    // calcualte length of lab_screens_info then multiply by 75 to find
    // height of the table

    const renderScreensCell = rowData => {
        if (rowData.lab_screens_info.length > 0) {
            return (
                <div className="live-screen-info">
                    <Table
                        height={totalHeight}
                        virtualized
                        data={rowData.lab_screens_info}
                        rowKey="id">
                        <Column width={200} align="center">
                            <HeaderCell>Specimen ID</HeaderCell>
                            <Cell dataKey="device_id"/>
                        </Column>
                        <Column width={250} align="center">
                            <HeaderCell>Process Type</HeaderCell>
                            <Cell dataKey="process_type"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Collection Date</HeaderCell>
                            <Cell dataKey="collection_date"/>
                        </Column>
                        <Column width={100} align="center">
                            <HeaderCell>Product Name</HeaderCell>
                            <Cell dataKey="product_name"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Drugs Confirmed</HeaderCell>
                            <Cell dataKey="approved_drugs_confirmed"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Unapproved Drugs Confirmed</HeaderCell>
                            <Cell dataKey="qty_unapproved_drugs_confirmed"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Lab Result</HeaderCell>
                            <Cell dataKey="lab_result"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Lab Status Override</HeaderCell>
                            <Cell dataKey="lab_status_override"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>MRO Result</HeaderCell>
                            <Cell dataKey="mro_result"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>First Name</HeaderCell>
                            <Cell dataKey="donor_first_name"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Last Name</HeaderCell>
                            <Cell dataKey="donor_last_name"/>
                        </Column>
                        <Column width={100} align="center">
                            <HeaderCell></HeaderCell>
                            <Cell dataKey="screen_system_link">
                                {rowData => (
                                    <div onClick={() => window.open(rowData.screen_system_link)}
                                         className="screen-system-link-icon">
                                        <img src={ReportCheckIcon} alt="System Link"/>
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                </div>
            );
        }
        return null;
    };


    return (
        <div className="live-screens-table-frame">
            <div style={{width: "100%", overflowX: "auto"}}>

                <Table
                    rowKey="id"
                    expandedRowKeys={expandedRowKeys}
                    shouldUpdateScroll={false}
                    rowExpandedHeight={totalHeight}
                    height={800}
                    data={billingSummaryDetails}
                    renderRowExpanded={renderScreensCell}
                >
                    <Column width={300} align="left" fixed>
                        <HeaderCell>Client</HeaderCell>
                        <Cell dataKey="client_name"/>
                    </Column>
                    <Column width={100} align="center" fixed>
                        <HeaderCell>Positive Rate</HeaderCell>
                        <Cell style={{whiteSpace: 'nowrap'}}>
                            {rowData => (
                                <div style={{color: getCellPosRateTextColor(rowData.client_positive_rate)}}>
                                    {rowData.client_positive_rate}%
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={55} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell style={{fontSize: '9px'}} dataKey="previous_client_positive_rate">

                            {rowData => (
                                <div style={{color: getCellPosRateTextColor(rowData.previous_client_positive_rate)}}>
                                    {rowData.previous_client_positive_rate}%
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={55} align="center" fixed>
                        <HeaderCell>%Δ</HeaderCell>
                        <Cell style={{whiteSpace: 'nowrap', fontSize: '9px'}} dataKey="percent_client_positive_rate">
                            {rowData => (
                                <div style={{color: getCellDifferenceTextColor(rowData.percent_client_positive_rate)}}>
                                    {rowData.percent_client_positive_rate}%
                                </div>
                            )}
                        </Cell>
                    </Column>
                    {/*<Column width={100} align="center" fixed>*/}
                    {/*    <HeaderCell>Density</HeaderCell>*/}
                    {/*    <Cell dataKey="rapid_density_comparison"/>*/}
                    {/*</Column>*/}
                    {/*<Column width={55} align="center" fixed>*/}
                    {/*    <HeaderCell>∑</HeaderCell>*/}
                    {/*    <Cell style={{  fontSize: '9px' }} dataKey="previous_rapid_density_comparison" />*/}
                    {/*</Column>*/}
                    {/*<Column width={55} align="center" fixed>*/}
                    {/*    <HeaderCell>%Δ</HeaderCell>*/}
                    {/*    <Cell style={{  fontSize: '9px' }} dataKey="percent_previous_rapid_density_comparison" />*/}
                    {/*</Column>*/}
                    {/*<Column width={55} align="center" fixed>*/}
                    {/*    <HeaderCell>Δ</HeaderCell>*/}
                    {/*    <Cell style={{  fontSize: '9px' }} dataKey="difference_previous_rapid_density_comparison" />*/}
                    {/*</Column>*/}
                    <Column width={100} align="center" fixed>
                        <HeaderCell>Total Screens</HeaderCell>
                        <Cell dataKey="total_screens"/>
                    </Column>
                    <Column width={55} align="center" fixed>
                        <HeaderCell>∑</HeaderCell>
                        <Cell style={{fontSize: '9px'}} dataKey="previous_total_screens"/>
                    </Column>
                    <Column width={55} align="center" fixed>
                        <HeaderCell>Δ</HeaderCell>
                        <Cell style={{fontSize: '9px'}} dataKey="diff_total_screens"/>
                    </Column>
                    <Column width={55} align="center" fixed>
                        <HeaderCell>%Δ</HeaderCell>
                        <Cell style={{fontSize: '9px'}} dataKey="percent_total_screens">
                            {rowData => (
                                <div style={{color: getCellDifferenceTextColor(rowData.percent_total_screens)}}>
                                    {rowData.percent_total_screens}%
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column width={200} align="center" fixed>
                        <HeaderCell>Total MRO ALL Screens</HeaderCell>
                        <Cell dataKey="counted_mro_all"/>
                    </Column>
                    <Column width={150} align="center" fixed>
                        <HeaderCell>Screens</HeaderCell>
                        <ExpandCell dataKey="device_id" expandedRowKeys={expandedRowKeys}
                                    onChange={handleRowExpanded}>{renderScreensCell}</ExpandCell>
                    </Column>
                </Table>
            </div>
        </div>
    );
};

export default BillingSummaryTreeTable;
