import "./LabMroReports.css"
import "rsuite/dist/rsuite.min.css";
import makeAnimated from 'react-select/animated';
import "rsuite/dist/rsuite.min.css";
import React, {useEffect, useRef, useState} from "react";
import {DateRangePicker, Table,} from 'rsuite'
import CreatableSelect from 'react-select/creatable';
import {allowedMaxDays, afterToday, combine} from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import {socket} from "../../socket";
import LoadingTable from "../../Loading/LoadingTable";
import {default as axios} from "axios";
import {config} from "../../Constants";
import {subDays, subMonths} from 'date-fns';
import MroSummaryTreeTable from "./mro_summary_tree_table/MroSummaryTreeTable";


function LabMroReports(props) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    const [clientOptions, setClientOptions] = useState([]);
    const [selectDateOptions, setSelectDateOptions] = useState([]);
    const [selectClientOptions, setSelectClientOptions] = useState([]);
    const [mroSummaryUrl, setMroSummaryUrl] = useState([]);
    const [mroSummaryDetails, setMroSummaryDetails] = useState([]);
    const updateMroSummaryDetails = mroSummaryDetails => {
        setMroSummaryDetails(mroSummaryDetails)
    }
    const [loadingTable, setLoadingTable] = useState(false)
    const updateLoadingTable = () => setLoadingTable(value => !value)
    const [download, setDownload] = useState(false);
    const updateDownload = () => setDownload(value => !value);
    const hiddenFileInput = React.useRef(null);
    const animatedComponents = makeAnimated();

    const loadClientOptions = () => {
        const axios = require('axios').default;
        const element = document.querySelector('#post-request-error-handling .formData-id');
        axios({
            method: "POST",
            baseURL: url,
            url: "/get-client-options",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setClientOptions(response.data)
        })).catch(error => {
            element.parentElement.innerHTML = `Error: ${error.message}`;
            console.error('There was an error!', error);
        });
    }

    useEffect(() => loadClientOptions(), []);


    const handleCsvUploadClick = e => {
        hiddenFileInput.current.click();
    };

    function submitMroOptions() {
        const formData = new FormData();
        try {
            formData.set('select_options', JSON.stringify(selectOptions));
            updateLoadingTable();
            axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/start-get-mro-filtered-screens",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                const jobId = response.data['job_id'];
                const pollUrl = `${url}/get-mro-filtered-screens-result?job_id=${jobId}`;
                const pollInterval = setInterval(() => {
                    axios({
                        method: "GET",
                        baseURL: pollUrl,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    }).then(((response) => {
                        if (response.data['data']) {
                            clearInterval(pollInterval);
                            updateMroSummaryDetails(response.data['data']);
                            setMroSummaryUrl(response.data['billing_summary_url']);
                            updateDownload()
                            updateLoadingTable();
                        }
                    })).catch(error => {
                        updateLoadingTable();
                        const element = document;
                        // if an error occurs, create a link element and add it to the document
                        const link = element.createElement("a");
                        element.body.appendChild(link);

                        // show an error pop up with the error message
                        window.alert("An error occurred: " + error.message);
                        clearInterval(pollInterval);
                    });
                }, 10000); // poll every second
            })).catch(error => {
                updateLoadingTable();
                const element = document;
                // if an error occurs, create a link element and add it to the document
                const link = element.createElement("a");
                element.body.appendChild(link);
                // show an error pop up with the error message
                window.alert("An error occurred: " + error.message);
            });
        } catch (error) {
            updateLoadingTable();
            const element = document;
            // if an error occurs, create a link element and add it to the document
            const link = element.createElement("a");
            element.body.appendChild(link);

            // show an error pop up with the error message
            window.alert("An error occurred: " + error.message);
        }
    }

    const selectOptions = [
        {
            "date_range": selectDateOptions,
            "client_id": selectClientOptions,
        }
    ]


    // useEffect(() => {
    //     const url = config.url.FREDDY_API_URL;
    //     updateLoadingTable()
    //     try {
    //         axios({
    //             method: "GET",
    //             baseURL: url,
    //             url: "/get-mro-default-screens",
    //             headers: {
    //                 Authorization: 'Bearer ' + accessToken
    //             }
    //         }).then(((response) => {
    //             updateMroSummaryDetails(response.data);
    //             updateLoadingTable()
    //         })).catch(error => {
    //             element.parentElement.innerHTML = `Error: ${error.message}`;
    //             console.error('There was an error!', error);
    //         });
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, []);


    const handleClientChange = (selectedOption) => {
        setSelectClientOptions(selectedOption)
    };

    const handleDateChange = (e) => {
        setSelectDateOptions(e)
    };


    if (download && mroSummaryUrl) {
        updateDownload()
        const link = document.createElement("a")
        link.href = mroSummaryUrl
        document.body.appendChild(link);
        link.click()
    }

    function downloadMroSummary(e) {
        e.preventDefault();
        const url = mroSummaryUrl;
        const filename = "mro-summary.csv";
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];


    return (
        <div className="lab-billing-reports-main-frame">
            {loadingTable ? null :
                <div className="lab-billing-reports-header">
                    <div className="lab-billing-reports-header">
                        <div className="lab-billing-reports-date-picker" style={{marginRight: '100px'}}>
                            <DateRangePicker
                                showOneCalendar
                                disabledDate={combine(allowedMaxDays(365), afterToday())}
                                className="multi-selector-date"
                                appearance="default"
                                placeholder="Select Date Range..."
                                value={selectDateOptions}
                                onChange={handleDateChange}
                                ranges={[
                                    {
                                        label: 'Last Week',
                                        value: lastWeek,
                                    },
                                    {
                                        label: 'Last Month',
                                        value: lastMonth,
                                    },
                                    {
                                        label: 'Last 3 Months',
                                        value: last3Months,
                                    },
                                ]}
                            />
                        </div>
                        <div className="lab-billing-reports-client-selector" style={{marginRight: '100px'}}>
                            <CreatableSelect
                                components={animatedComponents}
                                placeholder="Select Clients..."
                                isMulti
                                className="multi-selector-clients"
                                closeMenuOnSelect={false}
                                options={clientOptions}
                                onChange={handleClientChange}
                            />
                        </div>
                        <div className="get-screens-button-frame" style={{marginRight: '100px'}}>
                            <button className="get-screens-button" onClick={(e) => submitMroOptions(e)}>
                                <label className="get-screens-button-text">
                                    GET SCREENS
                                </label>
                            </button>
                        </div>

                        <div className="pre-set-report-icon-frame"
                             onClick={(e) => downloadMroSummary(e)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3 21H21M17.8793 11H14.05C14.0224 11 14 10.9776 14 10.95V4C14 3.44772 13.5523 3 13 3H11C10.4477 3 10 3.44772 10 4V10.95C10 10.9776 9.97761 11 9.95 11H6.12071C6.07617 11 6.05386 11.0539 6.08536 11.0854L11.9646 16.9646C11.9842 16.9842 12.0158 16.9842 12.0354 16.9646L17.9146 11.0854C17.9461 11.0539 17.9238 11 17.8793 11Z"
                                    stroke="#657390" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            }
            <div className="lab-billing-reports-body">
                <div className="live-screens-table-frame">
                    {loadingTable ? <LoadingTable/> : < MroSummaryTreeTable mroSummaryDetails={mroSummaryDetails}/>}
                </div>
            </div>
        </div>
    )

}

export default LabMroReports;


