import React from "react";
import "./HubspotTicketsList.css";

const HubspotTicketsList = ({ hubspotTickets }) => (
    <div className="hubspot-tickets-list-container">
        <h2 className="hubspot-tickets-list-heading">Ticket List</h2>
        <div className="ticket-items">
            {hubspotTickets.map((ticket) => (
                <div key={ticket.id} className="ticket-item">
                    <div>
                        <h3 className="ticket-subject">{ticket.subject}</h3>
                    </div>
                    <div>
                        <p className="ticket-date">Created Date: {ticket.create_date}</p>
                    </div>
                    <div>
                        <p className="ticket-date">Last Modified Date: {ticket.last_modified_date}</p>
                    </div>
                    <div>
                        <p className="ticket-pipeline">Pipeline: {ticket.pipeline}</p>
                    </div>
                    <div>
                        <p className="ticket-pipeline-stage">Pipeline Stage: {ticket.pipeline_stage}</p>
                    </div>
                    <div>
                        <p className="ticket-priority">Ticket Priority: {ticket.ticket_priority}</p>
                    </div>
                    <div>
                        <p className="ticket-content">Content: {ticket.content}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default HubspotTicketsList;
