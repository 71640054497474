import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../Constants";
import "./Profile.css";

import ProfilePhoto from "../profile_photo/ProfilePhoto";

function Profile({ setIsMicrosoftAccountLinked }) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");
    const [username, setUsername] = useState("");

    const getUsername = async () => {
        try {
            const response = await axios.get(`${url}/get-linked-user-name`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log(response);
        } catch (error) {
            console.error("There was an error!", error);
        }
    };

    useEffect(() => {
        getUsername();
    }, []);

    const saveUsername = async (e) => {
        e.preventDefault();
        if (username) {
            const formData = new FormData();
            formData.append("user_name", username);
            try {
                const response = await axios.post(`${url}/set-linked-user-name`, formData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                console.log(response);
                setIsMicrosoftAccountLinked(response.data[0])
            } catch (error) {
                console.error("There was an error!", error);
            }
        }
    };

    return (
        <div className="main-body">
            <div className="set-username-card">
                <form onSubmit={saveUsername}>
                    <img
                        className="set-username-logo"
                        src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                        alt="Labb Logo"
                    />
                    <input
                        type="text"
                        placeholder="Create your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="set-username-form-body">
                        <div>
                            <button type="submit" className="submit">
                                SAVE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Profile;
