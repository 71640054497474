import React from "react";
import "./BatchData.css";

export default function BatchData({ batchInfo }) {
    return (
        <div className="batch-card-batch-data">
            <div className="batch-card-batch-id">
                <h2
                    className="batch-card-batch-header-text"
                    style={{ fontSize: "22px", display: "inline", color: "#58585A" }}
                >
                    {batchInfo.batch_report_id}
                </h2>
                <h2 className="batch-card-batch-header-text">.FV{batchInfo.freddy_version}</h2>
            </div>
            <div className="batch-card-scans">
                <h4 className="batch-card-batch-sub-text" style={{ marginTop: "-25px" }}>
                    {batchInfo.kit_count} POCT scans
                </h4>
            </div>
            <div className="batch-card-date">
                <h4 className="batch-card-batch-sub-text" style={{ marginTop: "-15px" }}>
                    {batchInfo.create_date}
                </h4>
            </div>
        </div>
    );
}
