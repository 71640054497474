import React from 'react';
import './DrugsWell.css';
import DrugCheckbox from "./DrugCheckbox";

export default function DrugsWell({drugMap, updateCheckedItems, checkedItems}) {
    return (
        <div className="drug-well-main-body">
            {drugMap.map((drugs) => {
                return (
                    <DrugCheckbox key={drugs[0]} checkedItems={checkedItems} updateCheckedItems={updateCheckedItems} drugs={drugs}/>
                )
            })}
        </div>
    )
}
