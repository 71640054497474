// labb-reader-react/src/components/socket.js

import { io } from 'socket.io-client';
import {config} from "./Constants";

const URL = config.url.FREDDY_API_URL;

const accessToken = localStorage.getItem('accessToken');

export const socket = io(URL, {
    auth: {
        token: accessToken
    }
});
