import React, { useState } from 'react';
import './ReviewScan.css';
import FeedbackPanel from './FeedbackPanel';
import ResultPanel from './ResultPanel';
import ReaderInput from '../reader_input/ReaderInput';
import { useNavigate, useLocation } from 'react-router-dom';
import { config } from '../../Constants';
import axios from "axios";

export default function ReviewScan(props) {
    const location = useLocation();
    const [checkedItems, setCheckedItems] = useState(new Map());
    const updateCheckedItems = (drugId, checked) => {
        setCheckedItems((prevState) => new Map(prevState).set(drugId, checked));
    };

    const inputActions = ['READ', 'READ & NEXT', 'LIGHT & NEXT'];
    const navigate = useNavigate(); // initialize useNavigate hook

    function handleSubmit(e) {
        e.preventDefault();
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem('accessToken');
        const formData = new FormData(e.target);

        axios({
            method: 'POST',
            baseURL: url,
            url: '/submit-review',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
            data: formData
        })
            .then((response) => {
                navigate('/read-history');
            })
            .catch((error) => {
                window.alert('An error occurred: ' + error.message);
                console.error('There was an error!', error);
            });
    }




    return (
        <div>
            <div className="active-scan-main-body">
                <form onSubmit={handleSubmit}>
                    <div className="active-scan-results-input-panel-frame">
                        <ReaderInput
                            readInfo={location.state.read_info}
                            inputActions={inputActions}
                        />
                        <ResultPanel
                            readInfo={location.state.read_info}
                            checkedItems={checkedItems}
                            updateCheckedItems={updateCheckedItems}
                        />
                    </div>
                    <div>
                        <FeedbackPanel
                            readInfo={location.state.read_info}
                            checkedItems={checkedItems}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
