import './Screener.css'

const Screener = ({
                      screenerDetails,
                      adminScreenerDetails,
                  }) => {

    return (
        <div>
            <section
                className="screener"
            >
                <div className="screener_header">
                    <p className="screener_header_text">Screener</p>
                </div>
                <div className="dark_grey_strip"/>
                <div className="screener_selection">

                    <p className="screener_name">{screenerDetails?.screener_first_name} {screenerDetails?.screener_last_name}</p>

                    <p style={{fontSize: "10px"}} className="screener_email">{screenerDetails?.screener_email}</p>
                </div>
            </section>

        </div>
    )
}

export default Screener;

