import React, {useEffect, useState} from 'react';
import './PastBatch.css';
import ScreenImage from "../ScreenImage";
import Header from "../../header/Header";
import {useLocation} from "react-router-dom";
import ReviewPastBatch from "./ReviewPastBatch";
import {config} from "../../Constants";

export default function PastBatch(props) {
    const [nextTestInfo, setNextTestInfo] = useState([]);
    const url = config.url.FREDDY_API_URL
    const updateNextTest = nextTestInfo => {
        setNextTestInfo(nextTestInfo)
    }
    const formData = new FormData();
    const location = useLocation();

    const axios = require('axios').default;
    formData.set('batch_report_id', location.state.batch_report_id);
    const element = document.querySelector('#post-request-error-handling .formData-id');

    useEffect(() => getTestInfo(), []);
    function getTestInfo() {
        const accessToken = localStorage.getItem("accessToken");
        axios({
            method: "POST",
            baseURL: url,
            url: "/review-result",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
                    setNextTestInfo(({
                        total_inspectors: response.data[0].total_inspectors,
                        inspectors_reviewed: response.data[0].inspectors_reviewed,
                        batch_position: response.data[0].batch_position,
                        select_test_id: response.data[0].select_test_id,
                        batch_report_id: response.data[0].batch_report_id,
                        kit_count: response.data[0].kit_count,
                        freddy_version: response.data[0].freddy_version,
                        create_date: response.data[0].create_date,
                        select_inference_status: response.data[0].select_inference_status,
                        select_test_url: response.data[0].select_test_url
                    }))
                })).catch(error => {
                element.parentElement.innerHTML = `Error: ${error.message}`;
                console.error('There was an error!', error);
            });
        }

    return (
        <div>
            <Header/>
            <div className="past-batch-main-body">
                <ScreenImage nextTestInfo={nextTestInfo}/>
                <ReviewPastBatch updateNextTest={updateNextTest} nextTestInfo={nextTestInfo} />
            </div>
        </div>
    )
}
