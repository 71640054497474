import {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import "./SendSpecimenEmailModal.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {config} from "../../../Constants";
import InitiateEmailSend from "../../send_email/InitiateEmailSend";

export default function SendSpecimenEmailModal({
                                                   selectedTemplate,
                                                   setIsSendEmailTemplateActive,
                                                   select_screen_id,
                                                   errorMessage,
                                                   setErrorMessage,
                                                   successMessage,
                                                   setSuccessMessage,
                                               }) {
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL;
    const [toField, setToField] = useState([]);
    const [ccField, setCCField] = useState([]);
    const [fromField, setFromField] = useState("");
    const [subjectField, setSubjectField] = useState(selectedTemplate ? selectedTemplate.email_subject : '');
    const [bodyField, setBodyField] = useState('');
    const [onSendEmail, setOnSendEmail] = useState(null);
    const [emailTemplateCategories, setEmailTemplateCategories] = useState({});
    const [emailCategoryId, setEmailCategoryId] = useState(selectedTemplate ? selectedTemplate.email_category_id : "");
    const [toOptions, setToOptions] = useState([]);
    const [ccOptions, setCcOptions] = useState([]);

    useEffect(() => {
        if (selectedTemplate) {
            setSubjectField(selectedTemplate.email_subject);
            setBodyField(selectedTemplate.email_body);
            setEmailCategoryId(selectedTemplate.email_category_id);
        }
    }, [selectedTemplate]);


    const getEmailTemplateCategories = () => {

        axios({
            method: "GET",
            baseURL: url,
            url: "/get-all-email-template-categories",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                setEmailTemplateCategories(response.data)
            })
            .catch((error) => {
                setErrorMessage("There was an error!", error)
                console.error("There was an error!", error);
            });
    };

    useEffect(() => {
        getEmailTemplateCategories();
    }, []);
    const getEmailToOptions = () => {

        const formData = new FormData();
        formData.append("email_template_id", selectedTemplate.email_template_id);
        formData.append("select_screen_id", select_screen_id);


        axios({
            method: "POST",
            baseURL: url,
            url: "/get-specimen-email-to-options",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json"
            },
            data: formData
        })
            .then((response) => {
                setToOptions(response.data)
            })
            .catch((error) => {
                setErrorMessage("There was an error!", error)
                console.error("There was an error!", error);
            });
    };

    useEffect(() => {
        getEmailToOptions();
    }, []);


    const getEmailCcOptions = () => {

        const formData = new FormData();
        formData.append("email_template_id", selectedTemplate.email_template_id);
        formData.append("select_screen_id", select_screen_id);

        axios({
            method: "POST",
            baseURL: url,
            url: "/get-specimen-email-cc-options",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json"
            },
            data: formData
        })
            .then((response) => {
                setCcOptions(response.data)
            })
            .catch((error) => {
                setErrorMessage("There was an error!", error)
                console.error("There was an error!", error);
            });
    };

    useEffect(() => {
        getEmailCcOptions();
    }, []);


    const handleToChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option);
        setToField(selectedValues);
    };

    const handleCcChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option);
        setCCField(selectedValues);
    };


    const handleFromChange = () => {
        const token = localStorage.getItem("emailAccessToken");
        const decodedToken = jwt_decode(token);
        console.log(decodedToken)
        const fromAddress = decodedToken.unique_name;

        setFromField(fromAddress);
    };

    useEffect(() => {
        handleFromChange();
    }, []);


    const handleSubjectChange = (event) => {
        setSubjectField(event.target.value);
    };

    const handleBodyChange = (event) => {
        setBodyField(event.target.value);
    };

    function handleCategoryChange(event) {
        setEmailCategoryId(event.target.value);
    }


    const handleSendSpecimenEmail = (event) => {
        event.preventDefault();

        // Check if email is already being sent
        if (onSendEmail) {
            return;
        }
        setOnSendEmail({
            subject: subjectField,
            body: bodyField,
            toRecipients: toField,
            ccRecipients: ccField,
            from: fromField
        });
    };

    return (
        <div className="add-email-template-container">
            <form onSubmit={handleSendSpecimenEmail}>
                <div className="input-field">
                    <label htmlFor="email-to">To:</label>
                    <CreatableSelect
                        id="email-to"
                        name="email-to"
                        placeholder="Select Users to To..."
                        closeMenuOnSelect={true}
                        value={
                           toOptions
                                ? toOptions.filter((option) =>
                                    toField.includes(option.email_address)
                                ).map((option) => ({
                                    label: `${option.contact_first_name} ${option.contact_last_name} | ${option.email_address}`,
                                    value: option.email_address
                                }))
                                : []
                        }
                        options={toOptions}
                        getOptionLabel={(option) =>
                            option.email_address
                                ? `${option.contact_first_name} ${option.contact_last_name} | ${option.email_address}`
                                : option.label
                        }
                        getOptionValue={(option) => option.email_address}
                        isMulti
                        formatCreateLabel={(inputValue) => `Create new option: ${inputValue}`}
                        onCreateOption={(inputValue) => {
                            const newOption = {
                                contact_first_name: inputValue,
                                contact_last_name: inputValue,
                                email_address: inputValue,
                            };
                            handleToChange([...toField, newOption]);
                        }}
                        onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions.map(
                                (option) => option.email_address
                            );
                            handleToChange(selectedValues);
                        }}
                    />
                </div>

                <div className="input-field">
                    <label htmlFor="email-cc">Cc:</label>
                    <CreatableSelect
                        id="email-cc"
                        name="email-cc"
                        placeholder="Select Users to CC..."
                        closeMenuOnSelect={false}
                        value={
                            ccOptions
                                ? ccOptions.filter((option) =>
                                    ccField.includes(option.email_address)
                                ).map((option) => ({
                                    label: `${option.contact_first_name} ${option.contact_last_name} | ${option.email_address}`,
                                    value: option.email_address
                                }))
                                : []
                        }
                        options={ccOptions}
                        getOptionLabel={(option) =>
                            option.email_address
                                ? `${option.contact_first_name} ${option.contact_last_name} | ${option.email_address}`
                                : option.label
                        }
                        getOptionValue={(option) => option.email_address}
                        isMulti
                        formatCreateLabel={(inputValue) => `Create new option: ${inputValue}`}
                        onCreateOption={(inputValue) => {
                            const newOption = {
                                contact_first_name: inputValue,
                                contact_last_name: inputValue,
                                email_address: inputValue,
                            };
                            handleCcChange([...ccField, newOption]);
                        }}
                        onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions.map(
                                (option) => option.email_address
                            );
                            handleCcChange(selectedValues);
                        }}
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="email-subject">Subject:</label>
                    <input
                        id="email-subject"
                        name="email-subject"
                        type="text"
                        value={subjectField}
                        onChange={handleSubjectChange}
                        required
                    />
                </div>
                <div className="email-template-categories">
                    <label htmlFor="email-category">Category:</label>
                    <select id="email-category" required name="email-category" value={emailCategoryId}
                            onChange={handleCategoryChange}>
                        <option value="">Select a category</option>
                        {Object.entries(emailTemplateCategories).map(([id, category]) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>

                </div>
                <div className="input-field">
                    <label htmlFor="email-body">Body:</label>
                    <textarea
                        id="email-body"
                        name="email-body"
                        value={bodyField}
                        onChange={handleBodyChange}
                        required
                    />
                </div>
                <div className="button-container">
                    <button type="submit" className="add-email-template-button">
                        SEND
                    </button>
                </div>
            </form>
            {onSendEmail && (
                <InitiateEmailSend
                    emailData={onSendEmail}
                    setEmailData={setOnSendEmail}
                    setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                    select_screen_id={select_screen_id}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                />
            )}
        </div>
    );
}
