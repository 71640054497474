import React, {useState, useEffect} from 'react';
import './Login.css';
import {loginRequest} from "../../../authConfig";
import * as msal from "@azure/msal-browser";
import {default as axios} from "axios";
import {config} from "../../Constants";

export default function Login({instance, setUserLoginStatus, userLoginStatus}) {
    const url = config.url.FREDDY_API_URL;
    const [accessToken, setAccessToken] = useState("");

    useEffect(() => {
        const getToken = async () => {
            try {
                const {accessToken} = await instance.acquireTokenSilent(loginRequest);
                setAccessToken(accessToken);
            } catch (error) {
                console.error(error);
            }
        };
        getToken();
    }, [instance]);

    async function handleUserLoginStatus() {
        try {
            const storedAccessToken = localStorage.getItem('accessToken');
            if (storedAccessToken) {
                const formData = new FormData();
                formData.append('login_status', '1');
                const response = await axios({
                    method: 'POST',
                    baseURL: url,
                    url: '/set-user-login-status',
                    data: formData,
                    headers: {
                        Authorization: 'Bearer ' + storedAccessToken,
                    },
                });

                if (response.status === 200) {
                    console.log('Success: User status set properly.');
                } else {
                    console.log('Error: User status not set properly.');
                }
            }
        } catch (error) {
            console.log(error); // Log the error
            // Show an error pop-up with the error message
            window.alert('An error occurred: ' + error.message);
        }
    }



    const handleLogin = async () => {
        try {
            const {accessToken, account} = await instance.acquireTokenPopup(loginRequest);
            localStorage.setItem("accessToken", accessToken);
            setAccessToken(accessToken);
            instance.setActiveAccount(account);
            await handleUserLoginStatus()

        } catch (error) {
            // Check if the error is a BrowserAuthError
            if (error instanceof msal.BrowserAuthError) {
                // Redirect the user to the login page
                await instance.loginRedirect(loginRequest);
            } else {
                console.error(error);
            }
        }
    };

    if (accessToken) {
        // redirect to authenticated page
        return null;
    }

    return (
        <div className="main-body">
            <div className="login-form-card">
                <img
                    className="login-logo"
                    src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                    alt="Labb Logo"
                />
                <div className="login-form-body">
                    <div>
                        <button className="submit" onClick={handleLogin} type="submit">
                            LOGIN
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
