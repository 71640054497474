import React, {useEffect, useState} from 'react';
import './ActiveBatch.css';
import Header from "../../header/Header";
import {useLocation} from "react-router-dom";
import {config} from "../../Constants";
import ReviewScreen from "../ReviewScreen";
import ReviewPastBatch from "../past_batch/ReviewPastBatch";
import ReviewActiveBatch from "./ReviewActiveBatch";

export default function ActiveBatch(props) {
    const [nextTestInfo, setNextTestInfo] = useState([]);
    const [reviewStrips, setReviewStrips] = useState(false);
    const url = config.url.FREDDY_API_URL
    const updateNextTest = nextTestInfo => {
        setNextTestInfo(nextTestInfo)
    }
    const updateStripImages = reviewStrips => {
        setReviewStrips(reviewStrips)
    }
    const formData = new FormData();
    const location = useLocation();

    const axios = require('axios').default;
    formData.set('batch_report_id', location.state.batch_report_id);
    const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    useEffect(() => getTestInfo(), [""]);

    function getTestInfo() {

        axios({
            method: "POST",
            baseURL: url,
            url: "/get-result",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setNextTestInfo(({
                select_test_status: response.data[0].select_test_status,
                total_inspectors: response.data[0].total_inspectors,
                inspectors_reviewed: response.data[0].inspectors_reviewed,
                batch_position: response.data[0].batch_position,
                select_test_id: response.data[0].select_test_id,
                batch_report_id: response.data[0].batch_report_id,
                kit_count: response.data[0].kit_count,
                freddy_version: response.data[0].freddy_version,
                create_date: response.data[0].create_date,
                select_inference_status: response.data[0].select_inference_status,
                select_test_url: response.data[0].select_test_url
            }))
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    if (nextTestInfo.select_test_status === 2) {
        return (
            <div>
                <div className="active-batch-main-body">
                    <ReviewScreen reviewStrips={reviewStrips} updateStripImages={updateStripImages} nextTestInfo={nextTestInfo} updateNextTest={updateNextTest} />
                    <ReviewActiveBatch updateNextTest={updateNextTest} nextTestInfo={nextTestInfo}/>
                </div>
            </div>
        )
    } else if (nextTestInfo.select_test_status === 1) {
        return (
            <div>
                <div className="active-batch-main-body">
                    <ReviewScreen reviewStrips={reviewStrips} updateStripImages={updateStripImages} nextTestInfo={nextTestInfo} updateNextTest={updateNextTest} />
                    <ReviewPastBatch updateNextTest={updateNextTest} nextTestInfo={nextTestInfo} />
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className="active-batch-main-body">
                    <ReviewScreen reviewStrips={reviewStrips} updateStripImages={updateStripImages} nextTestInfo={nextTestInfo} updateNextTest={updateNextTest} />
                    <ReviewPastBatch updateNextTest={updateNextTest} nextTestInfo={nextTestInfo} />
                </div>
            </div>
        )
    }
}
