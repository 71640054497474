import React, {useEffect, useState} from 'react';
import './ReadTile.css';
import {config} from "../../Constants";
import {useNavigate} from "react-router-dom";
// import {useNavigate} from "react-router-dom";

export default function ReadTile({activeScan}) {
    const [screenInfo, setScreenInfo] = useState('');

    const navigate = useNavigate();

    function clickSDNumber(e) {
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        const url = config.url.FREDDY_API_URL
        const axios = require('axios').default;
        const formData = new FormData();
        formData.set('sd_number', activeScan.sd_number);
        e.preventDefault();
        axios({
            method: "POST",
            baseURL: url,
            url: "/create-read",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            navigate('/review-scan', {state: {read_info: response.data}});
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });

    };


    return (
        <div>
            {
                activeScan ?
                    <div onClick={(e) => {
                        clickSDNumber(e);
                    }}
                         style={{
                             backgroundColor:
                                 (activeScan.status === 100) ? "rgba(126,32,240,0.3)" : // 100 status READ_STARTED = PURPLE
                                     (activeScan.status === 200) ? "rgba(62,224,230,0.3)" : // 200 status SECONDARY_SCREEN_REQUIRED = LIGHT BLUE
                                         (activeScan.status === 250) ? "rgba(226, 284, 10, 0.3)" : // 250 status RESCAN_REQUIRED = YELLOW
                                             (activeScan.status === 300) ? "rgba(48, 173, 123, 0.3)" : // 300 status SECONDARY_SCREEN_DONE = GREEN
                                                 (activeScan.status === 400) ? "rgba(240, 152, 32, 0.3)" : // 400 status AUTOMATED_READ_DONE = ORANGE
                                                     "#FFFFFF"
                         }}
                         className="read-tile-main-body">
                        <div className="read-tile-info-frame">
                            <div className="read-tile-text-frame">
                                <h3 className="read-tile-scan-info-text">{activeScan.donor_first_name} {activeScan.donor_last_name}</h3>
                                <h3 className="read-tile-scan-info-text">NA | {activeScan.created_at_time} |
                                    SD0{activeScan.sd_number}</h3>
                            </div>
                        </div>
                    </div> : null
            }
        </div>
    )
}
