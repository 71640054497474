import React from 'react';
import './Organization.css';

const Organization = ({ organizationDetails }) => {
    return (
        <div>
            <section>
                <div className="organization">
                    <div className="organization_header">
                        <p className="organization_header_text">Organization</p>
                    </div>
                    <div className="dark_grey_strip" />
                    <div className="organization_info_section">
                        <p>{organizationDetails?.organization_name}</p>
                    </div>
                </div>
            </section>

            <section>
                <div className="organization_header">
                    <p className="organization_header_text">Contact Details</p>
                </div>
                <div className="dark_grey_strip" />
                <div className="organization_info_section">
                    <p>{organizationDetails?.default_contact?.contact_name}</p>
                    <p>{organizationDetails?.default_contact?.contact_phone}</p>
                    <p>{organizationDetails?.default_contact?.contact_email}</p>
                </div>
            </section>

            <section>
                <div className="organization_header">
                    <p className="organization_header_text">Negative Contact Details</p>
                </div>
                <div className="dark_grey_strip" />
                <div className="organization_info_section">
                    <p>{organizationDetails?.negative_contact?.contact_name}</p>
                    <p>{organizationDetails?.negative_contact?.contact_phone}</p>
                    <p>{organizationDetails?.negative_contact?.contact_email}</p>
                </div>
            </section>

            <section>
                <div className="organization_header">
                    <p className="organization_header_text">Non-Negative Contact Details</p>
                </div>
                <div className="dark_grey_strip" />
                <div className="organization_info_section">
                    <p>{organizationDetails?.non_negative_contact?.contact_name}</p>
                    <p>{organizationDetails?.non_negative_contact?.contact_phone}</p>
                    <p>{organizationDetails?.non_negative_contact?.contact_email}</p>
                </div>
            </section>
        </div>
    );
};

export default Organization;
