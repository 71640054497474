import React from 'react';
import './HistoryActionItems.css';
import ReadNext from "./ReadNext";
import ReadFilter from "./ReadFilter";

export default function HistoryActionItems() {


    return (
        <div className="history-action-items-main-body">
            <div className="history-action-items-main-frame">
                <ReadNext />
                <ReadFilter />
            </div>
        </div>
    )
}
