import React from 'react';
import './ReadNext.css';
// import addNotification from 'react-push-notification';

export default function ReadNext() {

    const buttonClick = () => {
        // addNotification({
        //     title: 'SD0191837',
        //     subtitle: 'This is a read Notification',
        //     theme: 'darkblue',
        //     native: true // when using native, your OS will handle theming.
        // });
    };


    return (
        <div className="read-next-main-body">
            <button onClick={buttonClick} className="read-next-button"><h3 className="read-next-text">NEXT READ</h3></button>

            </div>
    )
}
