import React from 'react';
import './FeedbackPanel.css';

export default function FeedbackPanel({readInfo}) {

    const collectorFeedbackActions = ["BAD LIGHTING", "UNDERDEVELOPED", "STRIPS OBSTRUCTED", "DRY STRIPS", "SIDE ONE NEEDED", "SIDE TWO NEEDED"]

    return (
        <div className="feedback-panel-main-body">
            <div className="feedback-panel-frame">
            <div className="feedback-panel-buttons-frame">
                {collectorFeedbackActions.map((collectorFeedback) => {
                    return (
                        <button className="feedback-panel-button">{collectorFeedback}</button>
                    )
                })}
            </div>
            <div className="feedback-panel-donor-main-body">
                <div className="feedback-panel-donor-company-frame">
                    <h3 className="feedback-panel-donor-company-text">
                        Organization: {readInfo.collection_details.organization_name}
                    </h3>
                    <h3 className="feedback-panel-donor-company-text">
                        Donor: {readInfo.collection_details.donor_first_name} {readInfo.collection_details.donor_last_name }
                    </h3>
                </div>
                <div className="feedback-panel-collector-main-body">
                    <div className="feedback-panel-collector-frame">
                        <h3 className="feedback-panel-collector-company-text">
                            Collector: {readInfo.collection_details.client_name}
                        </h3>
                        <h3 className="feedback-panel-collector-company-text">
                            James Ramsey - 757.805.9652
                        </h3>
                    </div>
                </div>
            </div>
            <div className="feedback-panel-training-button-frame">
                <button className="feedback-panel-training-button">RECOMMEND TRAINING</button>
            </div>
        </div>
        </div>
    )
}
