import React, {useEffect, useState} from 'react';
import './InspectorAgreement.css'
import {config} from "../../Constants";


export default function InspectorAgreement ({nextTestInfo}) {
    const axios = require('axios').default;
    const url = config.url.FREDDY_API_URL
    const formData = new FormData();
    const [inspectorsAgreement, setInspectorsAgreement] = useState([]);

    useEffect(() => {
        async function getInspectorAgreement() {
            const accessToken = localStorage.getItem("accessToken");
            formData.set('select_test_id', nextTestInfo.select_test_id);
            try {
                const response = await axios({
                    method: "POST",
                    baseURL: url,
                    url: "/inspector-agreement",
                    data: formData,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
                setInspectorsAgreement(response.data);
            } catch (error) {
                console.error('There was an error!', error);
            }
        }
        getInspectorAgreement();
    }, [nextTestInfo.select_test_id]);



    return(
        <div className="inspector-agreement-main-body">
            {inspectorsAgreement.map((agreement) => {
                return(
                    <div className="inspector-agreement-single-inspector">
                        <div className="inspector-agreement-inspector-tile">
                            <div className="inspector-agreement-inspector-button">
                                <h3 key={agreement.id} className="inspector-agreement-inspector-initials-text">{agreement.initials}</h3>
                            </div>
                        </div>
                        <div  className="inspector-agreement-result-body">
                            <div className="inspector-agreement-result-card">
                                <h3 key={agreement.id} className="inspector-agreement-result-text">{agreement.result}</h3>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
