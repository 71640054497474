import React from 'react';
import './DrugCheckbox.css';

export default function DrugCheckbox({drugs, checkedItems, updateCheckedItems}) {

    const handleChange = (e) => {
        const checked = e.target.checked;
        updateCheckedItems(drugs[1], checked);
    }

    return (<div className="drug-checkbox-drug-main-body">
        <div className="drug-checkbox-drug-frame" key={drugs[0]}>
            <h4 className="drug-checkbox-drug-text">{drugs[1]}</h4>
            <div style={{
                backgroundColor: (drugs[2] === 2 && drugs[3] === 3) ? "#FAFAFA" : (drugs[2] === 1 && drugs[3] === 2) ? "#FAFAFA" : "#FFD2DA"
            }}
                 className="drug-checkbox-drug-checkbox-frame">
                <input name="checkedItems" onChange={handleChange} className="drug-checkbox-input"
                       type="checkbox" checked={checkedItems.get(drugs[0])}
                       defaultChecked={(drugs[1] === "C") ? true : (drugs[2] === 2 && drugs[3] === 3) ? true : (drugs[2] === 1 && drugs[3] === 2) ? true : false}
                       />
            </div>
        </div>
    </div>)
}

