import React from 'react';
import './DrugsGrid.css';
import DrugsWell from "./DrugsWell";

export default function DrugsGrid({readInfo, updateCheckedItems, checkedItems}) {
    return (
        <div className="drug-grid-main-body">
            {readInfo.map((drugMap, index) => {
                // console.log(drugMap)
                return (
                    <DrugsWell checkedItems={checkedItems} updateCheckedItems={updateCheckedItems} key={index}
                               drugMap={drugMap}/>
                )
            })}
        </div>
    )
}
