import React from 'react';

function ReportTabs({ goToBillingSummaryTable, isBillingReportsTableActive }) {
    return (
        <div className="lab-reports-tabs">
            <div className="lab-billing-reports-tab" onClick={goToBillingSummaryTable}>
                <div className="lab-billing-reports-tab-label">
                    <div className="lab-billing-reports-tab-button">
                        <h3>BILLING SUMMARY</h3>
                    </div>
                </div>
                {isBillingReportsTableActive ? <div className="lab-billing-reports-tab-underline"></div> : null}
            </div>
        </div>
    );
}

export default ReportTabs;