import React from 'react';
import './NoRolesGranted.css';

function NoRolesGranted({ getAccessToken }) {

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        getAccessToken();
    }

    return (
        <div className="no-roles-granted">
            <h4>No roles granted. Please contact Techno King for assistance.</h4>
        </div>
    );
}

export default NoRolesGranted;
