import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'rsuite';

export default function UserRolesTable() {
    const [users, setUsers] = useState([]);
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://graph.microsoft.com/v1.0/users?$select=displayName,id,userPrincipalName,assignedLicenses', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setUsers(response.data.value);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUsers();
    }, [accessToken]);


    return (
        <div className="user-roles-table">
            <Table data={users} autoHeight>
                <Table.Column width={150}>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.Cell dataKey="displayName" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Roles</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => (
                            <div className="role-checkboxes">
                                {rowData.assignedLicenses.map(license => (
                                    <div className="role-checkbox" key={license.skuId}>
                                        {license.skuId}
                                    </div>
                                ))}
                            </div>
                        )}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </div>
    );
}
