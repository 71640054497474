import './SpecimenDetails.css'
import Organization from "./organization/Organization";
import Donor from "./donor/Donor";
import Screener from "./screener/Screener";
import LabbClient from "./labb_client/LabbClient";
import ClientLocation from "./location/ClientLocation";
import React, {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import {FixedSizeList} from "react-window";
import {default as axios} from "axios";
import {config} from "../../Constants";
import ProductDetails from "./product_details/ProductDetails";
import SecondaryEvaluation from "./secondary_evaluation/SecondaryEvaluation";

function SpecimenDetails({
                             selectSpecimenId,
                             setSelectSpecimenId,
                             setErrorMessage,
                             errorMessage,
                             successMessage,
                             setSuccessMessage
                         }) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");
    const [recentSpecimenIds, setRecentSpecimenIds] = useState([]);
    const [donorDetails, setDonorDetails] = useState(null);
    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [locationDetails, setLocationDetails] = useState(null);
    const [screenerDetails, setScreenerDetails] = useState(null);
    const [clientDetails, setClientDetails] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [adminScreenerDetails, setAdminScreenerDetails] = useState(null);


    const loadRecentSpecimenIds = () => {
        const axios = require('axios').default;
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-recent-specimen-id",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setRecentSpecimenIds(response.data);
            })
            .catch(error => {
                setErrorMessage(`Error: ${error.message}`);
                console.error('There was an error!', error);
            });
    };

    useEffect(() => loadRecentSpecimenIds(), []);


    const handleSpecimenSearchSubmit = (e) => {
        e.preventDefault();
        const axios = require('axios').default;
        const formData = new FormData();
        formData.set('device_id', JSON.stringify(selectSpecimenId));
        console.log(formData)
        axios({
            method: "POST",
            baseURL: url,
            data: formData,
            url: "/get-specimen-complete-details",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setDonorDetails(response.data.donor_details)
                setOrganizationDetails(response.data.organization_details)
                setLocationDetails(response.data.location_details)
                setScreenerDetails(response.data.screener_details)
                setAdminScreenerDetails(response.data.admin_screener_details)
                setClientDetails(response.data.client_details)
                setProductDetails(response.data.product_details)
            })
            .catch(error => {
                setErrorMessage(`Error: ${error.message}`);
                console.error('There was an error!', error);
            });
    };


    const handleSpecimenIdSearch = (selectedOption) => {
        const selectedId = selectedOption.value; // Assuming only one value is selected
        setSelectSpecimenId(selectedId);
    };

    // Calculate the height of the virtualized list
    const calculateListHeight = () => {
        // Adjust this value to determine the desired height of each option
        const optionHeight = 35;
        // Adjust this value to determine the desired number of visible options
        const visibleOptions = 5;
        return optionHeight * visibleOptions;
    };


    return (
        <div className="test-input-main-body">
            <form onSubmit={handleSpecimenSearchSubmit}>
                <div className="lab-billing-reports-search">
                    <CreatableSelect
                        components={{
                            MenuList: (props) => {
                                const {options, children, maxHeight, getValue} = props;
                                const [value] = getValue();
                                const initialOffset = options.indexOf(value) * 35;

                                return (
                                    <FixedSizeList
                                        height={calculateListHeight()}
                                        itemCount={options.length}
                                        itemSize={35}
                                        initialScrollOffset={initialOffset}
                                        width="100%"// Assign a valid width value here
                                    >
                                        {({index, style}) => (
                                            <div style={style}>{children[index]}</div>
                                        )}
                                    </FixedSizeList>
                                );
                            },
                        }}
                        placeholder="Search Specimen IDs..."
                        isClearable
                        closeMenuOnSelect={true}
                        onChange={handleSpecimenIdSearch}
                        className="search-input"
                        options={recentSpecimenIds.map((specimenId) => ({
                            label: specimenId,
                            value: specimenId,
                        }))}
                    />
                    <button className="search-button">SEARCH</button>
                </div>
            </form>
            <div>
                {/*{selectSpecimenId ? (*/}
                {/*    <SecondaryEvaluation deviceId={selectSpecimenId} setErrorMessage={setErrorMessage} />*/}
                {/*) : null}*/}
                <ProductDetails productDetails={productDetails} />
                <LabbClient clientDetails={clientDetails} />
                <Screener screenerDetails={screenerDetails} adminScreenerDetails={adminScreenerDetails}/>
                <Donor donorDetails={donorDetails} />
                <Organization organizationDetails={organizationDetails} />
                <ClientLocation locationDetails={locationDetails} />
            </div>
        </div>

    );
}

export default SpecimenDetails;
