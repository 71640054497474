import React, {useEffect} from 'react';
import axios from 'axios';
import {config} from '../../../Constants';

const AssociateSpecimenEmails = ({
                                     emailMessages,
                                     setNewSpecimenNotes,
                                     newSpecimenNotes,
                                     errorMessage,
                                     setErrorMessage,
                                     successMessage,
                                     setSuccessMessage,
                                 }) => {
    const handleSaveFetchedEmail = (message) => {
        console.log(message);
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem('accessToken');

        if (!message.email_processed) {
            const formData = new FormData();
            if (message.subject) {
                formData.append('subject', message.subject);
            } else {
                formData.append('subject', "");
            }
            formData.append('select_screen_id', message.select_screen_id);
            if (message.message_id) {
                formData.append('message_id', message.message_id);
            } else {
                formData.append('message_id', message.id);
            }
            if (message.select_screen_id && message.body.filtered_content) {
                formData.append('note', message.body.filtered_content);
                axios({
                    method: 'POST',
                    baseURL: url,
                    url: '/add-specimen-note',
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const updateFormData = new FormData();
                            if (message.message_id) {
                                updateFormData.append('message_id', message.message_id);
                            } else {
                                updateFormData.append('message_id', message.id);
                            }
                            updateFormData.append("email_linked", message.email_linked)
                            axios({
                                method: 'POST',
                                baseURL: url,
                                url: '/update-processed-message-id',
                                data: updateFormData,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            });
                            const newNote = {
                                id: response.data.id,
                                note: note,
                                select_screen_id: select_screen_id,
                            };
                            getAllNotes(); // Refresh the notes list
                            setNewSpecimenNotes((prevNotes) => [...prevNotes, newNote]); // Add the new note to the list
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                // Only update the processed message ID
                const updateFormData = new FormData();
                if (message.message_id) {
                    updateFormData.append('message_id', message.message_id);
                } else {
                    updateFormData.append('message_id', message.id);
                }
                updateFormData.append("email_linked", message.email_linked)
                axios({
                    method: 'POST',
                    baseURL: url,
                    url: '/update-processed-message-id',
                    data: updateFormData,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    };

    useEffect(() => {
        emailMessages.forEach((message) => {
            handleSaveFetchedEmail(message);
        });
    }, []);

    return null; // This component doesn't render anything directly
};

export default AssociateSpecimenEmails;
