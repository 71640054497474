import {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import {config} from "../../../Constants";
import './AddEmailTemplate.css'
import axios from "axios";

export default function AddEmailTemplate({
                                             emailTemplateOptions,
                                             setEmailTemplateOptions,
                                             selectedTemplate,
                                             isAddEmailTemplateActive,
                                             setIsAddEmailTemplateActive

                                         }) {
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL; // this should be the URL to your backend
    const [nameField, setNameField] = useState("");
    const [descriptionField, setDescriptionField] = useState("");
    const [ccField, setCCField] = useState([]);
    const [toField, setToField] = useState([]);
    const [subjectField, setSubjectField] = useState("");
    const [bodyField, setBodyField] = useState("");
    const [emailTemplateCategories, setEmailTemplateCategories] = useState({});
    const [emailCategoryId, setEmailCategoryId] = useState(selectedTemplate ? selectedTemplate.email_category_id : "");

    useEffect(() => {
        if (selectedTemplate) {
            setNameField(selectedTemplate.email_template_name || "");
            setDescriptionField(selectedTemplate.email_template_description || "");
            setSubjectField(selectedTemplate.email_subject || "");
            setBodyField(selectedTemplate.email_body || "");
            setToField(selectedTemplate.default_contact_to ? selectedTemplate.default_contact_to.map((option) => ({
                value: option,
                label: option
            })) : []);
            setCCField(selectedTemplate.default_contact_cc ? selectedTemplate.default_contact_cc.map((option) => ({
                value: option,
                label: option
            })) : []);
            setEmailCategoryId(selectedTemplate.email_category_id || "");
        } else {
            setNameField("");
            setDescriptionField("");
            setSubjectField("");
            setBodyField("");
            setToField([]);
            setCCField([]);
            setEmailCategoryId("");
        }
    }, [selectedTemplate]);


    const handleNameChange = (event) => {
        setNameField(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescriptionField(event.target.value);
    };

    const handleSubjectChange = (event) => {
        setSubjectField(event.target.value);
    };

    const handleBodyChange = (event) => {
        setBodyField(event.target.value);
    };

    const handleToChange = (value, action) => {
        if (action.action === "create-option") {
            // Add the created option to the ccField array
            setToField([...toField, {value, label: value}]);
        } else {
            // Update the ccField array with the selected options
            setToField(value || []);
        }
    };

    const handleCcChange = (value, action) => {
        if (action.action === "create-option") {
            // Add the created option to the ccField array
            setCCField([...ccField, {value, label: value}]);
        } else {
            // Update the bccField array with the selected options
            setCCField(value || []);
        }
    };

    const getNextTemplateId = () => {

        // Find the maximum id in emailTemplateOptions
        const maxId = Math.max(...emailTemplateOptions.map(option => option.email_template_id));

        // Return maxId + 1
        return maxId + 1;
    };

    const handleTemplateSave = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("email_template_name", nameField);
        formData.append("email_description", descriptionField);
        formData.append("email_category_id", emailCategoryId);
        formData.append("email_subject", subjectField);
        formData.append("email_body", bodyField);
        formData.append("email_to", JSON.stringify(toField.map((field) => field.value)));
        formData.append("email_cc", JSON.stringify(ccField.map((field) => field.value)));

        // Only append email_template_id if it exists in the selectedTemplate
        if (selectedTemplate && selectedTemplate.email_template_id) {
            formData.append("email_template_id", selectedTemplate.email_template_id);
        } else {
            // If no selected template, generate a new id
            const nextTemplateId = getNextTemplateId();
            formData.append("email_template_id", nextTemplateId);
        }

        try {
            const response = await axios.post(`${url}/add-email-template`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setEmailTemplateOptions([...emailTemplateOptions, response.data]);
            setNameField("");
            setDescriptionField("");
            setSubjectField("");
            setBodyField("");
            setToField([]);
            setCCField([]);
            setEmailCategoryId("");
        } catch (error) {
            console.error("There was an error!", error);
        }
    };


    const getEmailTemplateCategories = () => {

        axios({
            method: "GET",
            baseURL: url,
            url: "/get-all-email-template-categories",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                setEmailTemplateCategories(response.data)
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };

    useEffect(() => {
        getEmailTemplateCategories();
    }, []);


    function handleCategoryChange(event) {
        setEmailCategoryId(event.target.value);
    }

    return (
        <div className="add-email-template-container">
            <form onSubmit={handleTemplateSave}>
                <div className="input-field">
                    <label htmlFor="template-name">Name:</label>
                    <input
                        id="template-name"
                        name="template-name"
                        type="text"
                        value={nameField || (selectedTemplate ? selectedTemplate.email_template_name : "")}
                        onChange={handleNameChange}
                        required
                    />
                </div>

                <div className="input-field">
                    <label htmlFor="template-description">Description:</label>
                    <textarea
                        id="template-description"
                        name="template-description"
                        value={descriptionField}
                        onChange={handleDescriptionChange}
                        required
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="email-to">To:</label>
                    <CreatableSelect
                        id="email-to"
                        name="email-cc"
                        placeholder="Select Users to To..."
                        closeMenuOnSelect={false}
                        value={toField}
                        options={[]}
                        isMulti
                        onChange={handleToChange}
                    />
                </div>

                <div className="input-field">
                    <label htmlFor="email-cc">Cc:</label>
                    <CreatableSelect
                        id="email-cc"
                        name="email-cc"
                        placeholder="Select Users to Cc..."
                        closeMenuOnSelect={false}
                        value={ccField}
                        options={[]}
                        isMulti
                        onChange={handleCcChange}
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="email-subject">Subject:</label>
                    <input
                        id="email-subject"
                        name="email-subject"
                        type="text"
                        value={subjectField}
                        onChange={handleSubjectChange}
                        required
                    />
                </div>
                <div className="email-template-categories">
                    <label htmlFor="email-category">Category:</label>
                    <select id="email-category" required name="email-category" value={emailCategoryId}
                            onChange={handleCategoryChange}>
                        <option value="">Select a category</option>
                        {Object.entries(emailTemplateCategories).map(([id, category]) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>

                </div>
                <div className="input-field">
                    <label htmlFor="email-body">Body:</label>
                    <textarea
                        id="email-body"
                        name="email-body"
                        value={bodyField}
                        onChange={handleBodyChange}
                        required
                    />
                </div>
                <div className="button-container">
                    <button type="submit" className="add-email-template-button">
                        {selectedTemplate ? "Update Template" : "Add Template"}
                    </button>
                </div>
            </form>
        </div>
    );


}
