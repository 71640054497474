import CloseIcon from '../../../assets/icons/Close.svg';


function ErrorMessage({ errorMessage, onClose }) {
    if (!errorMessage) return null;

    return (
        <div className="error-message">
            {errorMessage}
            <div onClick={onClose} className="close-button">
                <img src={CloseIcon} alt="Close" />
            </div>
        </div>
    );
}

export default ErrorMessage;
