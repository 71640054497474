import React, {useEffect, useState} from 'react';
import {config} from '../../../../Constants';
import axios from 'axios';
import './LinkSpecimenEmail.css';
import SendSpecimenEmail from "../../../send_specimen_email/SendSpecimenEmail";
import NoteList from "../../../../notes/note_list/NoteList";


const LinkSpecimenEmail = ({
                               specimenId,
                               userRoles,
                               showNoteModal,
                               setShowNoteModal,
                               handleOpenSendEmailModal,
                               setIsSendEmailTemplateActive,
                               emailTemplateOptions,
                               setEmailTemplateOptions,
                               selectedTemplate,
                               setSelectedTemplate,
                               filteredTemplates,
                               setFilteredTemplates,
                               isSendEmailModalOpen,
                               setSpecimenShowEmailModal,
                           }) => {
        const [note, setNote] = useState('');
        const [notesList, setNotesList] = useState([]);
        const [select_screen_id, setSelectScreenId] = useState(specimenId);


        useEffect(() => {
            const getAllNotes = async () => {
                try {
                    const url = config.url.FREDDY_API_URL;
                    const accessToken = localStorage.getItem('accessToken');
                    const formData = new FormData();
                    formData.append('select_screen_id', specimenId);
                    const response = await axios.post(
                        `${url}/get-all-notes`,
                        formData,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    setNotesList(response.data);
                } catch (error) {
                    console.error(error);
                }
            };

            getAllNotes();
        }, [select_screen_id]);

        const handleClose = () => {
            setShowNoteModal(false);
        };

        const handleSubmit = (event) => {
            event.preventDefault();
            // Implement your submit logic here
        };

        const handleChange = (event) => {
            setNote(event.target.value);
        };


        return (
            <div className="link-specimen-email-container">
                {
                    showNoteModal ?

                        <div className="add-specimen-note-body">
                            <div className="specimen-note-container">
                                <div className="add-specimen-note-close-container">
                                    <button onClick={handleClose} className="add-specimen-note-close-button">
                                        <svg
                                            className="add-specimen-note-close-icon"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12 12L5 19M5 5L12 12L5 19ZM5 5L12 12L19 5"
                                                stroke="#ABABAB"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="specimen-notes-history">
                                    {notesList ?
                                        <NoteList
                                            notesList={notesList}


                                    /> : null}
                                </div>
                                <div className="add-specimen-note">
                                    <div className="add-specimen-note-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="add-specimen-note-form">
                                                <label>
                    <textarea
                        required
                        className="add-specimen-note-input"
                        value={note}
                        onChange={handleChange}
                        title="Please enter a note before submitting."
                        minLength={10}
                    />
                                                </label>
                                                <div className="add-specimen-note-buttons">
                                                    <button
                                                        type="submit"
                                                        className="add-specimen-note-submit-btn"
                                                        onClick={(event) => {
                                                            handleSubmit(event);
                                                            setShowNoteModal(false);
                                                            getLabStatusScreens();
                                                        }}
                                                    >
                                                        SUBMIT & CLOSE
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="add-specimen-note-submit-btn"
                                                        onClick={(event) => {
                                                            handleSubmit(event);
                                                            getLabStatusScreens();
                                                        }}
                                                    >
                                                        SUBMIT
                                                    </button>

                                                    {userRoles.includes('Labb.Email.Send') ? (
                                                        <button className="send-email-icon" type="button"
                                                                onClick={handleOpenSendEmailModal}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Icon / Send">
                                                                    <g id="Send">
                                                                        <path
                                                                            id="Send_2"
                                                                            d="M15.8325 8.17463L10.109 13.9592L3.59944 9.88767C2.66675 9.30414 2.86077 7.88744 3.91572 7.57893L19.3712 3.05277C20.3373 2.76963 21.2326 3.67283 20.9456 4.642L16.3731 20.0868C16.0598 21.1432 14.6512 21.332 14.0732 20.3953L10.106 13.9602"
                                                                            stroke="#657390"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {isSendEmailModalOpen && (
                                <div className="specimen-email-modal-container">
                                    <SendSpecimenEmail
                                        setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={setSelectedTemplate}
                                        setEmailTemplateOptions={setEmailTemplateOptions}
                                        emailTemplateOptions={emailTemplateOptions}
                                        filteredTemplates={filteredTemplates}
                                        setFilteredTemplates={setFilteredTemplates}
                                        select_screen_id={`SD0${select_screen_id}`}
                                        setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                                    />
                                </div>
                            )}

                        </div>
                        : null
                }
            </div>
        );
    }
;

export default LinkSpecimenEmail;

