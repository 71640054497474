import "./EmailTemplatesList.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../Constants";



const EmailTemplatesList = ({
                                emailTemplateOptions,
                                selectedTemplate,
                                onTemplateSelect,
                                setEmailTemplateOptions,
                                searchTerm,
                                setFilteredTemplates
                            }) => {
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL;

    function getEmailTemplateOptions() {
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-email-template-options",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
            .then((response) => {
                const emailTemplateOptions = response.data;
                setEmailTemplateOptions(emailTemplateOptions);
                setFilteredTemplates(emailTemplateOptions);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }


    useEffect(() => getEmailTemplateOptions(), []);

    useEffect(() => {
        if (searchTerm) {
            const filtered = emailTemplateOptions.filter((template) =>
                template.email_template_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
            setFilteredTemplates(filtered);
        } else {
            setFilteredTemplates(emailTemplateOptions);
        }
    }, [emailTemplateOptions, searchTerm]);

    return (
        <div className="email-templates-list-container">
            <ul className="email-templates-list">
                {emailTemplateOptions.map((template) => (
                    <li
                        key={template.email_template_id}
                        className={
                            selectedTemplate && selectedTemplate.id === template.email_template_id
                                ? "selected"
                                : ""
                        }
                        onClick={() => {
                            onTemplateSelect(template);
                        }}
                    >
                        <h3>{template.email_template_name}</h3>
                        <p>{template.email_template_description}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EmailTemplatesList;
