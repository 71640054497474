import React, {useState} from 'react';
import './BatchSummaryCard.css'
import InspectorRead from "./InspectorRead";
import {config} from "../../Constants";
import {default as axios} from "axios";

export default function BatchSummaryCard({batchSummary}) {
    const [expandImage, setExpandImage] = useState(false)
    const updateExpandImage = () => setExpandImage(value => !value);


    const handleExpandImage = () => {
        updateExpandImage(true)
    }

    const closeReview = () => {
        updateExpandImage(false)
    };

    if (expandImage) {
        return (
            <div className="screen-expanded-image-frame">
                <button onClick={closeReview} className="screen-expanded-image-close-button">

                    <svg className="screen-expanded-image-close-icon" width="24" height="24" viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <img alt="kit" className="screen-expanded-image" src={batchSummary[0].img_url}/>
            </div>

        )
    } else {
        return (
            <div style={{
                backgroundColor:
                    (batchSummary[0].initial_result === batchSummary[0].reader_result) ? "#FFFFFF" : // if freddy result equals reader result turn tile WHITE
                        (batchSummary[0].initial_result === "RESCAN") ? "#FFFFFF" : // if freddy result equals rescan turn tile WHITE
                            (batchSummary[0].initial_result === "LOW CONFIDENCE ERROR") ? "rgba(250,194,130,0.71)" : // if low confidence turn tile ORANGE
                                (batchSummary[0].initial_result === "ERROR") ? "#FFFFFF" : // if error turn tile WHITE
                                    (batchSummary[0].initial_result === "SEND TO LAB") ? "#FFFFFF" : // if freddy result is send to lab turn tile WHITE
                                        (batchSummary[0].initial_result === "CAPILLARY ACTION") ? "#FFFFFF" : // if freddy result is capillary action turn tile WHITE
                                            (batchSummary[0].reader_result === "RESCAN") ? "#FFFFFF" : // if reader result is rescan turn tile WHITE
                                                (batchSummary[0].reader_result === "INVALID") ? "#FFFFFF" : // if reader result is invalid turn tile WHITE
                                                    (batchSummary[0].reader_result === "READER ERROR") ? "rgba(250,194,130,0.71)" : // if freddy result is reader error turn tile WHITE
                                                "rgb(255, 210, 218)" // else turn tile RED
            }} className="screen-summary-main-body-card">
                <div className="screen-info-main-body-card">
                    <div className="screen-batch-info">
                        <img onClick={handleExpandImage} alt="kit" className="screen-info-screen-thumbnail"
                             src={batchSummary[0].img_url}/>
                    </div>
                    <div className="screen-summary-result">
                        <div className="batch-summary-freddy-frame">
                            <h3 className="screen-info-freddy-result"> {batchSummary[0].initial_result}</h3>
                        </div>
                        <div className="batch-summary-freddy-frame">
                            <h2 className="batch-summary-freddy-text">Freddy's Analysis - </h2><h2
                            className="batch-summary-freddy-text">S{batchSummary[0].side_id}</h2>
                        </div>
                        <div className="batch-summary-freddy-frame">
                            <h3 className="screen-info-test-id">{batchSummary[0].test_id}.FV{batchSummary[0].freddy_version_id}</h3>
                        </div>
                        <div className="batch-summary-vertical-line-break"/>
                        <div className="screen-comparison-results">
                            <h3 className="screen-info-result">LR - {batchSummary[0].reader_result}</h3>
                            {
                                batchSummary[0].inspector_results.map((inspector_results, index) => {
                                    return (
                                        <InspectorRead key={index} inspector_results={inspector_results}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}
