import React, {useEffect, useState} from 'react';
import './Reports.css';
import BatchCard from "./batch_card/BatchCard";
import GenerateBatch from "./batch_card/GenerateBatch";
import {config} from "../Constants";
import BatchSummaryCard from "../review/batch_summary/BatchSummaryCard";
import {default as axios} from "axios";

export default function Reports(props) {
    const [batchInfo, setBatchInfo] = useState([]);
    const [batchSummary, setBatchSummary] = useState([]);

    useEffect(() => {
        let source = axios.CancelToken.source(); // create a cancel token source
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        const url = config.url.FREDDY_API_URL;

        let isMounted = true; // keep track of whether the component is mounted

        try {
            axios({
                method: "GET",
                baseURL: url,
                url: "/batches",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                cancelToken: source.token // set the cancel token on the request
            }).then(((response) => {
                if (isMounted) { // check if the component is still mounted before updating the state
                    setBatchInfo(response.data);
                }
            })).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.log(error);
        }

        // cleanup function to cancel the request if the component unmounts
        return () => {
            isMounted = false; // set isMounted to false when the component unmounts
            source.cancel('Request canceled due to component unmounting.');
        };
    }, []);


    return (
        <div>
            {batchSummary ?
                <div className="batch-summary-main-body">
                    <div className="batch-summary-main-grid">
                        {batchSummary.map((batchSummary) => {
                            return (
                                <BatchSummaryCard key={batchSummary[0].test_id} batchSummary={batchSummary}/>
                            )
                        })}
                    </div>
                </div>
                : null
            }

            {batchInfo ?
                <div className="reports-main-body">

                    <GenerateBatch batchInfo={batchInfo} token={props.token}/>
                    {batchInfo.map((batchInfo) => {
                        return (
                            <BatchCard token={props.token} key={batchInfo.batch_report_id} batchInfo={batchInfo}/>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}
