import React, {useEffect, useState} from 'react';
import BatchSummaryCard from "./BatchSummaryCard";
import {config} from "../../Constants";
import {useLocation} from "react-router-dom";
import './BatchSummary.css';

export default function BatchSummary({token}) {
    const [batchSummary, setBatchSummary] = useState([]);

    const location = useLocation();

    const getBatchSummary = () => {

        const formData = new FormData();
        const axios = require('axios').default;

        const accessToken = localStorage.getItem("accessToken");
        const url = config.url.FREDDY_API_URL

        formData.set('batch_report_id', location.state.batch_report_id);
        axios({
            method: "POST",
            baseURL: url,
            url: "/get-batch-summary",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            const res = response.data
            setBatchSummary(batchSummary.concat(res))
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    useEffect(() => getBatchSummary(), []);


    return (
        <div>
            <div className="batch-summary-main-body">
                <div className="batch-summary-main-grid">
                    {batchSummary.map((batchSummary) => {
                        return (
                            <BatchSummaryCard token={token} key={batchSummary[0].test_id} batchSummary={batchSummary}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
