import React, {useState} from 'react';
import './ManageInbox.css';
import UnlinkedEmailsList from "./specimen_unlinked_emails/SpecimenUnlinkedEmails";
import LinkedEmailsList from "./linked_emails_list/LinkedEmailsList";
import LinkSpecimenEmail from "./link_specimen_email/LinkSpecimenEmail";
import SupportEmailList from "./support_email_list/SupportEmailList";

const ManageInbox = ({
                         specimenLinkedEmails,
                         specimenUnlinkedEmails,
                         supportEmailMessages,
                         handleItemClick,
                         setSelectedMessage,
                         newLinkedEmail,
                         setNewLinkedEmail,
                         newUnlinkedEmail,
                         setNewUnlinkedEmail,
                         newSpecimenNotes,
                         setNewSpecimenNotes
                     }) => {
    return (
        <div className="manage-inbox-container">
            <div className="emails-container">
                <div className="scrollable-container">
                    <LinkedEmailsList
                        specimenLinkedEmails={specimenLinkedEmails}
                        handleItemClick={handleItemClick}
                        setSelectedMessage={setSelectedMessage}
                        newLinkedEmail={newLinkedEmail}
                        setNewLinkedEmail={setNewLinkedEmail}
                        newUnlinkedEmail={newUnlinkedEmail}
                        setNewUnlinkedEmail={setNewUnlinkedEmail}
                        newSpecimenNotes={newSpecimenNotes}
                        setNewSpecimenNotes={setNewSpecimenNotes}
                    />
                </div>
                <div className="scrollable-container">
                    <UnlinkedEmailsList
                        specimenUnlinkedEmails={specimenUnlinkedEmails}
                        handleItemClick={handleItemClick}
                        setSelectedMessage={setSelectedMessage}
                        newLinkedEmail={newLinkedEmail}
                        setNewLinkedEmail={setNewLinkedEmail}
                        newUnlinkedEmail={newUnlinkedEmail}
                        setNewUnlinkedEmail={setNewUnlinkedEmail}
                        newSpecimenNotes={newSpecimenNotes}
                        setNewSpecimenNotes={setNewSpecimenNotes}
                    />
                </div>
                <div className="scrollable-container">
                    <SupportEmailList
                        supportEmailMessages={supportEmailMessages}
                        setSelectedMessage={setSelectedMessage}
                        handleItemClick={handleItemClick}
                        newLinkedEmail={newLinkedEmail}
                        setNewLinkedEmail={setNewLinkedEmail}
                        newUnlinkedEmail={newUnlinkedEmail}
                        setNewUnlinkedEmail={setNewUnlinkedEmail}
                        newSpecimenNotes={newSpecimenNotes}
                        setNewSpecimenNotes={setNewSpecimenNotes}
                    />
                </div>
            </div>
        </div>
    );
};

export default ManageInbox;
