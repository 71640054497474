import React from 'react';
import './ReadTiles.css';
import ReadTile from "./ReadTile";

export default function ReadTiles({readHistory}) {

    return (
        <div>
            <div className="read-tiles-main-body">
                    {readHistory.map((activeScan) => {
                        return (
                            <ReadTile key={activeScan.sd_number}  activeScan={activeScan}/>
                        )
                    })}
            </div>
        </div>
    )
}
