import React, {useEffect, useState} from 'react';
import './AddSpecimenNote.css';
import axios from 'axios';
import NoteList from "../note_list/NoteList";
import {config} from "../../Constants";
import CloseIcon from "../../../../src/assets/icons/Close.svg"
import SendIcon from "../../../assets/icons/Send.svg";
import RefreshIcon from "../../../assets/icons/Refresh.svg";
import SendSpecimenEmail from "../../email/send_specimen_email/SendSpecimenEmail";
import SpecimenActionItems from "../specimen_action_items/SpecimenActionItems";

export default function AddSpecimenNote({
                                            userRoles,
                                            select_screen_id,
                                            setShowNoteModal,
                                            selectedRow,
                                            setIsSendEmailTemplateActive,
                                            emailTemplateOptions,
                                            setEmailTemplateOptions,
                                            selectedTemplate,
                                            setSelectedTemplate,
                                            filteredTemplates,
                                            setFilteredTemplates,
                                            newSpecimenNotes,
                                            setNewSpecimenNotes,
                                            setSelectedRow,
                                            setErrorMessage,
                                            setSuccessMessage,
                                            labStatusScreens,
                                            setSortedScreens,
                                            setPendingUploadScreens,
                                            pendingUploadScreens,
                                            setPendingReviewScreens,
                                            setAttentionRequiredScreens,
                                            setApprovedScreens,
                                            setCompleteScreens,
                                            setLabStatusScreens,
                                            setSentToLabScreens,
                                            setSentToMroScreens,
                                            setSendToLabScreens,
                                            setSendToMroScreens,
                                            sortLabStatusScreens,
                                            rowData,
                                            setShowEmailModal,
                                            setSpecimenShowEmailModal,
                                        }) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");
    const [note, setNote] = useState('');
    const [notesList, setNotesList] = useState([]);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [textSuggestions, setTextSuggestions] = useState([]);
    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
    const [originalNote, setOriginalNote] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(true);


    const handleGenerateSuggestions = async () => {
        const formData = new FormData();
        const modelInstructions =
            "Please review the provided text and select one of the following options: " +
            "Propose a solution based on the information provided in the text. " +
            "Rephrase the text in a professional manner to request additional information from the user regarding their " +
            "subject matter. Please refrain from adding any fabricated content; only use information from the text provided. " +
            "Format your response in paragraph form."
        // Append mentionedUsersJSON to formData
        formData.append("text", note);
        formData.append("model_instructions", modelInstructions);
        setOriginalNote(note)
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                url: `/get-text-suggestions`,
                data: formData,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            // Process the response
            const suggestions = response.data.data.text;

            // Update the text suggestions state variable
            setTextSuggestions(suggestions);
            {
                textSuggestions ?
                    setShowSuggestions(true) : null
            }
        } catch (error) {
            setErrorMessage(error);
            console.error(error);
        }
    };

    const handleChange = (event) => {
        const newText = event.target.value;
        setNote(newText); // Update the note state variable
    };
    const handleClose = () => {
        setShowNoteModal(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (note.length < 10) {
            setErrorMessage('Note must be at least 10 characters long');
            return;
        }

        const formData = new FormData();
        formData.append("note", note);
        formData.append("select_screen_id", select_screen_id);

        // Check if there are selected users
        if (selectedUsers.length > 0) {
            // Convert mentionedUserIds to JSON array
            const mentionedUserIds = selectedUsers.map((user) => user.user_id);
            const mentionedUsersJSON = JSON.stringify(mentionedUserIds);

            // Append mentionedUsersJSON to formData
            formData.append("mentioned_users", mentionedUsersJSON);
        }

        axios({
            method: "POST",
            baseURL: url,
            url: `/add-specimen-note`,
            data: formData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                const updatedScreen = response.data.data;

                // Create a new copy of labStatusScreens
                let updatedScreens = [...labStatusScreens];

                // Find the index of the updated screen in the current labStatusScreens array
                const updatedIndex = updatedScreens.findIndex(
                    (screen) => screen.device_id === updatedScreen.device_id
                );

                if (updatedIndex !== -1) {

                    // Replace the screen at updatedIndex with the updatedScreen
                    updatedScreens[updatedIndex] = updatedScreen;

                    const {
                        pendingUploadScreens,
                        pendingReviewScreens,
                        attentionRequiredScreens,
                        approvedScreens,
                        completeScreens,
                        sendToLabScreens,
                        sentToLabScreens,
                        sendToMroScreens,
                        sentToMroScreens,
                        sortedScreens,
                    } = sortLabStatusScreens(updatedScreens);

                    setPendingUploadScreens(pendingUploadScreens);
                    setPendingReviewScreens(pendingReviewScreens);
                    setAttentionRequiredScreens(attentionRequiredScreens);
                    setApprovedScreens(approvedScreens);
                    setCompleteScreens(completeScreens);
                    setLabStatusScreens(sortedScreens); // Here we are setting the new state
                    setSentToLabScreens(sentToLabScreens);
                    setSentToMroScreens(sentToMroScreens);
                    setSendToLabScreens(sendToLabScreens);
                    setSendToMroScreens(sendToMroScreens);
                }

                const message = response.data.message;
                setSuccessMessage(message);
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
            });
    };


    function getAllNotes() {
        const formData = new FormData();
        formData.append('select_screen_id', select_screen_id);
        axios({
            method: 'POST',
            baseURL: url,
            url: '/get-all-notes',
            data: formData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setNotesList(response.data);
            })
            .catch((error) => {
                setErrorMessage(error.response.data)
                console.error(error);
            });
    }

    useEffect(() => {
        getAllNotes();
    }, []);

    const handleOpenSendEmailModal = () => {
        setIsSendEmailModalOpen(true);
    };

    const handleCloseSendEmailModal = () => {
        setIsSendEmailModalOpen(false);
    };

    const handleMention = (event) => {
        const inputValue = event.target.value;
        const lastIndex = inputValue.lastIndexOf('@');
        if (lastIndex !== -1 && inputValue.charAt(lastIndex - 1) !== ' ') {
            const searchTerm = inputValue.slice(lastIndex + 1);
            const filteredUsers = allUsers.filter((user) =>
                user.user_name.includes(searchTerm)
            );
            setSuggestedUsers(filteredUsers);
        } else {
            setSuggestedUsers([]);
        }
    };

    const handleUserSelect = (user) => {
        setNote((prevNote) => {
            const noteWithoutMention = prevNote.replace(/@\S+\s?/, '');
            return `${noteWithoutMention}@${user.user_name} `;
        });
        setSelectedUsers((prevUsers) => [...prevUsers, {user_id: user.user_id, user_name: user.user_name}]);
        setSuggestedUsers([]);
    };


    const getAllUsers = () => {
        axios({
            method: 'GET',
            baseURL: url,
            url: '/get-all-users',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setAllUsers(response.data);
            })
            .catch((error) => {
                setErrorMessage(error.response.data)
                console.error(error);
            });
    };


    useEffect(() => {
        getAllUsers();
    }, []);


    return (
        <div className="add-specimen-note-body">
            <div className="specimen-note-container">
                <div className="add-specimen-note-close-container">
                    <div onClick={handleClose} className="add-specimen-note-close-button">
                        <img src={CloseIcon} alt="Close"/>
                    </div>
                </div>
                <div className="specimen-notes-history">
                    {notesList[0] ? (
                        <NoteList notesList={notesList} setErrorMessage={setErrorMessage} setIsSendEmailModalOpen={setIsSendEmailModalOpen}/>
                    ) : null}
                </div>
                <div className="add-specimen-note">
                    <div className="add-specimen-note-form">
                        <form onSubmit={handleSubmit}>
                            <div className="add-specimen-note-form">
                                {selectedUsers.length > 0 && (
                                    <ul className="selected-users-list">
                                        {selectedUsers.map((user) => (
                                            <li key={user.user_id}>{user.user_name}</li>
                                        ))}
                                    </ul>
                                )}
                                <label>
                  <textarea
                      required
                      className="add-specimen-note-input"
                      value={note}
                      onChange={handleChange}
                      onKeyDown={handleMention}
                      title="Please enter a note before submitting."
                      minLength={10}
                  />
                                </label>

                                {/*<div className="note-modal-column">*/}
                                {/*    <SpecimenActionItems*/}
                                {/*        userRoles={userRoles}*/}
                                {/*        selectedRow={selectedRow}*/}
                                {/*        rowData={rowData}*/}
                                {/*        setSelectedRow={row => setSelectedRow(row.device_id)}*/}
                                {/*        setErrorMessage={setErrorMessage}*/}
                                {/*        setSuccessMessage={setSuccessMessage}*/}
                                {/*        labStatusScreens={labStatusScreens}*/}
                                {/*        setSortedScreens={setSortedScreens}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {userRoles.includes('Freddy.Suggester') && (
                                    <div
                                        onClick={handleGenerateSuggestions}
                                        className="generate-suggestions-button"
                                    >
                                        <img src={RefreshIcon} alt="Generate"/>
                                    </div>
                                )}
                                {suggestedUsers.length > 0 && (
                                    <ul className="suggested-users-list">
                                        {suggestedUsers.map((user) => (
                                            <li key={user.id} onClick={() => handleUserSelect(user)}>
                                                {user.user_name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {textSuggestions.length > 0 && showSuggestions && (
                                    <ul className="suggested-text">
                                        <div className="close-icon" onClick={() => setShowSuggestions(false)}>
                                            <img src={CloseIcon} alt="Close"/>
                                        </div>
                                        <div className="original-text">
                                            <p>{originalNote}</p>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setNote(textSuggestions);
                                            }}
                                        >
                                            {textSuggestions}
                                        </div>

                                    </ul>
                                )}
                                <div className="add-specimen-note-buttons">
                                    <button
                                        type="submit"
                                        className="add-specimen-note-submit-btn"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleSubmit(event);
                                            setShowNoteModal(false);
                                        }}
                                    >
                                        SUBMIT & CLOSE
                                    </button>
                                    <button
                                        type="submit"
                                        className="add-specimen-note-submit-btn"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleSubmit(event);
                                        }}
                                    >
                                        SUBMIT
                                    </button>
                                    {userRoles.includes('Labb.Email.Send') &&
                                        <div
                                            className="send-email-icon"
                                            onClick={handleOpenSendEmailModal}
                                        >
                                            <img src={SendIcon} alt="Send"/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {isSendEmailModalOpen && (
                <div className="specimen-email-modal-container">
                    <SendSpecimenEmail
                        userRoles={userRoles}
                        setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        setEmailTemplateOptions={setEmailTemplateOptions}
                        emailTemplateOptions={emailTemplateOptions}
                        filteredTemplates={filteredTemplates}
                        setFilteredTemplates={setFilteredTemplates}
                        select_screen_id={select_screen_id}
                        setErrorMessage={setErrorMessage}
                        setShowSuggestions={setShowSuggestions}
                        setShowEmailModal={setShowEmailModal}
                        setSpecimenShowEmailModa={setSpecimenShowEmailModal}
                    />
                </div>
            )}
        </div>
    );
}
