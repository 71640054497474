import {useEffect, useState} from "react";
import axios from "axios";
import "./InitiateEmailSend.css";
import getUserEmail from "../../user/email/Email";
import {config} from "../../Constants";

export default function InitiateEmailSend({
                                              emailData,
                                              setIsSendEmailTemplateActive,
                                              select_screen_id,
                                              errorMessage,
                                              setErrorMessage,
                                              successMessage,
                                              setSuccessMessage,
                                              setEmailData,
                                          }) {
    const emailAccessToken = localStorage.getItem("emailAccessToken");
    const accessToken = localStorage.getItem("accessToken");
    const [uniqueId, setUniqueId] = useState("");

    const handleSendEmail = async () => {
        const userEmail = await getUserEmail(emailAccessToken);

        if (!select_screen_id) {
            setErrorMessage("Select Specimen ID is required.");
            return; // Stop execution if select_screen_id is null
        }

        // Regex pattern to match "SD" followed by 8 digits
        const pattern = /^SD\d{8}$/;

        if (!pattern.test(select_screen_id)) {
            setErrorMessage("Invalid Specimen ID format.");
            return; // Stop execution if select_screen_id doesn't match the pattern
        }

        const handleSendMailSuccess = async () => {
            try {
                const formData = new FormData();
                formData.append("note", emailData.body);
                formData.append("subject", emailData.subject);
                formData.append("message_id", ""); // Optional, provide a message ID if needed
                formData.append("select_screen_id", select_screen_id);
                formData.append("mentioned_users", ""); // Optional, add mentioned users as an array of user IDs

                const response = await axios.post(
                    `${config.url.FREDDY_API_URL}/add-specimen-note`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.data.status === "success") {
                    setSuccessMessage(`Email was sent successfully for ${select_screen_id}`);
                    setEmailData(null)
                } else {
                    setErrorMessage(response.data.message);
                }
            } catch (error) {
                setErrorMessage(error.response.data.message);
            }
        };


        const emailPayload = {
            message: {
                subject: `Laboratory Specimen Tracking with Labb: ${select_screen_id} ` + emailData.subject,
                body: {
                    contentType: "Text",
                    content: emailData.body,
                },
                toRecipients: emailData.toRecipients.map((email_address) => ({
                    emailAddress: {
                        address: email_address,
                    },
                })),
                ccRecipients: emailData.ccRecipients.map((email_address) => ({
                    emailAddress: {
                        address: email_address,
                    },
                })),
                from: {
                    emailAddress: {
                        address: userEmail,
                    },
                },
            },
        };

        try {
            const response = await axios.post(
                "https://graph.microsoft.com/v1.0/me/sendMail",
                emailPayload,
                {
                    headers: {
                        Authorization: `Bearer ${emailAccessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("response", response)
            if (response.status === 202) {
                handleSendMailSuccess();
            } else {
                setErrorMessage(response.data.error.message);
            }
        } catch (error) {
            setErrorMessage(error.response.data.error.message);
        }
    };

    useEffect(() => {
        handleSendEmail();
    }, [emailData]);

    return (
        <div>
            {/* Render your component JSX here */}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );
}
