import {useEffect, useState} from "react";

const EmailBody = ({ body }) => {
    return (
        <div className="email-body">
            <p>
                {body}
            </p>
        </div>
    );
};

export default EmailBody;
