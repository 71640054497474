import React, {useState} from 'react';
import './ReviewActiveBatch.css';
import {useNavigate} from "react-router-dom";
import {config} from "../../Constants";


export default function ReviewActiveBatch({nextTestInfo, updateNextTest, token}) {
    const navigate = useNavigate();
    const [inspectorResult, setInspectorResult] = useState(null);
    const [checked, setChecked] = useState({"Negative": false, "Ignore": false, "Send to Lab": false, "Rescan": false})

    const closeReview = () => {
        navigate('/reports');
    };


    function handleIgnoreChange() {
        setChecked({Negative: false, Ignore: true, "Send to Lab": false, "Rescan": false,})
        setInspectorResult(({
            batch_position: nextTestInfo.batch_position,
            batch_report_id: nextTestInfo.batch_report_id,
            select_test_id: nextTestInfo.select_test_id,
            inspector_inference_status_id: 6,
        }))
    }

    function handleLabChange() {
        setChecked({Negative: false, Ignore: false, "Send to Lab": true, "Rescan": false,})
        setInspectorResult(({
            batch_position: nextTestInfo.batch_position,
            batch_report_id: nextTestInfo.batch_report_id,
            select_test_id: nextTestInfo.select_test_id,
            inspector_inference_status_id: 7,
        }))
    }

    function handleNegativeChange() {
        setChecked({Negative: true, Ignore: false, "Send to Lab": false, "Rescan": false,})
        setInspectorResult(({
            batch_position: nextTestInfo.batch_position,
            batch_report_id: nextTestInfo.batch_report_id,
            select_test_id: nextTestInfo.select_test_id,
            inspector_inference_status_id: 1,
        }))
    }

    function handleRescanChange() {
        setChecked({Negative: false, Ignore: false, "Send to Lab": false, "Rescan": true,})
        setInspectorResult(({
            batch_position: nextTestInfo.batch_position,
            batch_report_id: nextTestInfo.batch_report_id,
            select_test_id: nextTestInfo.select_test_id,
            inspector_inference_status_id: 5,
        }))
    }

    function clearResult() {
        setInspectorResult(null)
        setChecked({Negative: false, Ignore: false, "Send to Lab": false, "Rescan": false})
    }


    function handleSubmit(e) {
        const formData = new FormData();
        const axios = require('axios').default;
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem('accessToken');
        formData.set('batch_position', nextTestInfo.batch_position);
        formData.set('batch_report_id', nextTestInfo.batch_report_id);
        formData.set('select_test_id', inspectorResult.select_test_id);
        formData.set('inspector_inference_status_id', inspectorResult.inspector_inference_status_id);
        const element = document.querySelector('#post-request-error-handling .formData-id');
        e.preventDefault();
        if (nextTestInfo.batch_position === nextTestInfo.kit_count) {
            axios({
                method: "POST",
                baseURL: url,
                url: "/close-batch",
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                navigate('/reports')
            })).catch(error => {
                element.parentElement.innerHTML = `Error: ${error.message}`;
                console.error('There was an error!', error);
            });
        } else {
            axios({
                method: "POST",
                baseURL: url,
                url: "/result",
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                updateNextTest(({
                    select_test_status: response.data[0].select_test_status,
                    total_inspectors: response.data[0].total_inspectors,
                    inspectors_reviewed: response.data[0].inspectors_reviewed,
                    batch_position: response.data[0].batch_position,
                    select_test_id: response.data[0].select_test_id,
                    batch_report_id: response.data[0].batch_report_id,
                    kit_count: response.data[0].kit_count,
                    freddy_version: response.data[0].freddy_version,
                    create_date: response.data[0].create_date,
                    select_inference_status: response.data[0].select_inference_status,
                    select_test_url: response.data[0].select_test_url
                }))
                clearResult()
            })).catch(error => {
                element.parentElement.innerHTML = `Error: ${error.message}`;
                console.error('There was an error!', error);
            });
        }

    }

    return (
        <div className="review-active-batch-main-body">
            <button onClick={closeReview} className="review-active-batch-close-button">
                <svg className="review-active-batch-close-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </button>
            <div className="review-active-batch-data-frame">
                <div className="review-active-batch-spacer"/>

                <div className="review-active-batch-data">
                    <div className="review-active-batch-id">
                        <h2 className="review-active-batch-header-text"
                            style={{
                                fontSize: "22px",
                                display: "inline",
                                color: "#58585A"
                            }}>{nextTestInfo.select_test_id}</h2><br/>

                    </div>
                    <div className="review-active-batch-id">
                        <h2 className="review-active-batch-header-text"
                            style={{
                                fontSize: "22px",
                                display: "inline",
                                color: "#58585A"
                            }}>{nextTestInfo.batch_position}</h2>
                        <h2 className="review-active-batch-header-text">of {nextTestInfo.kit_count} scans</h2>
                    </div>
                    <div className="review-active-batch-id">
                        <h2 className="review-active-batch-header-text"
                            style={{
                                fontSize: "22px",
                                display: "inline",
                                color: "#58585A"
                            }}>{nextTestInfo.batch_report_id}</h2>
                        <h2 className="review-active-batch-header-text">.FV{nextTestInfo.freddy_version}</h2>
                    </div>

                    <div className="review-active-batch-id">
                        <div className="review-active-date">
                            <h4 className="review-active-batch-sub-text">
                                {nextTestInfo.create_date}
                            </h4>
                        </div>
                    </div>
                </div>
                {/*<div className="review-active-batch-id">*/}
                {/*    <h2 className="review-active-batch-header-text"*/}
                {/*        style={{*/}
                {/*            fontSize: "22px",*/}
                {/*            display: "inline",*/}
                {/*            color: "#58585A"*/}
                {/*        }}>{nextTestInfo.select_inference_status}</h2>*/}
                {/*</div>*/}
                {/*<div className="review-active-batch-id">*/}
                {/*    <h2 className="review-active-batch-header-text">Freddy's Analysis</h2>*/}
                {/*</div>*/}
                <form>
                    <div className="review-active-action-button-grid">
                        <div className="review-active-action-button-row">
                            <div className="review-active-action-button-frame">
                                <div className={`review-active-batch-radio-wrapper ${checked.Ignore ? 'selected' : ''} ${checked.Ignore ? 'mustard' : ''}`} onClick={handleIgnoreChange}>
                                    <input className="review-active-batch-radio-button" value="Ignore" checked={checked.Ignore} required={true} name="result" type="radio"/>
                                    <h2 className="review-active-batch-result-text">IGNORE</h2>
                                </div>

                                <div className={`review-active-batch-radio-wrapper ${checked.Rescan ? 'selected' : ''} ${checked.Rescan ? 'mustard' : ''}`} onClick={handleRescanChange}>
                                    <input className="review-active-batch-radio-button" value="Rescan" checked={checked.Rescan} required={true} name="result" type="radio"/>
                                    <h2 className="review-active-batch-result-text">RESCAN</h2>
                                </div>
                            </div>
                        </div>
                        <div className="review-active-action-button-row">
                            <div className="review-active-action-button-frame">
                                <div
                                    className={`review-active-batch-radio-wrapper ${checked.Negative ? 'selected' : ''} ${checked.Negative ? 'green' : ''}`}
                                    onClick={handleNegativeChange}>
                                    <input className="review-active-batch-radio-button" value="Negative"
                                           checked={checked.Negative} required={true} name="result" type="radio"/>
                                    <h2 className="review-active-batch-result-text">NEGATIVE</h2>
                                </div>
                                <div
                                    className={`review-active-batch-radio-wrapper ${checked['Send to Lab'] ? 'selected red' : ''}`}
                                    onClick={handleLabChange}>
                                    <input className="review-active-batch-radio-button" value="Send to Lab"
                                           checked={checked['Send to Lab']} required={true} name="result" type="radio"/>
                                    <h2 className="review-active-batch-result-text">SEND TO LAB</h2>
                                </div>
                            </div>
                        </div>
                        <div className="review-active-action-button-frame">
                            <input type="submit" value="SUBMIT" className={`${checked.Negative ? 'review-active-action-button-green' : ''} ${checked['Send to Lab'] ? 'review-active-action-button-red' : ''} ${checked.Ignore || checked.Rescan ? 'review-active-action-button-mustard' : ''}`} onClick={handleSubmit} />
                        </div>
                    </div>

                </form>

            </div>
        </div>
    )
}
