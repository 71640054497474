import React, {useEffect, useState} from 'react';
import './GenerateBatch.css';
import AddBatch from "./add_batch/AddBatch";
import {config} from "../../Constants";
import LoadingTable from "../../Loading/LoadingTable";

export default function GenerateBatch({token, batchInfo}) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    const [freddyVersions, setFreddyVersions] = useState("")
    const [freddyDatasets, setFreddyDatasets] = useState("")
    const [selectFreddyVersion, setSelectFreddyVersion] = useState("")
    const [selectFreddyDataset, setSelectFreddyDataset] = useState("")
    const [batchSize, setBatchSize] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const setProcessingStatus = () => setIsProcessing(value => !value);
    const [isPolling, setIsPolling] = useState(false)
    const setPollingStatus = () => setIsPolling(value => !value);

    let versions = null;
    let datasets = null;

    const loadFreddyVersions = () => {
        const axios = require('axios').default;
        const element = document.querySelector('#post-request-error-handling .formData-id');
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-versions",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setFreddyVersions(response.data)
        })).catch(error => {
            element.parentElement.innerHTML = `Error: ${error.message}`;
            console.error('There was an error!', error);
        });
    }
    const loadDatasetBatches = () => {
        const axios = require('axios').default;
        const element = document.querySelector('#post-request-error-handling .formData-id');
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-batches",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setFreddyDatasets(response.data)
        })).catch(error => {
            element.parentElement.innerHTML = `Error: ${error.message}`;
            console.error('There was an error!', error);
        });
    }


    useEffect(() => loadFreddyVersions(), []);
    useEffect(() => loadDatasetBatches(), []);

    if (freddyVersions) {
        versions = freddyVersions.map((version) => {
            return (
                <option key={version}
                        value={version}>FV{version}</option>
            )
        })
    }

    if (freddyDatasets) {
        datasets = freddyDatasets.map((dataset) => {
            return (
                <option key={dataset}
                        value={dataset}>Batch: {dataset}</option>
            )
        })
    }


    function handleSizeChange(e) {
        setBatchSize(e.target.value)
    }

    function handleVersionChange(e) {
        setSelectFreddyVersion(e.target.value)
    }

    function handleDatasetChange(e) {
        setSelectFreddyDataset(e.target.value)
    }


    if (isProcessing) {
        return (
            <div className="gen-card-main-body">
                <div className="gen-card-data-frame">
                    <div className="gen-card-batch-data">
                        <LoadingTable/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="gen-card-main-body">
                <div className="gen-card-data-frame">
                    <div className="gen-card-batch-data">
                        <AddBatch setProcessingStatus={setProcessingStatus} setPollingStatus={setPollingStatus}
                                  batchSize={batchSize} isProcessing={isProcessing} isPolling={isPolling}
                                  batchInfo={batchInfo}
                                  selectFreddyVersion={selectFreddyVersion} selectFreddyDataset={selectFreddyDataset}/>
                    </div>
                    <div className="gen-card-horizontal-line-break"/>
                    <div className="gen-card-version-data-frame">
                        <div>
                            <form onChange={handleDatasetChange}>
                                <select className="gen-card-dataset-select">
                                    <option value="default">
                                        CHOOSE DATASET
                                    </option>
                                    <option key={100} value={100}>New Dataset</option>
                                    {/*<option key={200} value={200}>Control Dataset</option>*/}
                                    {datasets}
                                </select>
                            </form>
                        </div>

                    </div>
                    <div className="gen-card-horizontal-line-break"/>
                    <div className="gen-card-version-data-frame">
                        <form onChange={handleSizeChange}>
                            <select className="gen-card-version-select">
                                <option value="default">
                                    BATCH SIZE
                                </option>
                                <option key={1} value={1}>1</option>
                                <option key={5} value={5}>5</option>
                                <option key={10} value={10}>10</option>
                                <option key={50} value={50}>50</option>
                                <option key={100} value={100}>100</option>
                                <option key={200} value={200}>200</option>
                                <option key={300} value={300}>300</option>
                                <option key={400} value={400}>400</option>
                                <option key={500} value={500}>500</option>
                                <option key={1000} value={1000}>1000</option>
                                <option key={2000} value={2000}>2000</option>
                            </select>
                        </form>
                    </div>
                    <div className="gen-card-horizontal-line-break"/>
                    <div className="gen-card-version-data-frame">
                        <form onChange={handleVersionChange}>
                            <select className="gen-card-version-select">
                                <option value="default">
                                    VERSION
                                </option>
                                {versions}
                            </select>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
