import CloseIcon from '../../../assets/icons/Close.svg';

function SuccessMessage({ successMessage, onClose }) {
    if (!successMessage) return null;

    return (
        <div className="success-message">
            {successMessage}
            <div onClick={onClose} className="close-button">
                <img src={CloseIcon} alt="Close" />
            </div>
        </div>
    );
}

export default SuccessMessage;
