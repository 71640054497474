import React from 'react';
import './UserInitial.css';
import Initials from "./Initials";

export default function UserInitial({inspectorsInitials}) {

    return (
        <div className="user-initials-inspector-initials">
            {inspectorsInitials.map((initials) => {
                return (
                    <Initials key={inspectorsInitials.id} initials={initials}/>
                )
            })}
        </div>
    )
}
