import React from "react";
import './ReviewScreen.css';
import ScreenImage from "./ScreenImage";
import StripImages from "./strips_images/StripsImage";

export default function ReviewScreen({reviewStrips, nextTestInfo, updateNextTest, updateStripImages, token}) {

    if (reviewStrips) {
        return (
            <div className="review-screen-main-body">
                <StripImages updateStripImages={updateStripImages} nextTestInfo={nextTestInfo} />
           </div>
        )
    } else {
        return (
            <div className="review-screen-main-body">
                <ScreenImage updateStripImages={updateStripImages} nextTestInfo={nextTestInfo} />
            </div>
        )
    }

}
