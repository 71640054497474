import "./LabTracking.css"
import React, {useEffect, useState} from 'react';
import RecentLabScreens from "./recent_lab_screens/RecentLabScreens";
import ErrorLabScreens from "./error_screens/ErrorLabScreens";
import {config} from "../Constants";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import {FixedSizeList} from "react-window";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import PendingUploadScreens from "./pending_upload_screens/PendingUploadScreens";
import AddSpecimenNote from "../notes/add_specimen_note/AddSpecimenNote";
import SendSpecimenEmail from "../email/send_specimen_email/SendSpecimenEmail";
import UploadIcon from '../../assets/icons/Upload.svg'

const LabTracking = ({
                         userRoles,
                         newLinkedEmail,
                         newUnlinkedEmail,
                         newSpecimenNotes,
                         setNewSpecimenNotes,
                         setErrorMessage,
                         errorMessage,
                         successMessage,
                         setSuccessMessage,
                         setSortedScreens,
                         setSpecimenShowEmailModal,
                     }) => {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");
    const [errorStatusScreens, setErrorStatusScreens] = useState([]);
    const [selectDateOptions, setSelectDateOptions] = useState([]);
    const [comprehensiveFreddyDataAnalysis, setComprehensiveFreddyDataAnalysis] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [labStatusScreens, setLabStatusScreens] = useState([]);
    const [mroReviewScreens, setMroReviewScreens] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const [selectedRow, setSelectedRow] = useState("");
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(false);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [recentSpecimenIds, setRecentSpecimenIds] = useState([]);
    const [selectedSpecimenIds, setSelectedSpecimenIds] = useState([]);
    const [isSendEmailTemplateActive, setIsSendEmailTemplateActive] = useState(false);
    const [pendingUploadScreens, setPendingUploadScreens] = useState([]);
    const [pendingReviewScreens, setPendingReviewScreens] = useState([]);
    const [attentionRequiredScreens, setAttentionRequiredScreens] = useState([]);
    const [approvedScreens, setApprovedScreens] = useState([]);
    const [completeScreens, setCompleteScreens] = useState([]);
    const [sendToLabScreens, setSendToLabScreens] = useState([]);
    const [sentToLabScreens, setSentToLabScreens] = useState([]);
    const [sentToMroScreens, setSentToMroScreens] = useState([]);
    const [sendToMroScreens, setSendToMroScreens] = useState([]);
    const [labTestReceivedScreens, setLabTestReceivedScreens] = useState([]);
    const [labTestCreatingScreens, setLabTestCreatingScreens] = useState([]);
    const [labDeliveredScreens, setLabDeliveredScreens] = useState([]);
    const [labTestCreatedScreens, setLabTestCreatedScreens] = useState([]);
    const [labTestTransitScreens, setLabTestTransitScreens] = useState([]);


    // Define initial state
    const initialState = {
        isAttentionRequiredLabScreensTableActive: false,
        isAttentionRequiredLabScreensTableAlert: false,
        isRecentLabScreensTableActive: true,
        isRecentLabScreensTableAlert: false,
        isLabTestCreatingTableActive: false,
        isLabTestCreatingTableAlert: false,
        isLabTestReceivedTableActive: false,
        isLabTestReceivedTableAlert: false,
        isLabTestTransitTableActive: false,
        isLabTestTransitTableAlert: false,
        isOrderGeneratedScreensTableActive: false,
        isOrderGeneratedTableAlert: false,
        isShippedScreensTableActive: false,
        isShippedTableAlert: false,
        isDeliveredScreensTableActive: false,
        isDeliveredTableAlert: false,
        isRegisteredInLabScreensTableActive: false,
        isRegisteredInLabTableAlert: false,
        isMroReviewLabScreensTableActive: false,
        isMroReviewLabScreensTableAlert: false,
        isPendingReviewLabScreensTableActive: false,
        isPendingReviewLabScreensTableAlert: false,
        isApprovedLabScreensTableActive: false,
        isApprovedLabScreensTableAlert: false,
        isPendingUploadScreensTableActive: false,
        isPendingUploadScreenTableAlert: false,
        isCompleteScreensTableActive: false,
        isCompleteScreensTableAlert: false,
        isSendToMroReviewLabScreensTableActive: false,
        isSendToMroReviewLabScreensTableAlert: false,
        isSentToMroReviewLabScreensTableActive: false,
        isSentToMroReviewLabScreensTableAlert: false,
        isSendToLabReviewLabScreensTableActive: false,
        isSendToLabReviewLabScreensTableAlert: false,
        isSentToLabReviewLabScreensTableActive: false,
        isSentToLabReviewLabScreensTableAlert: false,
    };


    // Define reducer function to update the state
    function tabReducer(state, action) {
        switch (action.type) {
            case 'ATTENTION_REQUIRED':
                return {
                    ...initialState,
                    isAttentionRequiredLabScreensTableActive: true,
                    isAttentionRequiredLabScreensTableAlert: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };

            case 'RECENT_SCREENS':
                return {
                    ...initialState,
                    isRecentLabScreensTableActive: true,
                    isRecentLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'LAB_TEST_CREATING':
                return {
                    ...initialState,
                    isLabTestsCreatingTableActive: true,
                    isLabTestsCreatingTableAlert: false,
                    isRecentLabScreensTableActive: false,
                    isRecentLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                };
            case 'ORDER_GENERATED':
                return {
                    ...initialState,
                    isOrderGeneratedScreensTableActive: true,
                    isOrderGeneratedTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                };

            case 'SHIPPED':
                return {
                    ...initialState,
                    isShippedScreensTableActive: true,
                    isShippedTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };

            case 'DELIVERED':
                return {
                    ...initialState,
                    isDeliveredScreensTableActive: true,
                    isDeliveredTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'REGISTERED_IN_LAB':
                return {
                    ...initialState,
                    isRegisteredInLabScreensTableActive: true,
                    isRegisteredInLabTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };

            case 'SEND_TO_MRO':
                return {
                    ...initialState,
                    isMroReviewLabScreensTableActive: false,
                    isMroReviewLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: true,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'SENT_TO_MRO':
                return {
                    ...initialState,
                    isMroReviewLabScreensTableActive: false,
                    isMroReviewLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: true,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'SEND_TO_LAB':
                return {
                    ...initialState,
                    isMroReviewLabScreensTableActive: false,
                    isMroReviewLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: true,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'SENT_TO_LAB':
                return {
                    ...initialState,
                    isMroReviewLabScreensTableActive: false,
                    isMroReviewLabScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: true,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'PENDING_REVIEW':
                return {
                    ...initialState,
                    isPendingReviewScreensTableActive: true,
                    isPendingReviewScreensTableAlert: false,
                    isMroReviewLabScreensTableActive: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };
            case 'APPROVED':
                return {
                    ...initialState,
                    isApprovedLabScreensTableActive: true,
                    isApprovedLabScreensTableAlert: false,
                    isPendingReviewScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };

            case 'PENDING_UPLOAD':
                return {
                    ...initialState,
                    isPendingUploadScreensTableActive: true,
                    isPendingUploadScreenTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isCompleteScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };

            case 'COMPLETE':
                return {
                    ...initialState,
                    isCompleteScreensTableActive: true,
                    isCompleteScreensTableAlert: false,
                    isAttentionRequiredLabScreensTableActive: false,
                    isRecentLabScreensTableActive: false,
                    isOrderGeneratedScreensTableActive: false,
                    isShippedScreensTableActive: false,
                    isDeliveredScreensTableActive: false,
                    isRegisteredInLabScreensTableActive: false,
                    isMroReviewLabScreensTableActive: false,
                    isPendingReviewScreensTableActive: false,
                    isApprovedLabScreensTableActive: false,
                    isPendingUploadScreensTableActive: false,
                    isSendToMroReviewLabScreensTableActive: false,
                    isSendToMroReviewLabScreensTableAlert: false,
                    isSentToMroReviewLabScreensTableActive: false,
                    isSentToMroReviewLabScreensTableAlert: false,
                    isSendToLabReviewLabScreensTableActive: false,
                    isSendToLabReviewLabScreensTableAlert: false,
                    isSentToLabReviewLabScreensTableActive: false,
                    isSentToLabReviewLabScreensTableAlert: false,
                    isLabTestsCreatingTableActive: false,
                    isLabTestsCreatingTableAlert: false,
                    isLabTestReceivedTableActive: false,
                    isLabTestReceivedTableAlert: false,
                    isLabTestTransitTableActive: false,
                    isLabTestTransitTableAlert: false,
                };


            default:
                return state;
        }
    }

    // Initialize state using the reducer
    const [state, dispatch] = React.useReducer(tabReducer, initialState);

    // Functions to handle tab clicks
    function goToAttentionRequiredTable() {
        dispatch({type: 'ATTENTION_REQUIRED'});
    }

    function goToRecentScreensTable() {
        dispatch({type: 'RECENT_SCREENS'});
    }

    function goToLabTestCreating() {
        dispatch({type: 'LAB_TEST_CREATING'});
    }

    function goToOrderGeneratedTable() {
        dispatch({type: 'ORDER_GENERATED'});
    }

    function goToShippedTable() {
        dispatch({type: 'SHIPPED'});
    }

    function goToDeliveredTable() {
        dispatch({type: 'DELIVERED'});
    }

    function goToRegisteredInLabTable() {
        dispatch({type: 'REGISTERED_IN_LAB'});
    }

    function goToSendToMroReviewTable() {
        dispatch({type: 'SEND_TO_MRO'});
    }

    function goToSentToMroReviewTable() {
        dispatch({type: 'SENT_TO_MRO'});
    }

    function goToSendToLabReviewTable() {
        dispatch({type: 'SEND_TO_LAB'});
    }

    function goToSentToLabReviewTable() {
        dispatch({type: 'SENT_TO_LAB'});
    }

    function goToPendingReviewTable() {
        dispatch({type: 'PENDING_REVIEW'});
    }

    function goToApprovedTable() {
        dispatch({type: 'APPROVED'});
    }

    function goToPendingUploadTable() {
        dispatch({type: 'PENDING_UPLOAD'});
    }

    function goToCompleteTable() {
        dispatch({type: 'COMPLETE'});
    }


    useEffect(() => {
        getLabStatusScreens();
        loadRecentSpecimenIds();
    }, []);

    const handleSpecimenIdSearch = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedSpecimenIds(selectedIds);
    };

    function fetchLabStatusScreens() {
        setIsTableLoading(true);
        return axios({
            method: "GET",
            baseURL: url,
            url: "/start-get-lab-screen-status",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then((response) => {
            const jobId = response.data['job_id'];
            const pollUrl = `${url}/get-lab-screen-status-result?job_id=${jobId}`;
            const pollInterval = setInterval(() => {
                axios({
                    method: "GET",
                    baseURL: pollUrl,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                }).then((response) => {
                    const result = response.data;
                    if (result.status === 'completed') {
                        const labStatusScreens = response.data.data;
                        setIsTableLoading(false);
                        const sortedLabStatusScreens = sortLabStatusScreens(labStatusScreens);
                        setLabStatusScreens(sortedLabStatusScreens.sortedScreens);
                        // Update the state variables with the sorted screens
                        setPendingUploadScreens(sortedLabStatusScreens.pendingUploadScreens);
                        setMroReviewScreens(sortedLabStatusScreens.mroReviewScreens);
                        setPendingReviewScreens(sortedLabStatusScreens.pendingReviewScreens);
                        setSendToLabScreens(sortedLabStatusScreens.sendToLabScreens);
                        setSentToLabScreens(sortedLabStatusScreens.sentToLabScreens);
                        setSendToMroScreens(sortedLabStatusScreens.sendToMroScreens);
                        setSentToMroScreens(sortedLabStatusScreens.sentToMroScreens);
                        setAttentionRequiredScreens(sortedLabStatusScreens.attentionRequiredScreens);
                        setApprovedScreens(sortedLabStatusScreens.approvedScreens);
                        setCompleteScreens(sortedLabStatusScreens.completeScreens);
                        setLabTestCreatingScreens(sortedLabStatusScreens.labTestCreatingScreens);
                        setLabTestCreatedScreens(sortedLabStatusScreens.labTestCreatedScreens);
                        setLabTestTransitScreens(sortedLabStatusScreens.labTestTransitScreens);
                        setLabDeliveredScreens(sortedLabStatusScreens.labDeliveredScreens)
                        setLabTestReceivedScreens(sortedLabStatusScreens.labTestReceivedScreens);
                        clearInterval(pollInterval);
                    } else if (result.status === 'failed') {
                        clearInterval(pollInterval);
                        setErrorMessage("Error Failed to get specimens");
                        setIsTableLoading(false);
                    }

                }).catch(error => {
                    console.log(error);
                    clearInterval(pollInterval);
                    setIsTableLoading(false);
                    setErrorMessage("An error occurred: " + error.message);
                });
            }, 10000); // poll every 10 seconds

            return response; // Return the initial response
        }).catch(error => {
            console.log(error);
            setIsTableLoading(false);
            setErrorMessage("An error occurred: " + error.message);
        });
    }

    const getLabStatusScreens = () => {
        fetchLabStatusScreens()
            .catch((error) => {
                setErrorMessage("An error occurred: " + error.message);
                console.error("There was an error!", error);
                setIsTableLoading(false)
            });
    };


    const sortLabStatusScreens = (labStatusScreens) => {
        const sortDateCollected = (a, b) => {
            const dateA = new Date(a.date_collected);
            const dateB = new Date(b.date_collected);

            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
                return 0;
            } else if (isNaN(dateA.getTime())) {
                return -1;
            } else if (isNaN(dateB.getTime())) {
                return 1;
            } else {
                return dateA.getTime() - dateB.getTime();
            }
        };

        const labTestCreatingScreens = labStatusScreens.filter(
            (screen) => screen.system_status === "LAB TEST CREATING"
        );
        const labTestCreatedScreens = labStatusScreens.filter(
            (screen) => screen.system_status === "LAB TEST CREATED"
        );
        const labTestTransitScreens = labStatusScreens.filter(
            (screen) => screen.system_status === "LAB TEST TRANSIT"
        );
        const labTestReceivedScreens = labStatusScreens.filter(
            (screen) => screen.system_status === "LAB TEST RECEIVED"
        );
        const labDeliveredScreens = labStatusScreens.filter(
            (screen) => screen.tracking_status === "Delivered"
        );
        const mroResultsReceivedScreens = labStatusScreens.filter(
            (screen) => screen.system_status === "MRO RESULTS RECEIVED"
        );
        const pendingUploadScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "PENDING UPLOAD"
        );
        const mroReviewScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "SENT TO MRO"
        );
        const pendingReviewScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "PENDING REVIEW"
        );
        const sendToLabScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "SEND TO LAB"
        );
        const sentToLabScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "SENT TO LAB"
        );
        const sendToMroScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "SEND TO MRO"
        );
        const sentToMroScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "SENT TO MRO"
        );
        const attentionRequiredScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "ATTENTION REQUIRED"
        );
        const approvedScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "APPROVED"
        );
        const completeScreens = labStatusScreens.filter(
            (screen) => screen.specimen_internal_status[0] === "COMPLETE"
        );

        // Add MRO RESULTS RECEIVED screens to completeScreens
        const completedScreens = completeScreens.concat(mroResultsReceivedScreens);


        return {
            pendingUploadScreens: pendingUploadScreens.sort(sortDateCollected),
            mroReviewScreens: mroReviewScreens.sort(sortDateCollected),
            pendingReviewScreens: pendingReviewScreens.sort(sortDateCollected),
            sendToLabScreens: sendToLabScreens.sort(sortDateCollected),
            sentToLabScreens: sentToLabScreens.sort(sortDateCollected),
            sendToMroScreens: sendToMroScreens.sort(sortDateCollected),
            sentToMroScreens: sentToMroScreens.sort(sortDateCollected),
            attentionRequiredScreens: attentionRequiredScreens.sort(sortDateCollected),
            approvedScreens: approvedScreens.sort(sortDateCollected),
            completeScreens: completedScreens.sort(sortDateCollected),
            sortedScreens: labStatusScreens.sort(sortDateCollected),
            labTestCreatingScreens: labTestCreatingScreens.sort(sortDateCollected),
            labTestCreatedScreens: labTestCreatedScreens.sort(sortDateCollected),
            labTestTransitScreens: labTestTransitScreens.sort(sortDateCollected),
            labTestReceivedScreens: labTestReceivedScreens.sort(sortDateCollected),
            labDeliveredScreens: labDeliveredScreens.sort(sortDateCollected),
        }
    };

    const handleReportUpload = async (event) => {
        event.preventDefault();
        const filesUploaded = event.target.files;
        if (!filesUploaded || filesUploaded.length === 0) {
            setErrorMessage("Please select at least one file to upload.");
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < filesUploaded.length; i++) {
            formData.append('uploaded_report', filesUploaded[i]);
        }

        try {
            const response = await axios.post(`${url}/start-report-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response.status === 200) {
                setSuccessMessage('Report uploaded successfully.')

            } else {
                setErrorMessage("Failed to upload report. Please try again.")
                console.error('Error uploading report!');
            }
        } catch (error) {
            setErrorMessage("An error occurred: " + error.message)
            console.error('Error uploading report:', error);
        }
    };

    // Calculate the height of the virtualized list
    const calculateListHeight = () => {
        // Adjust this value to determine the desired height of each option
        const optionHeight = 35;
        // Adjust this value to determine the desired number of visible options
        const visibleOptions = 5;
        return optionHeight * visibleOptions;
    };


    const handleSpecimenSearchSubmit = (e) => {
        e.preventDefault();
        const axios = require('axios').default;
        const formData = new FormData();
        formData.set('specimen_search', JSON.stringify(selectedSpecimenIds));
        console.log(formData)
        axios({
            method: "POST",
            baseURL: url,
            data: formData,
            url: "/get-lab-screen-status-search",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    const labStatusScreens = response.data.data;
                    setLabStatusScreens(labStatusScreens);
                } else {
                    setErrorMessage("An error occurred: " + response.data.message)
                }
            })
            .catch(error => {
                setErrorMessage(`Error: ${error.message}`);
                console.error('There was an error!', error);
            });
    };

    const handleReportUploadClick = (event) => {
        event.preventDefault(); // Add this line
        hiddenFileInput.current.click();
    };


    const loadRecentSpecimenIds = () => {
        const axios = require('axios').default;
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-recent-specimen-id",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setRecentSpecimenIds(response.data);
            })
            .catch(error => {
                setErrorMessage(`Error: ${error.message}`);
                console.error('There was an error!', error);
            });
    };

    useEffect(() => loadRecentSpecimenIds(), []);


    return (
        <div>
            {showNoteModal && (
                <div className="note-modal-container">
                    <div className="note-modal-row">
                        <div className="note-modal-column">
                            <AddSpecimenNote
                                userRoles={userRoles}
                                select_screen_id={selectedRow}
                                setShowNoteModal={setShowNoteModal}
                                onClose={() => setSelectedRow(null)}
                                setSelectedOption={setSelectedOption}
                                selectedOption={selectedOption}
                                setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                                emailTemplateOptions={emailTemplateOptions}
                                setEmailTemplateOptions={setEmailTemplateOptions}
                                selectedTemplate={selectedTemplate}
                                filteredTemplates={filteredTemplates}
                                setSelectedTemplate={setSelectedTemplate}
                                setFilteredTemplates={setFilteredTemplates}
                                getLabStatusScreens={getLabStatusScreens}
                                setNewSpecimenNotes={setNewSpecimenNotes}
                                newSpecimenNotes={newSpecimenNotes}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                successMessage={successMessage}
                                setSuccessMessage={setSuccessMessage}
                                selectedRow={selectedRow}
                                setSelectedRow={row => setSelectedRow(row.device_id)}
                                labStatusScreens={labStatusScreens}
                                setSortedScreens={setSortedScreens}
                                setPendingUploadScreens={setPendingUploadScreens}
                                pendingUploadScreens={pendingUploadScreens}
                                setPendingReviewScreens={setPendingReviewScreens}
                                setAttentionRequiredScreens={setAttentionRequiredScreens}
                                setApprovedScreens={setApprovedScreens}
                                setCompleteScreens={setCompleteScreens}
                                setLabStatusScreens={setLabStatusScreens}
                                setSentToLabScreens={setSentToLabScreens}
                                setSentToMroScreens={setSentToMroScreens}
                                setSendToLabScreens={setSendToLabScreens}
                                setSendToMroScreens={setSendToMroScreens}
                                sortLabStatusScreens={sortLabStatusScreens}
                                setShowEmailModal={setShowEmailModal}
                            />
                        </div>
                    </div>
                </div>
            )}
            {showEmailModal && (
                <div className="note-modal-container">
                    <div className="note-modal-row">
                        <div className="note-modal-column">
                            <SendSpecimenEmail
                                setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                emailTemplateOptions={emailTemplateOptions}
                                setEmailTemplateOptions={setEmailTemplateOptions}
                                filteredTemplates={filteredTemplates}
                                setFilteredTemplates={setFilteredTemplates}
                                select_screen_id={selectedRow}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                successMessage={successMessage}
                                setSuccessMessage={setSuccessMessage}
                                setShowEmailModal={setShowEmailModal}
                                setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div>
                    <div>
                        <div>
                            <div className="lab-billing-reports-search-header">
                                <div>
                                    <div>
                                        <form onSubmit={handleReportUploadClick}>
                                            <div className="lab-billing-reports-upload-icon"
                                                 style={{marginRight: '100px'}}>
                                                <button type='submit'
                                                        className="lab-billing-reports-upload-button">
                                                    <img src={UploadIcon} alt="Upload"/>
                                                    <input type="file" style={{display: 'none'}}
                                                           ref={hiddenFileInput}
                                                           onChange={handleReportUpload} multiple/>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <form onSubmit={handleSpecimenSearchSubmit}>
                                        <div className="lab-billing-reports-search">
                                            <CreatableSelect
                                                components={{
                                                    MenuList: (props) => {
                                                        const {options, children, maxHeight, getValue} = props;
                                                        const [value] = getValue();
                                                        const initialOffset = options.indexOf(value) * 35;

                                                        return (
                                                            <FixedSizeList
                                                                height={calculateListHeight()}
                                                                itemCount={options.length}
                                                                itemSize={35}
                                                                initialScrollOffset={initialOffset}
                                                                width="100%"// Assign a valid width value here
                                                            >
                                                                {({index, style}) => (
                                                                    <div style={style}>{children[index]}</div>
                                                                )}
                                                            </FixedSizeList>
                                                        );
                                                    },
                                                }}
                                                placeholder="Search Specimen IDs..."
                                                isClearable
                                                closeMenuOnSelect={false}
                                                isMulti
                                                onChange={handleSpecimenIdSearch}
                                                options={recentSpecimenIds.map((specimenId) => ({
                                                    label: specimenId,
                                                    value: specimenId,
                                                }))}
                                                className="search-input"
                                            />
                                            <button className="search-button">SEARCH</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lab-reports-tabs">

                        <div className="lab-billing-reports-tab" onClick={goToAttentionRequiredTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>ATTENTION REQUIRED</h3>
                                </div>
                            </div>
                            {
                                state.isAttentionRequiredLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isAttentionRequiredLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToRecentScreensTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>RECENT SCREENS</h3>
                                </div>
                            </div>
                            {
                                state.isRecentLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isRecentLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>

                        <div className="lab-billing-reports-tab" onClick={goToLabTestCreating}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>LAB TEST CREATING</h3>
                                </div>
                            </div>
                            {
                                state.isLabTestCreatingTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isLabTestCreatingTableActive ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>

                        {/*SEND TO LAB WAS PRESSED status 1000*/}
                        <div className="lab-billing-reports-tab" onClick={goToOrderGeneratedTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>ORDER GENERATED</h3>
                                </div>
                            </div>
                            {
                                state.isOrderGeneratedScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isOrderGeneratedTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        {/*SPEIMCEN is in transit status of 1350 */}
                        <div className="lab-billing-reports-tab" onClick={goToShippedTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>SHIPPED</h3>
                                </div>
                            </div>
                            {
                                state.isShippedScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isShippedTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>

                        <div className="lab-billing-reports-tab" onClick={goToDeliveredTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>DELIVERED</h3>
                                </div>
                            </div>
                            {
                                state.isDeliveredScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isDeliveredTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>

                        {/*Lab test has been recived at lab or AT LAB recived 1400*/}
                        <div className="lab-billing-reports-tab" onClick={goToRegisteredInLabTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>REGISTERED IN LAB</h3>
                                </div>
                            </div>
                            {
                                state.isRegisteredInLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isRegisteredInLabTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToSendToLabReviewTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>SEND TO LAB</h3>
                                </div>
                            </div>
                            {
                                state.isSendToLabReviewLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isSendToLabReviewLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToSentToLabReviewTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>SENT TO LAB</h3>
                                </div>
                            </div>
                            {
                                state.isSentToLabReviewLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isSentToLabReviewLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>

                        <div className="lab-billing-reports-tab" onClick={goToSendToMroReviewTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>SEND TO MRO</h3>
                                </div>
                            </div>
                            {
                                state.isSendToMroReviewLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isSendToMroReviewLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToSentToMroReviewTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>SENT TO MRO</h3>
                                </div>
                            </div>
                            {
                                state.isSentToMroReviewLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isSentToMroReviewLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToPendingReviewTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>PENDING REVIEW</h3>
                                </div>
                            </div>
                            {
                                state.isPendingReviewLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isPendingReviewLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToApprovedTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>APPROVED</h3>
                                </div>
                            </div>
                            {
                                state.isApprovedLabScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isApprovedLabScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToPendingUploadTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>PENDING UPLOAD</h3>
                                </div>
                            </div>
                            {
                                state.isPendingUploadScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isPendingUploadScreenTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                        <div className="lab-billing-reports-tab" onClick={goToCompleteTable}>
                            <div className="lab-billing-reports-tab-label">
                                <div className="lab-billing-reports-tab-button">
                                    <h3>COMPLETE</h3>
                                </div>
                            </div>
                            {
                                state.isCompleteScreensTableActive ?
                                    <div className="lab-billing-reports-tab-underline"></div> : null
                            }
                            {
                                state.isCompleteScreensTableAlert ?
                                    <div className="lab-billing-reports-tab-underline-red"></div> : null
                            }
                        </div>
                    </div>

                    {state.isAttentionRequiredLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={attentionRequiredScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isLabTestCreatingTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labTestCreatingScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isRecentLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labStatusScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isRegisteredInLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labTestReceivedScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}
                    {state.isOrderGeneratedScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labTestCreatedScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isShippedScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labTestTransitScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isDeliveredScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={labDeliveredScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isSendToMroReviewLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={sendToMroScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isSentToMroReviewLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={sentToMroScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}


                    {state.isSendToLabReviewLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={sendToLabScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}
                    {state.isSentToLabReviewLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={sentToLabScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}

                    {state.isPendingReviewScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={pendingReviewScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}
                                />
                            </div>
                        </div>
                    )}
                    {state.isApprovedLabScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={approvedScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}

                                />
                            </div>
                        </div>
                    )}
                    {state.isPendingUploadScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={pendingUploadScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}

                                />
                            </div>
                        </div>
                    )}
                    {state.isCompleteScreensTableActive && (
                        <div className="lab-billing-reports-body">
                            <div className="live-screens-table-frame">
                                <RecentLabScreens
                                    userRoles={userRoles}
                                    labStatusScreens={completeScreens}
                                    showNoteModal={showNoteModal}
                                    setShowNoteModal={setShowNoteModal}
                                    selectedRow={selectedRow}
                                    setSelectedRow={row => setSelectedRow(row.device_id)}
                                    setShowEmailModal={setShowEmailModal}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    successMessage={successMessage}
                                    setSuccessMessage={setSuccessMessage}
                                    setLabStatusScreens={setLabStatusScreens}
                                    sortLabStatusScreens={sortLabStatusScreens}
                                    setPendingUploadScreens={setPendingUploadScreens}
                                    setPendingReviewScreens={setPendingReviewScreens}
                                    setAttentionRequiredScreens={setAttentionRequiredScreens}
                                    setApprovedScreens={setApprovedScreens}
                                    setCompleteScreens={setCompleteScreens}
                                    setMroReviewScreens={setMroReviewScreens}
                                    setSentToLabScreens={setSentToLabScreens}
                                    setSentToMroScreens={setSentToMroScreens}
                                    setSendToLabScreens={setSendToLabScreens}
                                    setSendToMroScreens={setSendToMroScreens}

                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LabTracking;
