import React, {useEffect, useState} from "react";
import axios from "axios";
import "./NoteList.css";
import {config} from "../../Constants";
import ReplyIcon from "../../../assets/icons/Reply.svg";


export default function NoteList({
                                     notesList,
                                     setErrorMessage,
                                     setIsSendEmailModalOpen,
                                 }) {
    const [reversedNotesList, setReversedNotesList] = useState([]);

    useEffect(() => {
        if (Array.isArray(notesList)) {
            // If notesList is an array, set it directly
            setReversedNotesList(notesList.reverse());
        } else {
            // If notesList is a singular value, fetch the notes using the getAllNotes() function
            getAllNotes();
        }
    }, [notesList]);

    function getAllNotes() {
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem("accessToken");
        const formData = new FormData();
        // Adjust the formData as needed for the API call
        formData.append("select_screen_id", notesList);

        axios({
            method: "POST",
            baseURL: url,
            url: "/get-all-notes",
            data: formData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                setReversedNotesList(response.data.reverse());
            })
            .catch((error) => {
                setErrorMessage(error.data)
                console.error(error);
            });
    }

    const handleReplyIconClick = () => {
        setIsSendEmailModalOpen(true);
    };

    return (
        <div>
            {reversedNotesList.map((note, index) => (
                <div key={index} className="note-box">
                    <div className="note-full-container">
                        <div className="note-header">
                            <div className="date-container">
                                <h3>
                                    {new Date(note[0]).toLocaleDateString(undefined, {
                                        month: "2-digit",
                                        day: "2-digit",
                                    })}{" "}
                                    {new Date(note[0]).toLocaleTimeString(undefined, {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                                </h3>
                            </div>
                            {note[3] && (
                                <div className="note-subject">
                                    <h3>{note[3]}</h3>
                                </div>
                            )}
                            <div className="note-specimen-id">
                                <h3>SD0{note[1]}</h3>
                            </div>
                        </div>
                        <div>
                            <div className="note-container">
                                <p>{note[2]}</p>
                            </div>
                            {note[4] ?
                                <div className="note-reply-icon">
                                    <img src={ReplyIcon} alt="Reply" className="reply-icon"  onClick={handleReplyIconClick}/>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
