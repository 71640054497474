import './Header.css';
import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import FetchMail from "../email/fetch_mail/FetchMail";
import {msalInstance} from "../../index";
import LogoutIcon from "../../assets/icons/Logout.svg";
import BellIcon from "../../assets/icons/Bell.svg";
import RandomDonorIcon from "../../assets/icons/RandomDonor.svg";
import {default as axios} from "axios";
import {config} from "../Constants";
import LoadingTable from "../Loading/LoadingTable";
import CloseIcon from "../../assets/icons/Close.svg";


function Header({
                    instance,
                    userRoles,
                    specimenLinkedEmails,
                    setSpecimenLinkedEmails,
                    specimenUnlinkedEmails,
                    setSpecimenUnlinkedEmails,
                    setSupportEmailMessages,
                    supportEmailMessages,
                    setUnprocessedEmails,
                    unprocessedEmails,
                    exclusionsEmailList,
                    exclusionsSubjectList,
                    initialState,
                    newLinkedEmail,
                    setNewLinkedEmail,
                    setNewUnlinkedEmail,
                    newUnlinkedEmail,
                    setErrorMessage,
                    errorMessage,
                    successMessage,
                    setSuccessMessage,
                    setUserLoginStatus,
                    userLoginStatus,
                    setSpecimenNotificationNotes,
                    setSpecimenUserNotifications,
                    updateShowNotificationModal,

                }) {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem('accessToken');
    const hiddenFileInput = React.useRef(null);
    const [donorRandomSelectionUrl, setDonorRandomSelectionUrl] = useState(null);
    const [loadingTable, setLoadingTable] = useState(false)
    const updateLoadingTable = () => setLoadingTable(value => !value)
    const [download, setDownload] = useState(false);
    const updateDownload = () => setDownload(value => !value);


    // const accessToken = localStorage.getItem('accessToken');
    // const emailAccessToken = localStorage.getItem('emailAccessToken');
    //
    // const tabOptions = [
    //     {id: 'inbox', label: 'INBOX', component: ManageInbox},
    //     {id: 'manage-template', label: 'MANAGE TEMPLATE', component: LinkedEmailsList},
    //     // { id: 'specimen-tracking', label: 'Specimen Tracking', component: SpecimenTrackingComponent },
    // ];


    async function handleUserLoginStatus() {
        try {
            const formData = new FormData();
            formData.append('login_status', 0);
            const response = await axios({
                method: "POST",
                baseURL: url,
                url: "/set-user-login-status",
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                },
            });

            if (response.status === 200) {
                console.log("Success: User status set properly.");
            } else {
                setUserLoginStatus(false);
                console.log("Error: User status not set properly.");
            }
        } catch (error) {
            console.log("Error: User status not set properly.", error); // Log the error
        }
    }

    function handleLogout() {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        })
    }

    const logoutAndSetUserStatus = () => {
        handleUserLoginStatus();
        handleLogout();
    };


    async function handleUploadRandomDonorList(event) {
        event.preventDefault();
        const filesUploaded = event.target.files;
        if (!filesUploaded || filesUploaded.length === 0) {
            window.alert('Please select at least one file to upload.');
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < filesUploaded.length; i++) {
            formData.append('uploaded_report', filesUploaded[i]);
        }
        updateLoadingTable()
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/start-donor-random-selection-upload",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            console.log(response); // log the response object
            const job_id = response.data.job_id;
            const poll_url = `/get-donor-random-selection-upload-result?job_id=${job_id}`;
            console.log(poll_url); // log the polling URL
            const poll_interval = setInterval(async () => {
                try {
                    const response = await axios({
                        method: "GET",
                        baseURL: url,
                        data: formData,
                        url: poll_url,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    });

                    console.log(response.data.status)
                    if (response.data.status === 'completed') {
                        clearInterval(poll_interval);
                        setDonorRandomSelectionUrl(response.data.selected_donor_records_url);
                        updateDownload()
                        updateLoadingTable();
                    }
                } catch (error) {
                    clearInterval(poll_interval);
                    console.log(error); // log the polling error
                    setErrorMessage("Selection error:", error.message)
                }
            }, 10000); // poll every 10 seconds
        } catch (error) {
            console.log(error); // log the upload error
            // show an error pop up with the error message
            window.alert("An error occurred: " + error.message);
        }
    }

    const handleUploadRandomDonorListClick = (event) => {
        event.preventDefault(); // Add this line
        hiddenFileInput.current.click();
    };


    if (download && donorRandomSelectionUrl) {
        updateDownload()
        const link = document.createElement("a")
        link.href = donorRandomSelectionUrl
        document.body.appendChild(link);
        link.click()
    }

    const handleOpenNotificationModal = () => {
        updateShowNotificationModal();
    };

    return (
        <header className="main-header">
            <NavLink to="/" className="nav-button">
                <img className="header-logo"
                     src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                     alt="Labb Logo"/>
            </NavLink>
            <div className="nav-items">
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.Analytics') ? (
                            <div className="nav-item">
                                <NavLink to="/report-generator" className="nav-button">
                                    <label className="nav-button-text">Analytics</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.History') ? (
                            <div className="nav-item">
                                <NavLink to="/read-history" className="nav-button">
                                    <label className="nav-button-text">History</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Labb.Specimen.Details') ? (
                            <div className="nav-item">
                                <NavLink to="/specimen-details" className="nav-button">
                                    <label className="nav-button-text">Specinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.Inspector') ? (
                            <div className="nav-item">
                                <NavLink to="/reports" className="nav-button">
                                    <label className="nav-button-text">Reports</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Labb.Email') ? (
                            <div className="nav-item">
                                <NavLink to="/email" className="nav-button">
                                    <label className="nav-button-text">Emailinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <NavLink to="/monitor" activeClassName="active" className="nav-button">
                        <label className="nav-button-text">Monitor</label>
                    </NavLink>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.Reports') ? (
                            <div className="nav-item">
                                <NavLink to="/lab-tracking" className="nav-button">
                                    <label className="nav-button-text">Labinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Labb.Mro.Reports') ? (
                            <div className="nav-item">
                                <NavLink to="/lab-mro-reports" className="nav-button">
                                    <label className="nav-button-text">MROinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Labb.Client.Management') ? (
                            <div className="nav-item">
                                <NavLink to="/clients" className="nav-button">
                                    <label className="nav-button-text">Clientinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Labb.Billing.Reports') ? (
                            <div className="nav-item">
                                <NavLink to="/lab-billing-reports" className="nav-button">
                                    <label className="nav-button-text">Billinator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.Badganator') ? (
                            <div className="nav-item">
                                <NavLink to="/badganator" className="nav-button">
                                    <label className="nav-button-text">Badganator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="nav-item">
                    <div>
                        {userRoles.includes('Freddy.Pricanator') ? (
                            <div className="nav-item">
                                <NavLink to="/pricing-tool" className="nav-button">
                                    <label className="nav-button-text">Pricanator</label>
                                </NavLink>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/*<div className="nav-item">*/}
                {/*<NextScreen />*/}
                {/*</div>*/}
                {userRoles.includes('Labb.Email') ?
                    <div className="nav-item">
                        <div className="fetch-mail-icon-wrapper">
                            <FetchMail
                                instance={msalInstance}
                                specimenLinkedEmails={specimenLinkedEmails}
                                specimenUnlinkedEmails={specimenUnlinkedEmails}
                                setSpecimenUnlinkedEmails={setSpecimenUnlinkedEmails}
                                setSpecimenLinkedEmails={setSpecimenLinkedEmails}
                                setSupportEmailMessages={setSupportEmailMessages}
                                supportEmailMessages={supportEmailMessages}
                                setUnprocessedEmails={setUnprocessedEmails}
                                unprocessedEmails={unprocessedEmails}
                                exclusionsEmailList={exclusionsEmailList}
                                exclusionsSubjectList={exclusionsSubjectList}
                                initialState={initialState}
                                setNewLinkedEmail={setNewLinkedEmail}
                                newLinkedEmail={newLinkedEmail}
                                setNewUnlinkedEmail={setNewUnlinkedEmail}
                                newUnlinkedEmail={newUnlinkedEmail}
                                setErrorMessage={setErrorMessage}
                                errorMessage={errorMessage}
                                successMessage={successMessage}
                                setSuccessMessage={setSuccessMessage}

                            />
                        </div>
                    </div> : null
                }
                {userRoles.includes('Freddy.Notifier') && (
                    <div className="nav-item">
                        <div>
                            <div className="nav-item">
                                <div onClick={handleOpenNotificationModal} className="nav-button">
                                    <img src={BellIcon} alt="Notification" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {
                    userRoles.includes('Labb.Random.Selection') ?
                        <div className="nav-item">
                            {loadingTable ? (
                                <LoadingTable/>
                            ) : (
                                <div className="nav-item-random-donor">
                                    <form onSubmit={handleUploadRandomDonorListClick}>
                                        <div className="nav-button-random-donor">
                                            <img src={RandomDonorIcon} alt="Random Donor"/>
                                            <input
                                                type="file"
                                                style={{display: 'none'}}
                                                ref={hiddenFileInput}
                                                onChange={handleUploadRandomDonorList}
                                                multiple
                                            />
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div> : null
                }
                <div className="nav-item">
                    <div className="nav-item-logout">
                        <button className="nav-button-logout" onClick={logoutAndSetUserStatus}>
                            <img src={LogoutIcon} alt="Logout"/>
                        </button>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <Tabs tabOptions={tabOptions} />*/}
                {/*</div>*/}
            </div>
        </header>
    );
}

export default Header;



