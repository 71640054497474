import LatestSelectScreen from "./LatestSelectScreen";
import React from "react";
import './LatestSelectScreenSides.css';

function LatestSelectScreenSides({sideInfo}) {

    return (
        <div className="latest-select-screen-sides">
            {
                sideInfo ?
                    sideInfo.map((selectScreen) => {
                        return (
                            <div>
                                <LatestSelectScreen key={selectScreen[0].test_id}
                                                    selectScreen={selectScreen}/>
                            </div>

                        )
                    }) : null
            }

        </div>
    )
}

export default LatestSelectScreenSides;