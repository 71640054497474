import React from 'react';
import AddSpecimenNote from '../../../notes/add_specimen_note/AddSpecimenNote';
import SendSpecimenEmail from '../../../email/send_specimen_email/SendSpecimenEmail';

function SpecimenModalOverlays({
                                   showNoteModal,
                                   userRoles,
                                   selectedRow,
                                   setShowNoteModal,
                                   setSelectedRow,
                                   setSelectedOption,
                                   selectedOption,
                                   setIsSendEmailTemplateActive,
                                   emailTemplateOptions,
                                   setEmailTemplateOptions,
                                   selectedTemplate,
                                   filteredTemplates,
                                   setSelectedTemplate,
                                   setFilteredTemplates,
                                   setErrorMessage,
                                   setSuccessMessage,
                                   labStatusScreens,
                                   setSortedScreens,
                                   showEmailModal,
                                   setSpecimenShowEmailModal,
                               }) {
    return (
        <div className="lab-billing-reports-modal-winodws">
            <div className="add-note-container">
                {showNoteModal && (
                    <div className="recent-lab-screens-add-specimen-note">
                        <AddSpecimenNote
                            userRoles={userRoles}
                            select_screen_id={selectedRow}
                            setShowNoteModal={setShowNoteModal}
                            onClose={() => setSelectedRow(null)}
                            setSelectedOption={setSelectedOption}
                            selectedOption={selectedOption}
                            setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                            emailTemplateOptions={emailTemplateOptions}
                            setEmailTemplateOptions={setEmailTemplateOptions}
                            selectedTemplate={selectedTemplate}
                            filteredTemplates={filteredTemplates}
                            setSelectedTemplate={setSelectedTemplate}
                            setFilteredTemplates={setFilteredTemplates}
                            setErrorMessage={setErrorMessage}
                            setSuccessMessage={setSuccessMessage}
                            labStatusScreens={labStatusScreens}
                            setSortedScreens={setSortedScreens}
                            setShowEmailModal={setShowEmailModal}
                            setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                        />
                    </div>
                )}
                {showEmailModal && (
                    <div className="recent-lab-screens-specimen-email">
                        <SendSpecimenEmail
                            setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            emailTemplateOptions={emailTemplateOptions}
                            setEmailTemplateOptions={setEmailTemplateOptions}
                            filteredTemplates={filteredTemplates}
                            setFilteredTemplates={setFilteredTemplates}
                            select_screen_id={selectedRow}
                            setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}


export default SpecimenModalOverlays;