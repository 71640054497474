import React from 'react';
import './AgreementFrame.css';

export default function AgreementFrame({ batchInfo }) {
    return (
        <div className="batch-card-agreement-data-frame">
            <div className="batch-card-agreement-data">
                <div className="batch-card-agreement-rate">
                    <h2 className="batch-card-agreement-rate-header" >
                        FV - {batchInfo.batch_agreement}%
                    </h2>
                    <h4 className="batch-card-agreement-rate-sub-header" style={{ marginTop: '-20px' }}>
                        LR - {batchInfo.instant_agreement}%
                    </h4>
                </div>
                <div className="batch-card-date">
                    <h4 className="batch-card-agreement-rate-sub-header">
                        {/*NA - --.--%*/}
                    </h4>
                </div>
            </div>
        </div>
    );
}
