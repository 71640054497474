import "./Badganator.css"
import "rsuite/dist/rsuite.min.css";
import makeAnimated from 'react-select/animated';
import "rsuite/dist/rsuite.min.css";
import React, {useEffect, useRef, useState} from "react";
import CreatableSelect from 'react-select/creatable';
import {default as axios} from "axios";
import {config} from "../Constants";


function Badganator(props) {
    const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    const [collectorOptions, setCollectorOptions] = useState();
    const [selectCollectorOptions, setSelectCollectorOptions] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false)
    const updateLoadingTable = () => setLoadingTable(value => !value)
    const [download, setDownload] = useState(false);
    const updateDownload = () => setDownload(value => !value);
    const [collectorBadgeUrl, setCollectorBadgeUrl] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const url = config.url.FREDDY_API_URL
    const animatedComponents = makeAnimated();

    const loadCollectorOptions = () => {
        const axios = require('axios').default;
        const element = document.querySelector('#post-request-error-handling .formData-id');
        axios({
            method: "POST",
            baseURL: url,
            url: "/get-collector-options",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setCollectorOptions(response.data)
        })).catch(error => {
            element.parentElement.innerHTML = `Error: ${error.message}`;
            console.error('There was an error!', error);
        });
    }

    useEffect(() => loadCollectorOptions(), []);

    const handleCollectorChange = (selectCollectorOptions) => {
        setSelectCollectorOptions(selectCollectorOptions)
    };

    function submitCollectorOptions() {
        const formData = new FormData();
        try {
            formData.set('select_collectors', JSON.stringify(selectCollectorOptions));
            updateLoadingTable();
            axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/get-collector-badge",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                setCollectorBadgeUrl(response.data);
                updateLoadingTable();
            })).catch(error => {
                updateLoadingTable();
                const element = document;
                // if an error occurs, create a link element and add it to the document
                const link = element.createElement("a");
                element.body.appendChild(link);

                // show an error pop up with the error message
                window.alert("An error occurred: " + error.message);
            });
        } catch (error) {
            updateLoadingTable();
            const element = document;
            // if an error occurs, create a link element and add it to the document
            const link = element.createElement("a");
            element.body.appendChild(link);

            // show an error pop up with the error message
            window.alert("An error occurred: " + error.message);
        }
    }

    function downloadCollectorBadge(e) {
        e.preventDefault();
        downloadBadges(collectorBadgeUrl)
    }

    function downloadBadges(urls) {
        urls.forEach(({url, filename}) => {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        });
    }


    return (
        <div className="collector-badge-main-frame">
            {loadingTable ? null :
                <div className="collector-badge-header">
                    <div className="collector-badge-header">
                        <div className="collector-badge-collector-selector" style={{marginRight: '100px'}}>
                            <CreatableSelect
                                components={animatedComponents}
                                placeholder="Select Clients..."
                                // isMulti
                                className="multi-selector-clients"
                                closeMenuOnSelect={true}
                                options={collectorOptions}
                                onChange={handleCollectorChange}
                                value={selectCollectorOptions}
                            />
                        </div>
                        <div className="get-collector-badge-button-frame" style={{marginRight: '100px'}}>
                            <button className="get-collector-badge-button" onClick={(e) => submitCollectorOptions(e)}>
                                <label className="get-collector-badge-button-text">
                                    GET BADGE
                                </label>
                            </button>
                        </div>
                        <div className="pre-set-report-icon-frame" onClick={(e) => downloadCollectorBadge(e)}>
                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3 21H21M17.8793 11H14.05C14.0224 11 14 10.9776 14 10.95V4C14 3.44772 13.5523 3 13 3H11C10.4477 3 10 3.44772 10 4V10.95C10 10.9776 9.97761 11 9.95 11H6.12071C6.07617 11 6.05386 11.0539 6.08536 11.0854L11.9646 16.9646C11.9842 16.9842 12.0158 16.9842 12.0354 16.9646L17.9146 11.0854C17.9461 11.0539 17.9238 11 17.8793 11Z"
                                    stroke="#657390" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default Badganator;


