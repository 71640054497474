import './ReadStaffingUsageBarChart.css';
import "rsuite/dist/rsuite.min.css";
import {
    BarChart
} from 'reaviz';
import React, {useState} from "react";
function ReadStaffingUsageBarChart({staffingUsageData}) {

    // Define a custom color scheme to color every 3 bars the same color
    const defaultColors = ['#007bff', '#28a745', '#ffc107', '#dc3545', '#6c757d', '#17a2b8', '#6610f2', '#fd7e14', '#20c997', '#343a40'];
    const customColors = [
        defaultColors[0], // Use the default first color for the first 3 bars
        defaultColors[0],
        defaultColors[0],
        defaultColors[1], // Use the default second color for the next 3 bars
        defaultColors[1],
        defaultColors[1],
        defaultColors[2], // Use the default third color for the next 3 bars
        defaultColors[2],
        defaultColors[2],
        defaultColors[3], // Repeat the pattern for any additional bars
        defaultColors[3],
        defaultColors[3],
        defaultColors[4],
        defaultColors[4],
        defaultColors[4],
        defaultColors[5],
        defaultColors[5],
        defaultColors[5],
        defaultColors[6],
        defaultColors[6],
        defaultColors[6],
        defaultColors[7],
        defaultColors[7],
        defaultColors[7],
        defaultColors[8],
        defaultColors[8],
        defaultColors[8],
        defaultColors[9],
        defaultColors[9],
        defaultColors[9],
    ];


    return (
        <div className="usage-chart-frames">
            <div className="usage-staffing-chart-frame">
                <div style={{ height: '50vh', width: '100vh' }}>
                    {staffingUsageData ? <ReadStaffingUsageBarChart staffingUsageData={staffingUsageData}/> : <LoadingTable/>}
                </div>
            </div>
        </div>

    )
}

export default ReadStaffingUsageBarChart;
