import React, {useEffect, useState} from 'react';
import {Client} from '@microsoft/microsoft-graph-client';
import {emailTokenRequest} from "../../../authConfig";
import "./FetchMail.css";
import axios from "axios";
import {config} from "../../Constants";
import UnprocessedEmails from "../unprocessed_emails/UnprocessedEmails";

const FetchMail = ({
                       instance,
                       exclusionsEmailList,
                       exclusionsSubjectList,
                       setSpecimenLinkedEmails,
                       specimenLinkedEmails,
                       specimenUnlinkedEmails,
                       setSpecimenUnlinkedEmails,
                       setUnprocessedEmails,
                       unprocessedEmails,
                       handleItemClick,
                       setSupportEmailMessages,
                       supportEmailMessages,
                       initialState,
                       newLinkedEmail,
                       setNewLinkedEmail,
                       setNewUnlinkedEmail,
                       newUnlinkedEmail,
                       errorMessage,
                       setErrorMessage,
                       successMessage,
                       setSuccessMessage
                   }) => {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");
    const [emailAccessToken, setEmailAccessToken] = useState(localStorage.getItem("emailAccessToken"));

    useEffect(() => {
        const getToken = async () => {
            try {
                const {accessToken, account} = await instance.acquireTokenSilent(emailTokenRequest);
                setEmailAccessToken(accessToken);
                instance.setActiveAccount(account);
            } catch (error) {
                console.error(error);
            }
        };
        getToken();
    }, [instance]);

    useEffect(() => {
        if (emailAccessToken) {
            localStorage.setItem("emailAccessToken", emailAccessToken);
        }
    }, [emailAccessToken]);

    function filterEmailContent(content) {
        const lines = content.split(/\r?\n/);
        const filteredLines = [];

        let skipLine = false;
        let signatureDetected = false;

        for (let i = lines.length - 1; i >= 0; i--) {
            const line = lines[i].trim();

            if (line === '') {
                // Skip empty lines
                continue;
            }

            if (!skipLine) {
                if (/^\s*[-_]{2,}\s*$/.test(line)) {
                    // Detected a line consisting of dashes or underscores
                    signatureDetected = true;
                    skipLine = true;
                    continue;
                }

                if (/^\s*thanks\s*$/i.test(line)) {
                    // Detected a line containing only "thanks" (case-insensitive)
                    signatureDetected = true;
                    skipLine = true;
                    continue;
                }

                if (signatureDetected && /^\s*>/.test(line)) {
                    // Detected a line starting with ">"
                    skipLine = true;
                    continue;
                }

                filteredLines.unshift(line); // Add the line to the filteredLines array
            } else {
                if (/^\s*[\r\n]/.test(line)) {
                    // Detected a line starting with empty spaces, tabs, or line breaks
                    skipLine = false;
                }
            }
        }

        return filteredLines.join('\n');
    }

    useEffect(() => {
        const fetchMail = async () => {
            try {
                const emailAccessToken = localStorage.getItem('emailAccessToken');

                if (!emailAccessToken) {
                    console.error('Access token not acquired from popup request or local.');
                    return;
                }

                const client = Client.init({
                    authProvider: (done) => {
                        done(null, emailAccessToken);
                    },
                });
                const pageSize = 50;
                const recentMessages = await client.api('/me/messages').version('beta').header('Prefer', 'outlook.body-content-type="html"').query({'$top': pageSize}).get();
                const messages = recentMessages.value;
                const fetchedMessageIds = messages.map((message) => message.id);

                const formData = new FormData();
                formData.append("message_ids", fetchedMessageIds);

                const response = await axios({
                    method: "POST",
                    baseURL: url,
                    url: "/check-all-message-ids",
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const formattedResult = response.data;

                formattedResult.map((item) => {
                    const {email_linked, email_processed, message_id, note, email_message_status} = item;
                    return {
                        message_id,
                        email_linked,
                        email_processed,
                        note,
                        email_message_status,
                    };
                });

                // Check if formattedResult is empty
                if (formattedResult.length === 0) {
                    // Treat all messages as unprocessed emails
                    const unprocessedEmails = messages.map((message) => ({
                        message_id: message.id,
                        email_linked: false,
                        email_processed: false,
                        note: null,
                    }));
                    setUnprocessedEmails(unprocessedEmails);

                    // Clear the state variables for linked and unlinked emails
                    setSpecimenLinkedEmails([]);
                    setSpecimenUnlinkedEmails([]);
                    return;
                }

                const linkedMessages = formattedResult.filter((item) => item.email_linked && item.email_processed);
                const unlinkedMessages = formattedResult.filter((item) => !item.email_linked && item.email_processed);

                const linkedEmails = linkedMessages.map((linkedMessage) => {
                    // Find the corresponding message from the original response
                    const message = messages.find((msg) => msg.id === linkedMessage.message_id);
                    return {
                        ...message,
                        ...linkedMessage, // Add the formattedResult properties to the message
                    };
                });

                const unlinkedEmails = unlinkedMessages.map((unlinkedMessage) => {
                    // Find the corresponding message from the original response
                    const message = messages.find((msg) => msg.id === unlinkedMessage.message_id);
                    return {
                        ...message,
                        ...unlinkedMessage, // Add the formattedResult properties to the message
                    };
                });

                // Set the state variables with the actual messages and formattedResult properties
                setSpecimenLinkedEmails(linkedEmails);
                setSpecimenUnlinkedEmails(unlinkedEmails);

                const message_ids = formattedResult.map((message) => message.message_id);
                const messagesWithContent = messages.filter((message) => message_ids.includes(message.id));

                const unprocessedEmails = formattedResult.filter((item) => !item.email_linked && !item.email_processed);
                const unprocessedEmailMessages = unprocessedEmails.map((unprocessedMessage) => {
                    // Find the corresponding message from the original response
                    return messages.find((msg) => msg.id === unprocessedMessage.message_id)
                });

                const cleanedMessages = unprocessedEmailMessages.map((unprocessedMessage) => {
                    const {body} = unprocessedMessage;
                    const filteredContent = filterEmailContent(body.content);
                    return {
                        ...unprocessedMessage,
                        body: {
                            ...body,
                            filtered_content: filteredContent
                        }
                    };
                });

                setUnprocessedEmails(cleanedMessages);

                // Filter out excluded emails from the list
                const filteredUnprocessedMessages = messagesWithContent.filter((message) => {
                    if (!message.body || !message.subject) {
                        return false;
                    }
                    // Check if the email address is not in the exclusionsEmailList
                    const isExcludedEmail = exclusionsEmailList.includes(
                        message.sender?.emailAddress?.address
                    );

                    // Check if the subject contains any string from the exclusionsSubjectList
                    const isExcludedSubject = exclusionsSubjectList.some((subject) =>
                        message.subject.toLowerCase().includes(subject.toLowerCase())
                    );

                    // Include the email if it does not match any exclusion criteria
                    return !(isExcludedEmail || isExcludedSubject);
                });

                const filteredEmailIDs = filteredUnprocessedMessages.map(
                    (message) => message.id
                );

                const unprocessedMessagesData = messagesWithContent.filter(
                    (message) => filteredEmailIDs.includes(message.id)
                );

                // Create copies of linkedEmails and unlinkedEmails
                const linkedEmailsCopy = [...linkedEmails];
                const unlinkedEmailsCopy = [...unlinkedEmails];


                unprocessedMessagesData.forEach((message) => {
                    const subject = message.subject.toLowerCase();
                    const body = message.body.content.toLowerCase();

                    const parser = new DOMParser();

                    const doc = parser.parseFromString(body, 'text/html');
                    const emailContent = doc.documentElement.textContent;

                    const regex = /sd(\d{8})/gi;
                    const subjectMatches = subject.match(regex);
                    const bodyMatches = emailContent.match(regex);
                    const specimenId =
                        subjectMatches ? subjectMatches[0].substring(2) : bodyMatches ? bodyMatches[0].substring(2) : null;

                    if (specimenId) {
                        message.select_screen_id = specimenId; // Add the selectScreenId property to the message object

                        // Check if the message already exists in the linkedEmails state
                        const existingLinkedEmail = linkedEmailsCopy.find((email) => email.id === message.id);
                        if (!existingLinkedEmail) {
                            const emailRegex = /from: .+?[\r\n]+to: .+?[\r\n]+subject: .+?[\r\n]+date: .+?[\r\n]+[\r\n]+([\s\S]+?)(?=from: .+?[\r\n]+to: .+?[\r\n]+subject: .+?[\r\n]+date: .+?[\r\n]+[\r\n]+|$)/gi;

                            const mostRecentContentMatches = emailContent.match(emailRegex);
                            if (mostRecentContentMatches && mostRecentContentMatches.length > 0) {
                                message.body.filtered_content = mostRecentContentMatches[mostRecentContentMatches.length - 1];
                            } else {
                                message.body.filtered_content = emailContent;
                            }

                            message.email_linked = true;
                            linkedEmailsCopy.push(message);
                        }
                    } else {
                        // Check if the message already exists in the unlinkedEmails state
                        const existingUnlinkedEmail = unlinkedEmailsCopy.find((email) => email.id === message.id);
                        if (!existingUnlinkedEmail) {
                            message.body.filtered_content = emailContent;
                            message.email_linked = false;
                            unlinkedEmailsCopy.push(message);
                        }
                    }
                });

                const supportEmailList = ['support@labb.com', 'Support@labb.com']; // Update with your desired list of support email addresses

                const supportEmails = filteredUnprocessedMessages.filter((message) => {
                    const toRecipients = message.toRecipients || [];
                    const emailAddresses = toRecipients.map((recipient) => recipient.emailAddress.address);
                    return emailAddresses.some((address) => supportEmailList.includes(address));
                });

                // Filter out support emails from the unlinked emails list
                const updatedUnlinkedEmails = unlinkedEmails.filter((message) => {
                    const messageId = message.id;
                    return !supportEmails.some((email) => email.id === messageId);
                });

                // clear the state variables
                setSpecimenLinkedEmails([]);
                setSpecimenUnlinkedEmails([]);
                setSupportEmailMessages([]);

                // Update the state variables
                setSpecimenLinkedEmails(linkedEmails);
                setSpecimenUnlinkedEmails(updatedUnlinkedEmails);
                setSupportEmailMessages(supportEmails);

            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        // Initial fetch
        fetchMail();

        const intervalId = setInterval(fetchMail, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="fetch-mail-icon-frame">
            {unprocessedEmails.length > 0 && (
                <div>
                    <UnprocessedEmails
                        handleItemClick={handleItemClick}
                        unprocessedEmails={unprocessedEmails}
                        exclusionsEmailList={exclusionsEmailList}
                        exclusionsSubjectList={exclusionsSubjectList}
                        setSpecimenLinkedEmails={setSpecimenLinkedEmails}
                        specimenLinkedEmails={specimenLinkedEmails}
                        setSpecimenUnlinkedEmails={setSpecimenUnlinkedEmails}
                        specimenUnlinkedEmails={specimenUnlinkedEmails}
                        setSupportEmailMessages={setSupportEmailMessages}
                        supportEmailMessages={supportEmailMessages}
                        setNewLinkedEmail={setNewLinkedEmail}
                        newLinkedEmail={newLinkedEmail}
                        setNewUnlinkedEmail={setNewUnlinkedEmail}
                        newUnlinkedEmail={newUnlinkedEmail}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                </div>
            )}
        </div>
    );
};

export default FetchMail;
