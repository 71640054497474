import {useEffect, useState} from "react";
import "./ManageTemplates.css";
import AddEmailTemplate from "./add_email_template/AddEmailTemplate";
import {config} from "../../Constants";
import axios from "axios";
import EmailTemplatesList from "./email_templates_list/EmailTemplatesList";

export default function ManageTemplates({
                                            emailTemplateOptions,
                                            setEmailTemplateOptions,
                                            setFilteredTemplates,
                                            filteredTemplates,
                                        }) {
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL;
    const [isAddEmailTemplateActive, setIsAddEmailTemplateActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    function getEmailTemplateOptions() {
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-email-template-options",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                setEmailTemplateOptions(response.data);
                setFilteredTemplates(response.data);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => getEmailTemplateOptions(), []);

    useEffect(() => {
        if (searchTerm) {
            const filtered = emailTemplateOptions.filter((template) =>
                template.email_template_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredTemplates(filtered);
        } else {
            setFilteredTemplates(emailTemplateOptions);
        }
    }, [emailTemplateOptions, searchTerm]);

    return (
        <div className="manage-templates-container">
            <div className="manage-templates-list">
                <div className="manage-templates-list-search">
                    <input
                        type="text"
                        placeholder="Search templates"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <EmailTemplatesList
                    emailTemplateOptions={emailTemplateOptions}
                    filteredTemplates={filteredTemplates}
                    selectedTemplate={selectedTemplate}
                    onTemplateSelect={setSelectedTemplate}
                    setIsAddEmailTemplateActive={setIsAddEmailTemplateActive}
                    setFilteredTemplates={setFilteredTemplates}
                    setEmailTemplateOptions={setEmailTemplateOptions}
                />
            </div>
            <div className="manage-template-actions">
                {isAddEmailTemplateActive || selectedTemplate ? (
                    <div className="add-email-template">
                        <AddEmailTemplate
                            setEmailTemplateOptions={setEmailTemplateOptions}
                            emailTemplateOptions={emailTemplateOptions}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            setIsAddEmailTemplateActive={setIsAddEmailTemplateActive}
                        />
                    </div>
                ) : null}
                {isAddEmailTemplateActive ?
                    null :
                    <button
                        type="button"
                        className="manage-template-add-button"
                        onClick={() => setIsAddEmailTemplateActive(true)}
                    >
                        CREATE TEMPLATE
                    </button>
                }
            </div>
        </div>
    );
}
