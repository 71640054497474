import React, {useState} from 'react';
import {IconButton, Table} from 'rsuite';
import './LabbClientSettingsTable.css';
import TicketIcon from '../../../../assets/icons/Ticket.svg';
import ExpandIcon from '../../../../assets/icons/Expand.svg';
import ContractIcon from '../../../../assets/icons/Contract.svg';
import ReportCheckIcon from '../../../../assets/icons/ReportCheck.svg';
import SendIcon from '../../../../assets/icons/Send.svg';

const {Column, HeaderCell, Cell} = Table;

const LabbClientSettingsTable = ({
                                     allClientData,
                                     setShowTicketModal,
                                     handleClientClick,
                                     handleClientName,
                                     setShowClientEmailModal,
                                 }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [totalHeight, setTotalHeight] = useState();

    const updateTotalHeight = totalHeight => {
        setTotalHeight(totalHeight)
    }

    const rowKey = 'id';


    const handleRowExpanded = (rowData, dataKey) => {
        let nextExpandedRowKeys;

        if (expandedRowKeys.includes(rowData[rowKey])) {
            nextExpandedRowKeys = []; // If row is currently expanded, collapse it
        } else {
            nextExpandedRowKeys = [rowData[rowKey]]; // Otherwise, set this row to be the only expanded row
        }

        updateTotalHeight(rowData.formatted_organizations.length * 51 + 50);
        setExpandedRowKeys(nextExpandedRowKeys);
    };


    const renderOrganizationCell = (rowData) => {
        if (rowData.formatted_organizations.length > 0) {
            return (
                <div className="labb-client-settings-table-inner">
                    <Table virtualized data={rowData.formatted_organizations}>
                        <Column width={200} align="center">
                            <HeaderCell>ID</HeaderCell>
                            <Cell dataKey="id" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Name</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Automated Billing</HeaderCell>
                            <Cell dataKey="automated_billing">
                                {rowData => (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            disabled
                                            checked={rowData.automated_billing}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Billing Day</HeaderCell>
                            <Cell dataKey="billing_day" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Default Contact ID</HeaderCell>
                            <Cell dataKey="default_contact_id" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Billing Contact ID</HeaderCell>
                            <Cell dataKey="billing_contact_id" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Created At</HeaderCell>
                            <Cell dataKey="created_at" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Updated At</HeaderCell>
                            <Cell dataKey="updated_at" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Deleted At</HeaderCell>
                            <Cell dataKey="deleted_at" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Negative Contact ID</HeaderCell>
                            <Cell dataKey="negative_contact_id" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Non-Negative Contact ID</HeaderCell>
                            <Cell dataKey="non_negative_contact_id" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Show Details</HeaderCell>
                            <Cell dataKey="show_details">
                                {rowData => (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            disabled
                                            checked={rowData.show_details}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Email Link</HeaderCell>
                            <Cell dataKey="email_link">
                                {rowData => (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            disabled
                                            checked={rowData.email_link}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Rapid Service Level</HeaderCell>
                            <Cell dataKey="rapid_service_level" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Tox Service Level</HeaderCell>
                            <Cell dataKey="tox_service_level" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Result Report Type</HeaderCell>
                            <Cell dataKey="result_report_type" />
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Send ECCF Notification</HeaderCell>
                            <Cell dataKey="send_eccf_notification">
                                {rowData => (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            disabled
                                            checked={rowData.send_eccf_notification}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>DOT Service</HeaderCell>
                            <Cell dataKey="dot_service">
                                {rowData => (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            disabled
                                            checked={rowData.dot_service}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        {/* Add more columns for the remaining key-value pairs */}
                    </Table>
                </div>
            );
        }
        return null;
    };


    const formatDate = (dateStr) => {
        if (!dateStr) {
            return ''; // Return an empty string or any default value you prefer
        }

        const date = new Date(dateStr);
        const formatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });

        return formatter.format(date);
    };


    const ExpandCell = ({rowData, dataKey, expandedRowKeys, onChange, ...props}) => (
        <Cell {...props} style={{padding: 5}}>
            <IconButton
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    expandedRowKeys.includes(rowData[rowKey]) ? (
                        <img src={ContractIcon} alt="Expand" />
                    ) : (
                        <img src={ExpandIcon} alt="Expand" />
                    )
                }
            />
        </Cell>
    );



    return (
        <div className="labb-client-settings-table">
            <Table
                rowKey="id"
                shouldUpdateScroll={false}
                rowExpandedHeight={totalHeight}
                autoHeight
                data={allClientData}
                expandedRowKeys={expandedRowKeys}
                renderRowExpanded={renderOrganizationCell}
            >
                <Column width={200} align="center" fixed>
                    <HeaderCell>Account ID</HeaderCell>
                    <Cell dataKey="uuid"/>
                </Column>
                <Column width={250} align="center" fixed>
                    <HeaderCell>Reseller</HeaderCell>
                    <Cell dataKey="reseller_name"/>
                </Column>
                <Column width={300} align="center" fixed>
                    <HeaderCell>Client Name</HeaderCell>
                    <Cell dataKey="client_name"/>
                </Column>
                <Column width={50} align="center" fixed>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <div
                                onClick={() => window.open(rowData.system_link)}
                                className="client-system-link-icon"
                            >
                                <img src={ReportCheckIcon} alt="Client Settings"/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={50} align="center" fixed>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <div
                                onClick={() => {
                                    handleClientClick(rowData.account_id);
                                    handleClientName(rowData.client_name);
                                    setShowTicketModal(true);
                                }}
                            >
                                <img src={TicketIcon} alt="Note Icon"/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={50} align="center" fixed>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <div
                                onClick={() => {
                                    handleClientClick(rowData.account_id);
                                    handleClientName(rowData.client_name);
                                    setShowClientEmailModal(true);
                                }}
                            >
                                <img src={SendIcon} alt="Email"/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={50} align="center" fixed>
                    <HeaderCell></HeaderCell>
                    <ExpandCell
                        dataKey="id"
                        expandedRowKeys={expandedRowKeys}
                        onChange={handleRowExpanded}>
                        {renderOrganizationCell}
                    </ExpandCell>

                </Column>

                <Column width={300} align="center">
                    <HeaderCell>Rapid Service Level</HeaderCell>
                    <Cell dataKey="rapid_service_level"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Rapid Lab</HeaderCell>
                    <Cell dataKey="rapid_lab"/>
                </Column>
                <Column width={300} align="center">
                    <HeaderCell>Tox Service Level</HeaderCell>
                    <Cell dataKey="tox_service_level"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Tox Lab</HeaderCell>
                    <Cell dataKey="tox_lab"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>MRO</HeaderCell>
                    <Cell dataKey="mro_account_name" />
                </Column>
                {/*<Column width={200} align="center">*/}
                {/*    <HeaderCell>MRO Account ID</HeaderCell>*/}
                {/*    <Cell dataKey="mro_account_id"/>*/}
                {/*</Column>*/}
                {/*<Column width={100} align="center">*/}
                {/*    <HeaderCell>DOT Service</HeaderCell>*/}
                {/*    <Cell dataKey="dot_service"/>*/}
                {/*</Column>*/}
                {/*<Column width={200} align="center">*/}
                {/*    <HeaderCell>DOT Lab</HeaderCell>*/}
                {/*    <Cell dataKey="dot_lab"/>*/}
                {/*</Column>*/}
                <Column width={100} align="center">
                    <HeaderCell>Organizations</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_organization_portal}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Labb Station</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_labb_access}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>White Label</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_white_label}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Portal Users</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_portal_access}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={125} align="center">
                    <HeaderCell>Donor Database</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_donor_database}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Donor First</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.donor_first}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Email History</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_email_history}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Network</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.is_network}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={150} align="center">
                    <HeaderCell>Automated Billing</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_automated_billing}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Fastest Labs</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.fastest_labs_location}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={150} align="center">
                    <HeaderCell>Document Upload</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_document_upload}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Badge Login</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_badge_login}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>API Access</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_api_access}/>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={150} align="center">
                    <HeaderCell>Custom Consent</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_custom_consent}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={175} align="center">
                    <HeaderCell>Observed Collection</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_observed_collection}/>
                            </div>
                        )}
                    </Cell>
                </Column>


                {/*<Column width={200} align="center">*/}
                {/*    <HeaderCell>Delayed Testing</HeaderCell>*/}
                {/*    <Cell>*/}
                {/*        {rowData => (*/}
                {/*            <div className="checkbox-container">*/}
                {/*                <input type="checkbox" className="checkbox" disabled*/}
                {/*                       checked={rowData.has_delayed_testing}/>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </Cell>*/}
                {/*</Column>*/}

                {/*<Column width={100} align="center">*/}
                {/*    <HeaderCell>Newest Report</HeaderCell>*/}
                {/*    <Cell>*/}
                {/*        {rowData => (*/}
                {/*            <div className="checkbox-container">*/}
                {/*                <input type="checkbox" className="checkbox" disabled*/}
                {/*                       checked={rowData.use_newest_report}/>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </Cell>*/}
                {/*</Column>*/}

                <Column width={100} align="center">
                    <HeaderCell>Sample Check</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="checkbox-container">
                                <input type="checkbox" className="checkbox" disabled
                                       checked={rowData.has_sample_check}/>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Updated At</HeaderCell>
                    <Cell>
                        {rowData => <p>{formatDate(rowData.updated_at)}</p>}
                    </Cell>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Reseller Agent ID</HeaderCell>
                    <Cell dataKey="reseller_agent_id"/>
                </Column>
                {/*<Column width={100} align="center">*/}
                {/*    <HeaderCell>Accounting ID</HeaderCell>*/}
                {/*    <Cell dataKey="accounting_id"/>*/}
                {/*</Column>*/}

                {/*<Column width={300} align="center">*/}
                {/*    <HeaderCell>Secondary Result Reporter ID</HeaderCell>*/}
                {/*    <Cell dataKey="secondary_result_reporter_id"/>*/}
                {/*</Column>*/}
                <Column width={400} align="center">
                    <HeaderCell>Notes</HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="client-notes-paragraph-container">
                                <p className="client-notes-paragraph">
                                    {rowData.notes}
                                </p>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Timezone</HeaderCell>
                    <Cell dataKey="timezone"/>
                </Column>
                {/*<Column width={100} align="center">*/}
                {/*    <HeaderCell>Type</HeaderCell>*/}
                {/*    <Cell dataKey="type"/>*/}
                {/*</Column>*/}

                <Column width={200} align="center">
                    <HeaderCell>Phone</HeaderCell>
                    <Cell dataKey="phone"/>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Webhook</HeaderCell>
                    <Cell dataKey="webhook"/>
                </Column>

                <Column width={175} align="center">
                    <HeaderCell>Show Sample Check</HeaderCell>
                    <Cell dataKey="show_sample_check"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Admin ID</HeaderCell>
                    <Cell dataKey="admin_id"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>MRO Result Reporter ID</HeaderCell>
                    <Cell dataKey="mro_result_reporter_id"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Show Details</HeaderCell>
                    <Cell dataKey="show_details"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Positive String</HeaderCell>
                    <Cell dataKey="positive_string"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Organization String</HeaderCell>
                    <Cell dataKey="organization_string"/>
                </Column>

                <Column width={200} align="center">
                    <HeaderCell>Use Client Details on Report</HeaderCell>
                    <Cell dataKey="use_client_details_on_report"/>
                </Column>
                <Column width={250} align="center">
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email"/>
                </Column>
                <Column width={500} align="center">
                    <HeaderCell>Custom Consent</HeaderCell>
                    <Cell dataKey="custom_consent"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Zoho Books ID</HeaderCell>
                    <Cell dataKey="zoho_books_id"/>
                </Column>

                <Column width={100} align="center">
                    <HeaderCell>Plan Type</HeaderCell>
                    <Cell dataKey="plan_type"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Trial Ends</HeaderCell>
                    <Cell>
                        {rowData => <p>{formatDate(rowData.trial_ends)}</p>}
                    </Cell>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>List All Drugs</HeaderCell>
                    <Cell dataKey="list_all_drugs"/>
                </Column>
                <Column width={100} align="center">
                    <HeaderCell>Panel Price</HeaderCell>
                    <Cell dataKey="can_manage_panel_price"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Result Report Type</HeaderCell>
                    <Cell dataKey="result_report_type"/>
                </Column>
                <Column width={250} align="center">
                    <HeaderCell>Send ECCF Notification</HeaderCell>
                    <Cell dataKey="send_eccf_notification"/>
                </Column>
                <Column width={200} align="center">
                    <HeaderCell>Started</HeaderCell>
                    <Cell>
                        {rowData => <p>{formatDate(rowData.created_at)}</p>}
                    </Cell>
                </Column>
            </Table>
        </div>
    )
        ;
};

export default LabbClientSettingsTable;
