import React, {useEffect, useState} from "react";
import axios from "axios";
import "./CreateHubspotTicket.css";
import {config} from "../Constants";
import Select from "react-select";
import RefreshIcon from "../../../src/assets/icons/Refresh.svg";

const CreateHubspotTicket = ({
                                 userRoles,
                                 selectedAccountId,
                                 selectedClientName,
                                 setErrorMessage,
                                 setSuccessMessage,
                                 showTicketModal,
                                 setShowTicketModal,
                             }) => {
        const url = config.url.FREDDY_API_URL;
        const accessToken = localStorage.getItem("accessToken");

        // State variables
        const [clientName, setClientName] = useState(selectedClientName);
        const [collector, setCollector] = useState(null);
        const [email, setEmail] = useState("");
        const [phone, setPhone] = useState("");
        const [permissions, setPermissions] = useState({});
        const [subject, setSubject] = useState("");
        const [bodyContent, setBodyContent] = useState("");
        const [clientCollectors, setClientCollectors] = useState([]);
        const [descriptionButtonPressedContent, setDescriptionButtonPressedContent] =
            useState("");
       const [showSuggestions, setShowSuggestions] = useState(false);
        const [textBodySuggestions, setTextBodySuggestions] = useState([]);
        const [showBodySuggestions, setShowBodySuggestions] = useState(false);
        const [textSubjectSuggestions, setTextSubjectSuggestions] = useState([]);
        const [showSubjectSuggestions, setShowSubjectSuggestions] = useState(false);



        // Helper functions
        const handleSubjectSuggestions = async (e) => {
            const inputValue = e.target.value;
            setSubject(inputValue);
            const formData = new FormData();
            const modelInstructions = "Evaluate the provided given subject and reword the subject in a clear and consist manner.  Please make this no more then 15 words or one short sentence. Here is the text:"
            formData.append("model_instructions", modelInstructions);
            formData.append("text", subject)

            try {
                const response = await axios({
                    method: "POST",
                    baseURL: url,
                    data: formData,
                    url: "/get-text-suggestions",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                });

                const suggestions = response.data.data.text;
                setTextSubjectSuggestions(suggestions);
                setShowSubjectSuggestions(true);
                // Handle the suggestions, e.g., update state with the suggestions
                console.log("Ticket name suggestions:", suggestions);
            } catch
                (error) {
                setErrorMessage("Error generating ticket name suggestions:", error);
                console.error("Error generating ticket name suggestions:", error);
                // Handle error or display error message
            }
        };
        // Helper functions
        const handleBodySuggestions = async (e) => {
            const inputValue = e.target.value;
            setSubject(inputValue);
            const formData = new FormData();
            const modelInstructions = "Evaluate the provided given description of the problem and reword the description in a clear and consist manner. Add questions regarding he subject matter if need be. Here is the text:"
            formData.append("model_instructions", modelInstructions);
            formData.append("text", bodyContent)

            try {
                const response = await axios({
                    method: "POST",
                    baseURL: url,
                    data: formData,
                    url: "/get-text-suggestions",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                });

                const suggestions = response.data.data.text;
                setTextBodySuggestions(suggestions);
                setShowSuggestions(true);
                // Handle the suggestions, e.g., update state with the suggestions
                console.log("Ticket name suggestions:", suggestions);
            } catch
                (error) {
                setErrorMessage("Error generating ticket name suggestions:", error);
                console.error("Error generating ticket name suggestions:", error);
                // Handle error or display error message
            }
        };

        const handleBodyContentChange = (e) => {
            setBodyContent(e.target.value);
        };

        const handleCollectorNameChange = (selectedOption) => {
            if (selectedOption) {
                const selectedCollector = clientCollectors.find(
                    (collector) => collector.screener_id === selectedOption.value
                );
                if (selectedCollector) {
                    setCollector(selectedCollector);
                    setPhone(selectedCollector.screener_phone);
                    setEmail(selectedCollector.screener_email);
                    setPermissions({
                        canManageResults: selectedCollector.can_manage_results,
                        canManageScreeners: selectedCollector.can_manage_screeners,
                        canPerformScreens: selectedCollector.can_perform_screens,
                        canManagePanels: selectedCollector.can_manage_panels,
                    });
                }
            } else {
                setCollector(null);
                setPhone("");
                setEmail("");
                setPermissions({});
            }
        };

        const handleEmailChange = (e) => {
            setEmail(e.target.value);
        };

        const handlePhoneChange = (e) => {
            setPhone(e.target.value);
        };


        const handleSubjectChange = (e) => {
            setSubject(e.target.value);
        };

        const getClientCollectors = () => {
            const formData = new FormData();
            formData.append("account_id", selectedAccountId);
            axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/get-client-collectors",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    setClientCollectors(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        };

        useEffect(() => {
            getClientCollectors();
        }, [selectedAccountId]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const ticketData = {
            account_id: selectedAccountId,
            client_name: clientName,
            collector_name: collector ? collector.screener_id.toString() : "",
            email: email,
            phone: phone,
            subject: subject,
            body_content: bodyContent,
        };

        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                url: "/create-hubspot-ticket",
                data: ticketData,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });



        } catch (error) {
            setErrorMessage("Error creating ticket:", error);
            console.error("Error creating ticket:", error);
            // Handle error or display error message
        }
    };

        // Extract collector names from allClientData
        const allCollectorNames = clientCollectors.map((collector) => ({
            value: collector.screener_id,
            label: `${collector.screener_first_name} ${collector.screener_last_name}`,
        }));

        return (
            <div className="labb-client-create-hubspot-ticket-modal-container">
                <div className="create-hubspot-ticket-modal">
                    <form className="create-hubspot-ticket-form" onSubmit={handleSubmit}>
                        <h2 className="create-hubspot-ticket-heading">
                            Create Ticket for {clientName}
                        </h2>
                        <div className="form-group">
                            <label htmlFor="collector-name" className="form-label">
                                Collector Name:
                            </label>
                            <Select
                                id="client-name"
                                className="form-select"
                                options={allCollectorNames}
                                onChange={handleCollectorNameChange}
                                value={allCollectorNames.find(
                                    (option) => option.value === (collector ? collector.screener_id : "")
                                )}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">
                                Email:
                            </label>
                            <input
                                type="text"
                                id="email"
                                className="form-input"
                                value={email}
                                placeholder="Email"
                                onChange={handleEmailChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone" className="form-label">
                                Phone:
                            </label>
                            <input
                                type="text"
                                id="phone"
                                className="form-input"
                                value={phone}
                                placeholder="Phone"
                                onChange={handlePhoneChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Permissions:</label>
                            <div className="permissions-container">
                                <div className="permission">
                                    <input
                                        type="checkbox"
                                        id="can-manage-results"
                                        checked={permissions.canManageResults}
                                        disabled={!permissions.canManageResults}
                                    />
                                    <label htmlFor="can-manage-results">Can Manage Results</label>
                                </div>
                                <div className="permission">
                                    <input
                                        type="checkbox"
                                        id="can-manage-screeners"
                                        checked={permissions.canManageScreeners}
                                        disabled={!permissions.canManageScreeners}
                                    />
                                    <label htmlFor="can-manage-screeners">Can Manage Screeners</label>
                                </div>
                                <div className="permission">
                                    <input
                                        type="checkbox"
                                        id="can-perform-screens"
                                        checked={permissions.canPerformScreens}
                                        disabled={!permissions.canPerformScreens}
                                    />
                                    <label htmlFor="can-perform-screens">Can Perform Screens</label>
                                </div>
                                <div className="permission">
                                    <input
                                        type="checkbox"
                                        id="can-manage-panels"
                                        checked={permissions.canManagePanels}
                                        disabled={!permissions.canManagePanels}
                                    />
                                    <label htmlFor="can-manage-panels">Can Manage Panels</label>
                                </div>
                                {/* Add similar checkboxes for other permissions */}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="ticket-name" className="form-label">
                                Ticket Name:
                            </label>
                            <input
                                type="text"
                                id="ticket-name"
                                className="form-input"
                                onChange={handleSubjectChange}
                            />
                            {userRoles.includes("Freddy.Suggester") && (
                                <button
                                    type="button"
                                    className="generate-suggestions-button"
                                    onClick={handleSubjectSuggestions}
                                >
                                    <img src={RefreshIcon} alt="Generate"/>
                                </button>
                            )}
                            {textSubjectSuggestions.length > 0 && (
                                <div className="text-suggestions-list">
                                    <div>{textSubjectSuggestions}</div>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="ticket-description" className="form-label">
                                Ticket Description:
                            </label>
                            <textarea
                                id="ticket-description"
                                className="form-textarea"
                                value={bodyContent}
                                onChange={handleBodyContentChange}
                            />

                            {userRoles.includes("Freddy.Suggester") && (
                                <div className="form-submit-button"
                                     onClick={handleBodySuggestions}>
                                    <img src={RefreshIcon} alt="Generate"/>
                                </div>
                            )}
                            {textBodySuggestions.length > 0 && (
                                <div className="text-suggestions-list">
                                    <div>{textBodySuggestions}</div>
                                </div>
                            )}
                        </div>
                        <button type="submit" className="form-submit-button">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        );
    }
;

export default CreateHubspotTicket;
