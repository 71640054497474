import { useEffect, useState } from "react";
import "./SendSpecimenEmail.css";
import SendSpecimenEmailModal from "./send_specimen_email_modal/SendSpecimenEmailModal";
import EmailTemplatesList from "../manage_templates/email_templates_list/EmailTemplatesList";
import CloseIcon from "../../../assets/icons/Close.svg";

const SendSpecimenEmail = ({
                               setIsSendEmailTemplateActive,
                               selectedTemplate,
                               setSelectedTemplate,
                               setEmailTemplateOptions,
                               emailTemplateOptions,
                               filteredTemplates,
                               setFilteredTemplates,
                               select_screen_id,
                               errorMessage,
                               setErrorMessage,
                               successMessage,
                               setSuccessMessage,
                               userRoles,
                               setShowSuggestions,
                               setSpecimenShowEmailModal,
                           }) => {
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (searchTerm) {
            const filtered = emailTemplateOptions.filter((template) =>
                template.email_template_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredTemplates(filtered);
        } else {
            setFilteredTemplates(emailTemplateOptions);
        }
    }, [emailTemplateOptions, searchTerm]);

    const handleCloseModal = () => {
        setSpecimenShowEmailModal(false);
    };

    return (
        <div className="send-specimen-email-wrapper">
            <div className="send-specimen-email-container">
                <div className="send-specimen-email-sidebar">
                    <div className="manage-templates-list">
                        <div className="manage-templates-list-search">
                            <input
                                type="text"
                                placeholder="Search templates"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <EmailTemplatesList
                            emailTemplateOptions={filteredTemplates}
                            selectedTemplate={selectedTemplate}
                            onTemplateSelect={setSelectedTemplate}
                            setEmailTemplateOptions={setEmailTemplateOptions}
                            searchTerm={searchTerm}
                            setFilteredTemplates={setFilteredTemplates}
                        />
                    </div>
                </div>
                <div className="send-specimen-email-content">
                    <div className="send-specimen-email-container-close-icon" onClick={handleCloseModal}>
                        <img src={CloseIcon} alt="Close" />
                    </div>
                    <SendSpecimenEmailModal
                        setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                        selectedTemplate={selectedTemplate}
                        select_screen_id={select_screen_id}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        successMessage={successMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                </div>
            </div>
        </div>
    );
};

export default SendSpecimenEmail;
