import "./LabBillingReportHeader.css";
import React from 'react';
import { DateRangePicker } from 'rsuite';
import CreatableSelect from 'react-select/creatable';
import UploadIcon from '../../../../assets/icons/Upload.svg';
import { default as axios } from "axios";
import { config } from "../../../Constants";

function LabBillingReportHeader({
                                    loadingTable,
                                    updateBillingSummaryDetails,
                                    updateLoadingTable,
                                    setErrorMessage,
                                    selectDateOptions,
                                    handleDateChange,
                                    lastWeek,
                                    lastMonth,
                                    last3Months,
                                    clientOptions,
                                    handleClientChange,
                                    selectClientOptions,
                                    setBillingSummaryUrl,
                                }) {
    const hiddenFileInput = React.useRef(null);
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL;

    const handleReportUploadClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };

    async function handleCsvUpload(event) {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
        formData.append('csv_file', fileUploaded);

        try {
            updateLoadingTable();
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/start-analyze-csv-upload",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            const job_id = response.data.job_id;
            const poll_url = `/get-analyze-csv-upload-result?job_id=${job_id}`;
            const poll_interval = setInterval(async () => {
                try {
                    const res = await axios({
                        method: "GET",
                        baseURL: url,
                        data: formData,
                        url: poll_url,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    });
                    const result = res.data;
                    if (result.status === 'completed') {
                        updateBillingSummaryDetails(result.data);
                        const link = document.createElement("a");
                        link.href = result.billing_summary_url;
                        document.body.appendChild(link);
                        link.click();
                        clearInterval(poll_interval);
                        updateLoadingTable();
                    } else if (result.status === 'failed') {
                        clearInterval(poll_interval);
                        setErrorMessage("An error occurred while processing the CSV file: " + error.message)
                    }
                } catch (error) {
                    clearInterval(poll_interval);
                    setErrorMessage("An error occurred: " + error.message)
                }
            }, 10000); // poll every 10 seconds
        } catch (error) {
            setErrorMessage("An error occurred: " + error.message)
        }
    }

    function submitBillingOptions() {
        const formData = new FormData();

        formData.set('select_options', JSON.stringify(selectOptions));

        updateLoadingTable();

        return axios({
            method: "POST",
            baseURL: url,
            data: formData,
            url: "/start-get-filtered-screens",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then(response => {
                const jobId = response.data['job_id'];
                const pollUrl = `${url}/get-filtered-screens-result?job_id=${jobId}`;

                const pollInterval = setInterval(() => {
                    axios({
                        method: "GET",
                        baseURL: pollUrl,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                        .then(response => {
                            const result = response.data;
                            if (result.status === 'completed') {
                                updateBillingSummaryDetails(result.data);
                                setBillingSummaryUrl(result.billing_summary_url);
                                console.log(result.billing_summary_url)
                                const link = document.createElement("a")
                                link.href = result.billing_summary_url
                                document.body.appendChild(link);
                                link.click()
                                clearInterval(pollInterval);
                                updateLoadingTable();
                            } else if (result.status === 'failed') {
                                setErrorMessage("Error Failed to get billing options");
                                updateLoadingTable(false);
                                clearInterval(pollInterval);
                            }
                        })
                        .catch(error => {
                            setErrorMessage("An error occurred: " + error.message);
                            updateLoadingTable(false);
                            clearInterval(pollInterval);
                        });
                }, 10000); // poll every 10 seconds

                return response; // Return the initial response
            })
            .catch(error => {
                console.error(error);
                setErrorMessage("An error occurred: " + error.message);
                updateLoadingTable(false);
            });
    }

    const selectOptions = [
        {
            "date_range": selectDateOptions,
            "client_id": selectClientOptions,
        }
    ]

    return (
        <div className="lab-billing-reports-header">
            <form onSubmit={handleReportUploadClick}>
                <div className="lab-billing-reports-upload-icon">
                    <div className="lab-billing-reports-upload-button" onClick={handleReportUploadClick}>
                        <img src={UploadIcon} alt="Upload" />
                    </div>
                    <input type="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleCsvUpload} multiple />
                </div>
            </form>
            <DateRangePicker
                showOneCalendar
                disabled={loadingTable}
                className="lab-billing-reports-date-picker"
                appearance="default"
                placeholder="Select Date Range..."
                value={selectDateOptions}
                onChange={handleDateChange}
                ranges={[
                    {
                        label: 'Last Week',
                        value: lastWeek,
                    },
                    {
                        label: 'Last Month',
                        value: lastMonth,
                    },
                    {
                        label: 'Last 3 Months',
                        value: last3Months,
                    },
                ]}
            />
            <div className="lab-billing-reports-client-selector">
                <CreatableSelect
                    placeholder="Select Clients..."
                    className="multi-selector-clients"
                    isDisabled={loadingTable}
                    closeMenuOnSelect={true}
                    options={clientOptions}
                    onChange={handleClientChange}
                    value={selectClientOptions}
                    isMulti
                />
            </div>
            <div className="get-screens-button-frame">
                <div onClick={submitBillingOptions} className="get-screens-lab-billing-button">
                    <label className="get-screens-button-text">GET SCREENS</label>
                </div>
            </div>
        </div>
    );
}

export default LabBillingReportHeader;
