import React from 'react';
import './SupportEmailList.css';
import EmailBody from "../EmailBody";
import SupportEmailBody from "../SupportEmailBody";

const SupportEmailList = ({
                              supportEmailMessages
                          }) => {
    return (
        <div>
            {supportEmailMessages ? (
                <div>
                    <ul className="support-emails-list">
                        {supportEmailMessages.map((message) => (
                            <li
                                key={message.id}
                                className="support-email-item"
                                onClick={() => window.open(message.webLink, '_blank')}
                            >
                                <div className="support-email-subject">{message.subject}</div>
                                <div className="support-email-details">
                                    <div className="support-email-sender">
                                        <span className="label">From:</span> {message.sender?.emailAddress?.address}
                                    </div>
                                    <div className="support-email-received">
                                        <span className="label">Received:</span>{' '}
                                        {new Date(message.receivedDateTime).toLocaleString()}
                                    </div>
                                </div>
                                <div className="support-email-body">
                                    {/*{*/}
                                    {/*    message.body?.content ?*/}

                                    {/*    <SupportEmailBody body={message.body?.content}/>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default SupportEmailList;
