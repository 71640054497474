import React from 'react';
import './ScreenImage.css';

export default function ScreenImage({nextTestInfo, updateStripImages }) {

    const toggleReviewImage = () => {
        updateStripImages(value => !value)
    };

    return (
        <div className="screen-image-main-body">
            <button onClick={toggleReviewImage} className="switch-button">
                <svg className="switch-icon" xmlns="http://www.w3.org/2000/svg" width="21.381" height="18" viewBox="0 0 21.381 18">
                    <g id="Group_92" data-name="Group 92" transform="translate(-1.31 -3)">
                        <path id="Path_2" data-name="Path 2" d="M4,12a8,8,0,0,0,12.5,6.615" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_3" data-name="Path 3" d="M20,12A8,8,0,0,0,7.5,5.385" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_4" data-name="Path 4" d="M3.685,7.57a.4.4,0,0,1,.686,0L6.641,11.4A.4.4,0,0,1,6.3,12H1.71a.4.4,0,0,1-.342-.607Z" fill="#fff"/>
                        <path id="Path_5" data-name="Path 5" d="M20.315,16.43a.4.4,0,0,1-.686,0L17.359,12.6A.4.4,0,0,1,17.7,12H22.29a.4.4,0,0,1,.342.607Z" fill="#fff"/>
                    </g>
                </svg>
            </button>
            <div className="screen-image-data-frame">
                <img className="active-batch-image" src={nextTestInfo.select_test_url} alt="Kit"/>
            </div>
        </div>
    )
}

