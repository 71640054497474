import React from 'react';
import './UserInitial.css';

export default function Initials({initials}) {

    return (
        <div className="user-initials-inspector-initials">
            <div className="user-initials-inspector-tile">
                <div className="user-initials-inspector-button">
                    <h3 className="user-initials-inspector-initials-text">{initials}</h3>
                </div>
            </div>
        </div>
    )
}
