import './ReportGenerator.css';
import "rsuite/dist/rsuite.min.css";
import React, {useEffect, useState} from "react";
import UsageLineChart from "./usage_line_chart/UsageLineChart";
import ReadStaffingUsageBarChart from "./read_staffing_usage_bar_chart/ReadStaffingUsageBarChart";
import {default as axios} from "axios";
import {config} from "../Constants";
import LoadingTable from "../Loading/LoadingTable";
import PositiveRateBarChart from "./postive_rate_bar_chart/PositiveRateBarChart";


function ReportGenerator() {
    const [usageData, setUsageData] = useState([]);
    const [positiveRate, setPositiveRate] = useState([]);
    const [staffingUsageData, setStaffingUsageData] = useState([]);


    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        const url = config.url.FREDDY_API_URL;
        try {
            axios({
                method: "GET",
                baseURL: url,
                url: "/get-reader-staffing-usage-data",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                setStaffingUsageData(response.data);
            })).catch(error => {
                window.alert("An error occurred: " + error.message);
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.log(error)
        }
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        const url = config.url.FREDDY_API_URL;
        try {
            axios({
                method: "GET",
                baseURL: url,
                url: "/get-usage-data",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                setUsageData(response.data);
            })).catch(error => {
                window.alert("An error occurred: " + error.message);
                console.error('There was an error!', error);
            });
        } catch (error) {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        }
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        const url = config.url.FREDDY_API_URL;
        try {
            axios({
                method: "GET",
                baseURL: url,
                url: "/start-get-positive-rates-summary",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }).then(((response) => {
                const jobId = response.data['job_id'];
                const pollUrl = `${url}/get-positive-rates-summary-result?job_id=${jobId}`;
                const pollInterval = setInterval(() => {
                    axios({
                        method: "GET",
                        baseURL: pollUrl,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    }).then(((response) => {
                        if (response.data['data']) {
                            clearInterval(pollInterval);
                            setPositiveRate(response.data['data']['result']);
                        }
                    })).catch(error => {
                        const element = document;
                        // if an error occurs, create a link element and add it to the document
                        const link = element.createElement("a");
                        element.body.appendChild(link);

                        // show an error pop up with the error message
                        window.alert("An error occurred: " + error.message);
                        clearInterval(pollInterval);
                    });
                }, 5000); // poll every second
            })).catch(error => {
                const element = document;
                // if an error occurs, create a link element and add it to the document
                const link = element.createElement("a");
                element.body.appendChild(link);

                // show an error pop up with the error message
                window.alert("An error occurred: " + error.message);
            });
        } catch (error) {
            const element = document;
            // if an error occurs, create a link element and add it to the document
            const link = element.createElement("a");
            element.body.appendChild(link);

            // show an error pop up with the error message
            window.alert("An error occurred: " + error.message);
        }
    }, []);


    return (
        <div className="main-frame">
            <div className="top-row-main-frame">
                <div className="top-row-frame">
                    <div className="top-row-cell">
                        {
                            usageData ? <UsageLineChart usageData={usageData}/> : null
                        }
                    </div>
                    <div className="top-row-cell">
                        {
                            positiveRate ? <PositiveRateBarChart positiveRate={positiveRate}/> : null
                        }
                    </div>
                </div>
            </div>
            <div className="bottom-row-main-frame">
                <div className="bottom-row-frame">
                    <div className="bottom-row-cell">
                        {
                            staffingUsageData ?
                                <ReadStaffingUsageBarChart staffingUsageData={staffingUsageData}/> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )


}

export default ReportGenerator;


