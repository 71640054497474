import React, {useEffect, useState} from 'react';
import './SpecimenUnlinkedEmails.css';
import axios from "axios";
import {config} from "../../../../Constants";
import EmailBody from "../EmailBody";

const SpecimenUnlinkedEmails = ({
                                specimenUnlinkedEmails,
                                }) => {

    return (
        <div>
            {specimenUnlinkedEmails.length > 0 ? (
                <div>
                    <ul className="email-messages-list">
                        {specimenUnlinkedEmails.map((message) => (
                            <li
                                key={message.id}
                                className="email-message-item"
                                onClick={() => window.open(message.webLink, '_blank')}
                            >
                                <div className="email-subject">{message.subject}</div>
                                <div className="email-details">
                                    <div className="email-sender">
                                        <span className="label">From:</span> {message.sender?.emailAddress?.address}
                                    </div>
                                    <div className="email-received">
                                        <span className="label">Received:</span>{' '}
                                        {new Date(message.receivedDateTime).toLocaleString()}
                                    </div>
                                </div>
                                <div className="email-body">
                                    {/*{message.body?.content ?*/}
                                    {/*    <EmailBody body={message.body?.content}/>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default SpecimenUnlinkedEmails;
