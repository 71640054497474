import './RecentLabScreens.css';
import "rsuite/dist/rsuite.min.css";
import EmailIcon from '../../../assets/icons/Email.svg';
import NoteIcon from '../../../assets/icons/Note.svg';
import SendIcon from '../../../assets/icons/Send.svg';
import ReportCheckIcon from '../../../assets/icons/ReportCheck.svg';
import DownloadIcon from '../../../assets/icons/Download.svg';
import BoxIcon from '../../../assets/icons/Box.svg';
import RejectIcon from '../../../assets/icons/Reject.svg';
import CheckIcon from '../../../assets/icons/Check.svg';
import LockIcon from '../../../assets/icons/Lock.svg';
import LaboratoryIcon from '../../../assets/icons/Laboratory.svg';



import React, {useEffect, useRef, useState} from "react";
import {Table} from 'rsuite';
import {default as axios} from "axios";
import {config} from "../../Constants";
import PersonIcon from "../../../assets/icons/Person.svg";
import SaveIcon from "../../../assets/icons/Save.svg";
import SavedIcon from "../../../assets/icons/Saved.svg";
import LoadingTable from "../../Loading/LoadingTable";


function RecentLabScreens({
                              labStatusScreens,
                              setLabStatusScreens,
                              selectedRow,
                              setSelectedRow,
                              setShowNoteModal,
                              setShowEmailModal,
                              userRoles,
                              setErrorMessage,
                              setSuccessMessage,
                              sortLabStatusScreens,
                              setPendingUploadScreens,
                              setPendingReviewScreens,
                              setAttentionRequiredScreens,
                              setApprovedScreens,
                              setCompleteScreens,
                              setMroReviewScreens,
                              setSentToLabScreens,
                              setSentToMroScreens,
                              setSendToLabScreens,
                              setSendToMroScreens,
                          }) {
    const {Column, HeaderCell, Cell, Pagination} = Table;
    const accessToken = localStorage.getItem('accessToken')
    const url = config.url.FREDDY_API_URL;
    const [sortedScreens, setSortedScreens] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(selectedRow); // New state for selected checkbox

    const handleRowClick = (rowData) => {
        setSelectedRow(rowData);
    };

    const handleEmailModalOpen = (selectedRow) => {
        setShowEmailModal(true);
        setSelectedRow(selectedRow);

    };


    async function handleGenerateCombineReport(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/get-specimen-reports",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data.presigned_url) {

                const downloadLink = document.createElement('a');
                downloadLink.href = response.data.presigned_url;
                downloadLink.download = `${device_id}.pdf`;
                downloadLink.click();
            } else {
                setErrorMessage("No Reports Found!")
            }
        } catch (error) {
            console.log(error); // log the upload error
            // fill an error state with the error message
            setErrorMessage("Download error:", error.message)

        }
    }

    // Sort the labStatusScreens array based on the specimen_internal_status property
    useEffect(() => {

        console.log("labStatusScreens", labStatusScreens)
        // Sort the labStatusScreens array based on the specimen_internal_status property
        const sorted = [...labStatusScreens].sort((a, b) => {
            if (a.specimen_internal_status && !b.specimen_internal_status) {
                return -1; // a comes before b
            } else if (!a.specimen_internal_status && b.specimen_internal_status) {
                return 1; // b comes before a
            } else {
                return 0; // no change in order
            }
        });

        setSortedScreens(sorted);
    }, [labStatusScreens]);

    async function handleApproveReview(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append("select_screen_id", device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/approve-specimen-report",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    async function handlePendingReview(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/pending-review-specimen-report",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                console.log(labStatusScreens)

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    async function handleRejectReview(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/reject-specimen-report",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }


    async function handleAwaitingUploadReview(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/awaiting-upload-specimen-report",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }


    async function handleScreenComplete(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-competed-specimen-report",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    async function handleSendToLab(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-specimen-send-to-lab-status",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    async function handleSentToLab(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-specimen-sent-to-lab-status",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    async function handleSendToMro(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-specimen-send-to-mro-status",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }


    async function handleSentToMro(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-specimen-sent-to-mro-status",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            const updatedScreen = response.data.data;

            // Find the index of the updated screen in the current labStatusScreens array
            const updatedIndex = labStatusScreens.findIndex(
                (screen) => screen.device_id === device_id
            );

            if (updatedIndex !== -1) {

                // Replace the screen at updatedIndex with the updatedScreen
                labStatusScreens[updatedIndex] = updatedScreen;

                const {
                    pendingUploadScreens,
                    pendingReviewScreens,
                    attentionRequiredScreens,
                    approvedScreens,
                    completeScreens,
                    sendToLabScreens,
                    sentToLabScreens,
                    sendToMroScreens,
                    sentToMroScreens,
                    sortedScreens,
                } = sortLabStatusScreens(labStatusScreens);

                setPendingUploadScreens(pendingUploadScreens);
                setPendingReviewScreens(pendingReviewScreens);
                setAttentionRequiredScreens(attentionRequiredScreens);
                setApprovedScreens(approvedScreens);
                setCompleteScreens(completeScreens);
                setLabStatusScreens(sortedScreens);
                setSentToLabScreens(sentToLabScreens);
                setSentToMroScreens(sentToMroScreens);
                setSendToLabScreens(sendToLabScreens);
                setSendToMroScreens(sendToMroScreens);
            }

            const message = response.data.message;
            setSuccessMessage(message);

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error:", error.response ? error.response.data.message : error.message);
        }
    }

    function handleShowNotesModal(rowData) {
        setShowNoteModal(true);
        setSelectedRow(rowData);
    }

    function CopyToClipboard({text, rowData}) {
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (event, rowData) => {
            event.stopPropagation();
            setSelectedRow(rowData)
            setSelectedCheckbox(rowData.device_id);
            navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 5000); // Revert back after 5 seconds
        };


        return (
            <div>
                {copied ?
                    <div onClick={(event) => copyToClipboard(event, rowData)}>
                        <img src={SavedIcon} alt="Copy"/>
                    </div>
                    :
                    <div onClick={(event) => copyToClipboard(event, rowData)}>
                        <img src={SaveIcon} alt="Copy"/>
                    </div>

                }
            </div>
        );
    }

    const handleCheckboxChange = (rowData) => {
        if (selectedCheckbox === rowData.device_id) {
            setSelectedCheckbox(null);
        } else {
            setSelectedCheckbox(rowData.device_id);
        }
    };

    const handleEmailModalClose = () => {
        setShowEmailModal(false);
    };

    if (labStatusScreens.length === 0) {
        return <LoadingTable/>; // or render a loading indicator
    }


    return (
        <div>
            <div className="live-screens-table-frame">
                <Table virtualized
                       height={800}
                       data={sortedScreens}
                       fixedHeader
                       wordWrap="break-word"
                       onRowClick={handleRowClick}
                >
                    <Column width={100} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {(rowData) => {
                                const checked = selectedCheckbox === rowData.device_id;
                                return (
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            onChange={() => handleCheckboxChange(rowData)}
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        </Cell>
                    </Column>
                    <Column width={100} align="center" fixed>
                        <HeaderCell>Date Collected</HeaderCell>
                        <Cell dataKey="date_collected"/>
                    </Column>
                    <Column width={150} align="center" fixed>
                        <HeaderCell>Specimen ID</HeaderCell>
                        <Cell dataKey="device_id">
                            {rowData => (
                                <div
                                    onClick={() => {
                                        setSelectedCheckbox(rowData.device_id);
                                        window.open(rowData.screen_system_link);
                                    }}
                                    className="screen-system-link-icon">
                                    {rowData.device_id}
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <CopyToClipboard text={rowData.device_id}
                                                 rowData={rowData}/>
                            )}
                        </Cell>
                    </Column>
                    <Column width={225} align="center" fixed>
                        <HeaderCell>Status</HeaderCell>
                        <Cell dataKey="system_status"/>
                    </Column>
                    <Column width={175} align="center" fixed>
                        <HeaderCell>Internal Status</HeaderCell>
                        <Cell dataKey="specimen_internal_status"/>
                    </Column>
                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        onClick={event => {
                                            event.preventDefault(); // Prevent the default behavior
                                            handleGenerateCombineReport(rowData);
                                            setSelectedCheckbox(rowData.device_id);
                                        }}
                                    >
                                        <img src={DownloadIcon} alt="Download"/>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        className="reject-review-button"
                                        onClick={event => {
                                            event.preventDefault(); // Prevent the default behavior
                                            handleRejectReview(rowData);
                                        }}
                                    >
                                        <img src={RejectIcon} alt="Reject"/>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>


                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        className="handle-mro-button"
                                        onClick={event => {
                                            event.preventDefault(); // Prevent the default behavior
                                            const status = rowData.specimen_internal_status[0];

                                            if (status === "SEND TO LAB") {
                                                handleSentToLab(rowData);
                                            } else {
                                                handleSendToLab(rowData);
                                            }
                                        }}
                                    >
                                        <img src={LaboratoryIcon}
                                             alt="Laboratory"/>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        className="handle-mro-button"
                                        onClick={event => {
                                            event.preventDefault(); // Prevent the default behavior
                                            const status = rowData.specimen_internal_status[0];

                                            if (status === "SEND TO MRO") {
                                                handleSentToMro(rowData);
                                            } else {
                                                handleSendToMro(rowData);
                                            }
                                        }}
                                    >
                                        <img src={PersonIcon} alt="Person"/>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column width={50} fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        className="advance-specimen-button"
                                        onClick={event => {
                                            console.log(rowData.specimen_internal_status);
                                            event.preventDefault(); // Prevent the default behavior
                                            if (rowData.specimen_internal_status[0] === "APPROVED") {
                                                handleAwaitingUploadReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "PENDING REVIEW") {
                                                handleApproveReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "SEND TO MRO") {
                                                handlePendingReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "SENT TO MRO") {
                                                handlePendingReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "SENT TO LAB") {
                                                handlePendingReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "SEND TO LAB") {
                                                handlePendingReview(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "PENDING UPLOAD") {
                                                handleScreenComplete(rowData);
                                            } else if (rowData.specimen_internal_status[0] === "ATTENTION REQUIRED") {
                                                handlePendingReview(rowData);
                                            } else {
                                                console.log("no actions available");
                                            }
                                        }}
                                    >
                                        <img src={CheckIcon} alt="Approve"/>
                                    </div>
                                </div>
                            )}

                        </Cell>
                    </Column>


                    {
                        userRoles.includes('Labb.Email.Send') ?
                            <Column width={50} fixed>
                                <HeaderCell></HeaderCell>
                                <Cell>
                                    <div>
                                        <div className="email-button"
                                             onClick={handleEmailModalOpen}>
                                            <img src={SendIcon}
                                                 alt="Send Email"/>
                                        </div>
                                    </div>
                                </Cell>
                            </Column>
                            : null
                    }
                    <Column width={50} align="center" fixed>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <div>
                                    <div
                                        onClick={event => {
                                            event.preventDefault(); // Prevent the default behavior
                                            handleScreenComplete(rowData);
                                        }}
                                    >
                                        <img src={LockIcon}
                                             alt="Finilize Specimen"/>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column width={300} align="center">
                        <HeaderCell>
                            <img src={BoxIcon} alt="Tracking Link"/>
                        </HeaderCell>
                        <Cell dataKey="tracking_status" style={{textTransform: 'uppercase'}}>
                            {rowData => (
                                <div onClick={(event) => {
                                    setSelectedCheckbox(rowData.device_id);
                                    event.preventDefault(); // Prevent the default behavior
                                    // Redirect to the FedEx tracking page
                                    window.open(`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${rowData.tracking_number}`);
                                }}>
                                    {rowData.tracking_status}
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={300}>
                        <HeaderCell>Latest Note</HeaderCell>
                        <Cell dataKey="latest_specimen_note" align='center'>
                            {rowData => (
                                <div style={{ cursor: "pointer" }} onClick={() => handleShowNotesModal(rowData)}>
                                    {rowData.latest_specimen_note ? (
                                        rowData.latest_specimen_note
                                    ) : (
                                        <img src={NoteIcon} alt="Note" />
                                    )}
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column width={215}>
                        <HeaderCell>Client Name</HeaderCell>
                        <Cell dataKey="client_id"/>
                    </Column>
                    <Column width={225}>
                        <HeaderCell>Organization Name</HeaderCell>
                        <Cell dataKey="org_id"/>
                    </Column>
                    <Column width={275}>
                        <HeaderCell>Service Level</HeaderCell>
                        <Cell dataKey="service_level"/>
                    </Column>
                    <Column width={100} align="center">
                        <HeaderCell>Product Name</HeaderCell>
                        <Cell dataKey="product_name"/>
                    </Column>
                    <Column width={200} align="center">
                        <HeaderCell>Drugs Confirmed</HeaderCell>
                        <Cell dataKey="approved_drugs_confirmed"/>
                    </Column>
                    <Column width={200} align="center">
                        <HeaderCell>Unapproved Drugs Confirmed</HeaderCell>
                        <Cell dataKey="qty_unapproved_drugs_confirmed"/>
                    </Column>

                </Table>
            </div>
        </div>
    )
}

export default RecentLabScreens;