import './UsageLineChart.css';
import "rsuite/dist/rsuite.min.css";
import {
    Line,
    LinearXAxis,
    LinearYAxis,
    StackedAreaChart,
    StackedAreaSeries,
} from 'reaviz';
import React from "react";
import LoadingTable from "../../Loading/LoadingTable";

function UsageLineChart({usageData}) {


    const color = ["#1E5DC8", "#30AD7B", "#E63E57", "#E2B80A", "#C36FC5"]

    if (!usageData || usageData.length === 0) {
        return <LoadingTable/>; // or any other placeholder component
    }

    return (
        <div className="usage-chart-frames">
            <center>
                <div className="usage-line-chart-frame">
                    <StackedAreaChart
                        series={
                            <StackedAreaSeries
                                zoomPan={true}
                                type='grouped'
                                area={null}
                                line={<Line strokeWidth={2}/>}
                                xScale={<LinearXAxis type="time"/>}
                                yScale={<LinearYAxis type="value"/>}
                                colorScheme={color}
                            />
                        }
                        xAxis={<LinearXAxis type="time"/>}
                        yAxis={<LinearYAxis type="value"/>}
                        data={usageData}
                    />
                </div>
            </center>
        </div>

    )

}

export default UsageLineChart;




