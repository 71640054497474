import React from 'react';

const EmailNotification = ({ note, created_at, select_screen_id, subject, onClick }) => {
    return (
        <div className="note-box" onClick={onClick}>
            <div className="note-full-container">
                <div className="note-header">
                    <div className="date-container">
                        <h3>
                            {new Date(created_at).toLocaleDateString(undefined, {
                                month: "2-digit",
                                day: "2-digit",
                            })}{" "}
                            {new Date(created_at).toLocaleTimeString(undefined, {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                            })}
                        </h3>
                    </div>
                    {subject && (
                        <div className="note-subject">
                            <h3>{subject}</h3>
                        </div>
                    )}
                    <div className="note-specimen-id">
                        <h3>SD0{select_screen_id}</h3>
                    </div>
                </div>
                <div className="note-container">
                    <p>{note}</p>
                </div>
            </div>
        </div>
    );
};

export default EmailNotification;
