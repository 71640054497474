import React from 'react';
import './ResultPanel.css';
import ReadScan from "./ReadScan";

export default function ResultPanel({readInfo, checkedItems, updateCheckedItems}) {
    // console.log("readInfo.sides", readInfo.sides)

    return (
        <div className="results-panel-main-body">
            {readInfo.sides[0].map((side, index) => {
                return (
                    <ReadScan key={side} imgUrl={readInfo.img_urls} readInfo={readInfo.drugs_info[index]} side={side}
                              index={index} checkedItems={checkedItems} updateCheckedItems={updateCheckedItems}/>
                )
            })}
        </div>
    )
}
