import './PositiveRateBarChart.css';
import "rsuite/dist/rsuite.min.css";
import {
    Bar,
    BarChart,
    BarSeries
} from 'reaviz';
import React from "react";
import LoadingTable from "../../Loading/LoadingTable";

function PositiveRateBarChart({positiveRate}) {

    return (
        <div className="usage-chart-frames">
            <center>
                <div className="pos-rate-chart-frame">
                    <BarChart
                        data={positiveRate}
                    />
                </div>
            </center>
        </div>

    )

}

export default PositiveRateBarChart;




