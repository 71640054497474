import React, {useEffect, useState} from 'react';
import './StripsImage.css';
import {default as axios} from "axios";
import {config} from "../../Constants";

export default function StripImages({updateStripImages, nextTestInfo, token}) {

    useEffect(() => getStripInfo(), [""]);
    const [selectStripImages, setSelectStripImages] = useState({});
    const url = config.url.FREDDY_API_URL;
    const formData = new FormData();
    formData.set('select_test_id', nextTestInfo.select_test_id);

    const toggleReviewImage = () => {
        updateStripImages(value => !value)
    };


    function getStripInfo() {
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        axios({
            method: "POST",
            baseURL: url,
            url: "/get-strip-info",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            const res = response.data
            setSelectStripImages(res)
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    if (selectStripImages) {
        return (
            <h3>loading</h3>
        )
    } else {
        return (
            <div className="strips-image-main-body">
                <button onClick={toggleReviewImage} className="switch-button">
                    <svg className="switch-icon" xmlns="http://www.w3.org/2000/svg" width="21.381" height="18"
                         viewBox="0 0 21.381 18">
                        <g id="Group_92" data-name="Group 92" transform="translate(-1.31 -3)">
                            <path id="Path_2" data-name="Path 2" d="M4,12a8,8,0,0,0,12.5,6.615" fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_3" data-name="Path 3" d="M20,12A8,8,0,0,0,7.5,5.385" fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_4" data-name="Path 4"
                                  d="M3.685,7.57a.4.4,0,0,1,.686,0L6.641,11.4A.4.4,0,0,1,6.3,12H1.71a.4.4,0,0,1-.342-.607Z"
                                  fill="#fff"/>
                            <path id="Path_5" data-name="Path 5"
                                  d="M20.315,16.43a.4.4,0,0,1-.686,0L17.359,12.6A.4.4,0,0,1,17.7,12H22.29a.4.4,0,0,1,.342.607Z"
                                  fill="#fff"/>
                        </g>
                    </svg>
                </button>
                <div className="strips-image-data-frame">
                    <img className="select-strip-image" src={selectStripImages[0].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[0].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[0].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[1].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[1].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[1].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[2].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[2].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[2].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[3].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[3].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[3].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[4].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[4].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[4].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[5].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[5].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[5].strip_class}</h4>
                    <img className="select-strip-image" src={selectStripImages[6].strip_url}/>
                    <h4 className="select-strip-confidence">{selectStripImages[6].strip_confidence}%</h4>
                    <h4 className="select-strip-class">{selectStripImages[6].strip_class}</h4>
                </div>
            </div>
        )

    }
}
