import React, { useEffect, useState } from 'react';
import './LinkedEmailsList.css';
import axios from 'axios';
import { config } from '../../../../Constants';
import EmailBody from '../EmailBody';

const LinkedEmailsList = ({
                              specimenLinkedEmails,
                              handleItemClick,
                              newLinkedEmail,
                              setNewLinkedEmail,
                              newUnlinkedEmail,
                              setNewUnlinkedEmail,
                              newSpecimenNotes,
                              setNewSpecimenNotes,
                              errorMessage,
                              setErrorMessage,
                              successMessage,
                              setSuccessMessage,
                          }) => {
    const getEmailStatusColor = (status) => {
        switch (status) {
            case 'COMPLETED':
                return 'light-green';
            case 'FLAGGED':
                return 'orange';
            case 'READ':
                return 'white';
            case 'UNREAD':
                return 'blue';
            default:
                return '';
        }
    };

    return (
        <div>
            {specimenLinkedEmails ? (
                <div>
                    <ul className="linked-emails-list">
                        {specimenLinkedEmails.map((message) => (
                            <li
                                key={message.id}
                                className={`linked-email-item ${getEmailStatusColor(message.email_message_status)}`}
                                onClick={() => handleItemClick(message)}
                            >
                                <div className="linked-email-subject">{message.subject}</div>
                                <div className="linked-email-details">
                                    <div className="linked-email-sender">
                                        <span className="label">From:</span> {message.sender?.emailAddress?.address}
                                    </div>
                                    <div className="linked-email-received">
                                        <span className="label">Received:</span>{' '}
                                        {new Date(message.receivedDateTime).toLocaleString()}
                                    </div>
                                </div>
                                <div className="linked-email-body">
                                    {message.body?.content.filtered_content ? (
                                        <EmailBody body={message.body?.filtered_content} />
                                    ) : (
                                        <EmailBody body={message.note} />
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default LinkedEmailsList;
