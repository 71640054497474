import React from 'react';
import './ReaderInput.css';

export default function ReaderInput({checkedItems}) {

    return (
        <div>
            <div className="reader-input-main-body">
                <div className="reader-input-button-frame">
                    <button type="submit" className="reader-input-read-button">READ</button>
                    <button type="submit" className="reader-input-read-next-button">READ &
                        NEXT
                    </button>
                    <button type="submit"
                            className="reader-input-light-next-button">LIGHT & NEXT
                    </button>
                </div>
            </div>
        </div>
    )
}
