import './Notifications.css';
import {default as axios} from "axios";
import {config} from "../../Constants";
import React, {useEffect, useState} from "react";
import AddSpecimenNote from "../../notes/add_specimen_note/AddSpecimenNote";
import SendSpecimenEmail from "../../email/send_specimen_email/SendSpecimenEmail";
import NotificationList from "./notification_list/NotificationList";

function Notifications({
                           userRoles,
                           setErrorMessage,
                           errorMessage,
                           successMessage,
                           setSuccessMessage,
                           setUserNotifications,
                           userNotifications,
                           newSpecimenNotes,
                           setNewSpecimenNotes,
                           setIsSendEmailTemplateActive,
                           selectedNotificationDetails,
                           setSelectedNotificationDetails,
                           specimenNotificationNotes,
                           specimenUserNotifications,
                           setSelectedNotification,
                           selectedNotification,
                           handleGetUserNotifications,
                           labStatusScreens,
                           setSortedScreens,
                           setShowEmailModal,
                           showEmailModal,
                           setSpecimenShowEmailModal,
                       }) {
    const accessToken = localStorage.getItem('accessToken');
    const url = config.url.FREDDY_API_URL;
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(false);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedSpecimenId, setSelectedSpecimenId] = useState(null);

    const handleAddNote = (specimenId) => {
        console.log(specimenId)
        setSelectedSpecimenId(specimenId);
        setShowNoteModal(true);
    };

    const handleCloseNoteModal = () => {
        setSelectedSpecimenId(null);
        setShowNoteModal(false);
    };

    const handleAcknowledgeNotification = async (notificationId) => {
        const formData = new FormData();
        formData.append("notification_id", notificationId);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                url: "/acknowledge-notification",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                data: formData
            });
            if (response.status === 200) {
                setSuccessMessage(response.data.message);
                // Refresh the notifications
                await handleGetUserNotifications();
            } else {
                setErrorMessage(response.data.message);
                window.alert("An error occurred: " + response.data.message);
            }
        } catch (error) {
            console.log(error);
            window.alert("An error occurred: " + error.message);
        }
    };

    return (
        <div>
            <div>
                {showNoteModal && selectedSpecimenId && (
                    <div className="note-modal-container">
                        <div className="note-modal-row">
                            <div className="note-modal-column">
                                <AddSpecimenNote
                                    userRoles={userRoles}
                                    select_screen_id={selectedSpecimenId}
                                    setShowNoteModal={setShowNoteModal}
                                    specimenId={selectedSpecimenId}
                                    onClose={handleCloseNoteModal}
                                    setSelectedOption={setSelectedOption}
                                    selectedOption={selectedOption}
                                    setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                                    emailTemplateOptions={emailTemplateOptions}
                                    setEmailTemplateOptions={setEmailTemplateOptions}
                                    selectedTemplate={selectedTemplate}
                                    filteredTemplates={filteredTemplates}
                                    setSelectedTemplate={setSelectedTemplate}
                                    setFilteredTemplates={setFilteredTemplates}
                                    getLabStatusScreens={null}
                                    setNewSpecimenNotes={setNewSpecimenNotes}
                                    newSpecimenNotes={newSpecimenNotes}
                                    labStatusScreens={labStatusScreens}
                                    setSortedScreens={setSortedScreens}
                                    setShowEmailModal={setShowEmailModal}
                                    setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {showEmailModal && (
                    <div className="recent-lab-screens-specimen-email">
                        <SendSpecimenEmail
                            setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            emailTemplateOptions={emailTemplateOptions}
                            setEmailTemplateOptions={setEmailTemplateOptions}
                            filteredTemplates={filteredTemplates}
                            setFilteredTemplates={setFilteredTemplates}
                            select_screen_id={selectedRow}
                            setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                        />
                    </div>
                )}
            </div>
            <div>
                <div className="notifications-container">
                    <div className="notification-list-container">
                        <NotificationList
                            userNotifications={specimenNotificationNotes}
                            setUserNotifications={setUserNotifications}
                            selectedNotification={selectedNotification}
                            setSelectedNotification={setSelectedNotification}
                            selectedNotificationDetails={selectedNotificationDetails}
                            setSelectedNotificationDetails={setSelectedNotificationDetails}
                            onAddNote={handleAddNote}
                            onAcknowledgeNotification={handleAcknowledgeNotification}
                        />
                    </div>
                    <div className="notification-details-container">
                        <NotificationList
                            newSpecimenNotes={newSpecimenNotes}
                            setNewSpecimenNotes={setNewSpecimenNotes}
                            userNotifications={specimenUserNotifications}
                            setUserNotifications={setUserNotifications}
                            selectedNotification={selectedNotification}
                            setSelectedNotification={setSelectedNotification}
                            selectedNotificationDetails={selectedNotificationDetails}
                            setSelectedNotificationDetails={setSelectedNotificationDetails}
                            onAddNote={handleAddNote}
                            onAcknowledgeNotification={handleAcknowledgeNotification}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notifications;
