import "./LabbClients.css"
import React, {useEffect, useState} from 'react';
import {config} from "../Constants";
import axios from "axios";
import LabbClientTables from "./labb_client_tables/LabbClientTables";
import CreateHubspotTicket from "../hubspot_tickets/CreateHubspotTicket";
import CreatableSelect from "react-select/creatable";
import {FixedSizeList} from "react-window";
import ClientsSearchHeader from "./clients_search_header/ClientsSearchHeader";
import LoadingTable from "../Loading/LoadingTable";


const LabbClients = ({
                         userRoles,
                         setErrorMessage,
                         setSuccessMessage,
                         showClientNoteModal,
                         setShowClientNoteModal,
                     }) => {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem("accessToken");

    const [allClientData, setAllClientData] = useState([]);
    const [showTicketModal, setShowTicketModal] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [selectedClientName, setSelectedClientName] = useState(null);
    const [allClients, setAllClients] = useState(null);
    const [selectedClientIds, setSelectedClientIds] = useState([]);


    const [showClientEmailModal, setShowClientEmailModal] = useState(false);
    const initialState = {
        isActiveClientsTableActive: true,
        isActiveClientsTableAlert: false,
    };

    const handleClientClick = (clientId) => {
        setSelectedAccountId(clientId);

    };

    const handleClientName = (clientName) => {
        setSelectedClientName(clientName);
    };

    // Initialize state using the reducer
    const [state, dispatch] = React.useReducer(tabReducer, initialState);


    // Define reducer function to update the state
    function tabReducer(state, action) {
        switch (action.type) {
            case 'ACTIVE_CLIENTS':
                return {
                    ...initialState,
                    isActiveClientsTableActive: true
                };
            default:
                return state;
        }
    }

    // Functions to handle tab clicks
    function goToActiveClientsTable() {
        dispatch({type: 'ACTIVE_CLIENTS'});
    }


    const handleClientSearchSubmit = () => {
        const formData = new FormData();
        formData.set('client_ids', selectedClientIds);
        axios({
            method: "POST",
            baseURL: url,
            data: formData,
            url: "/get-client-details-search",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setAllClientData(response.data);
            })
            .catch(error => {
                setErrorMessage("An error occurred: " + error.message);
                console.error('There was an error!', error);
            });
    };

    const handleGetClients = (e) => {
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-all-clients",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setAllClients(response.data);
            })
            .catch(error => {
                setErrorMessage("An error occurred: " + error.message);
                console.error('There was an error!', error);
            });
    };

    useEffect(() => handleGetClients(), []);


    const handleClientIdSearch = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedClientIds(selectedIds);
    };

    function getAllClientData() {
        axios({
            method: 'GET',
            baseURL: url,
            url: '/start-get-all-client-data',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const jobId = response.data['job_id'];
            const pollUrl = `${url}/get-client-data-result?job_id=${jobId}`;
            const pollInterval = setInterval(() => {
                axios({
                    method: "GET",
                    baseURL: pollUrl,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                }).then((response) => {
                    const result = response.data;
                    if (result.status === 'completed') {
                        const res = response.data.data;
                        const currentTime = Date.now();
                        const allClientData = res.filter((client) => client.deleted_at < currentTime);
                        setAllClientData(allClientData)
                        clearInterval(pollInterval);
                    } else if (result.status === 'failed') {
                        clearInterval(pollInterval);
                        setErrorMessage("Error Failed to get specimens");
                    }

                }).catch(error => {
                    console.log(error);
                    clearInterval(pollInterval);
                    setErrorMessage("An error occurred: " + error.message);
                });
            }, 10000); // poll every 10 seconds

            return response; // Return the initial response
        }).catch(error => {
            console.log(error);
            setErrorMessage("An error occurred: " + error.message);
        });
    }

    useEffect(() => getAllClientData(), []);


    return (
        <div>

            {/*{showEmailModal && (*/}
            {/*    <div className="recent-lab-screens-specimen-email">*/}
            {/*        <SendClientEmail*/}
            {/*            setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}*/}
            {/*            selectedTemplate={selectedTemplate}*/}
            {/*            setSelectedTemplate={setSelectedTemplate}*/}
            {/*            emailTemplateOptions={emailTemplateOptions}*/}
            {/*            setEmailTemplateOptions={setEmailTemplateOptions}*/}
            {/*            filteredTemplates={filteredTemplates}*/}
            {/*            setFilteredTemplates={setFilteredTemplates}*/}
            {/*            select_screen_id={selectedRow}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}
            <div>
                <div>
                    <div>
                        <div>
                            <div className="labb-clients-search-header">
                                <ClientsSearchHeader
                                    allClients={allClients}
                                    handleClientIdSearch={handleClientIdSearch}
                                    handleClientSearchSubmit={handleClientSearchSubmit}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="lab-reports-tabs">

                        <div className="labb-clients-tab" onClick={goToActiveClientsTable}>
                            <div className="labb-clients-tab-label">
                                <div className="labb-clients-tab-button">
                                    <h3>ACTIVE CLIENTS</h3>
                                </div>
                            </div>
                            {
                                state.isActiveClientsTableActive ?
                                    <div className="labb-clients-tab-underline"></div> : null
                            }
                            {
                                state.isActiveClientsTableAlert ?
                                    <div className="labb-clients-tab-underline-red"></div> : null
                            }
                        </div>
                    </div>

                    {state.isActiveClientsTableActive && (
                        <div className="labb-clients-container">
                            {selectedAccountId && showTicketModal && (
                                <div className="labb-client-create-hubspot-ticket-modal-container">
                                    <CreateHubspotTicket
                                        selectedAccountId={selectedAccountId}
                                        selectedClientName={selectedClientName}
                                        userRoles={userRoles}
                                    />
                                </div>
                            )}
                            {selectedAccountId && showClientNoteModal && (
                                <div className="labb-client-create-hubspot-ticket-modal-container">
                                    <AddClientNote
                                        selectedAccountId={selectedAccountId}
                                        selectedClientName={selectedClientName}
                                        showClientNoteModal={showClientNoteModal}
                                        setShowClientNoteModal={setShowClientNoteModal}
                                    />
                                </div>
                            )}
                            <LabbClientTables
                                handleClientClick={handleClientClick}
                                allClientData={allClientData}
                                setShowTicketModal={setShowTicketModal}
                                handleClientName={handleClientName}
                                showClientNoteModal={showClientNoteModal}
                                setShowClientNoteModal={setShowClientNoteModal}
                            />
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
        ;
};

export default LabbClients;


