import "./MroSummaryTreeTable.css"
import React, {useState} from 'react';
import {IconButton, Table} from 'rsuite';
import PersonIcon from "../../../../assets/icons/Person.svg";
import ReportCheckIcon from "../../../../assets/icons/ReportCheck.svg";
import ContractIcon from "../../../../assets/icons/Contract.svg";
import ExpandIcon from "../../../../assets/icons/Expand.svg";
import {default as axios} from "axios";
import {config} from "../../../Constants";

const {Column, HeaderCell, Cell,} = Table;

const MroSummaryTreeTable = ({ mroSummaryDetails }) => {
    const accessToken = localStorage.getItem('accessToken')
    const url = config.url.FREDDY_API_URL;
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [totalHeight, setTotalHeight] = useState();
    const updateTotalHeight = totalHeight => {
        setTotalHeight(totalHeight)
    }
    const rowKey = "id";

    const getCellPosRateTextColor = (value) => {
        return value > 10.00 ? 'red' : 'black';
    };
    const getCellDifferenceTextColor = (value) => {
        return value < 0.00 ? 'red' : 'green';
    };
    const handleRowExpanded = (rowData, dataKey) => {
        const rowKeyValue = rowData[rowKey];
        const isExpanded = expandedRowKeys.includes(rowKeyValue);

        let nextExpandedRowKeys = [];
        if (isExpanded) {
            nextExpandedRowKeys = expandedRowKeys.filter(key => key !== rowKeyValue);
        } else {
            nextExpandedRowKeys = [...expandedRowKeys, rowKeyValue];
        }

        updateTotalHeight(rowData.mro_all_screens_by_client.length * 51 + 50);
        setExpandedRowKeys(nextExpandedRowKeys);
    };




    async function handleSendToMro(rowData) {
        const device_id = rowData.device_id;
        const formData = new FormData();
        formData.append('select_screen_id', device_id);
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                data: formData,
                url: "/set-specimen-mro-status",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            console.log(response)
        } catch (error) {
            console.log(error); // log the upload error
            // show an error pop up with the error message
            window.alert("An error occurred: " + error.message);
        }
    }

    const ExpandCell = ({rowData, dataKey, expandedRowKeys, onChange, ...props}) => (
        <Cell {...props} style={{padding: 5}}>
            <IconButton
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                        <img src={ContractIcon} alt="Contract" />
                    ) : (
                        <img src={ExpandIcon} alt="Expand" />
                    )
                }
            />
        </Cell>
    );


    // calcualte length of mro_all_screens_by_client then multiply by 75 to find
    // height of the table

    const renderScreensCell = rowData => {
        if (rowData.mro_all_screens_by_client.length > 0) {
            return (
                <div className="live-screen-info">
                    <Table virtualized
                           height={expandedRowKeys.includes(rowData[rowKey]) ? rowData.mro_all_screens_by_client.length * 50 + 50 : 0}
                           data={rowData.mro_all_screens_by_client}>
                        <Column width={200} align="center" fixed>
                            <HeaderCell>Specimen ID</HeaderCell>
                            <Cell dataKey="device_id"/>
                        </Column>
                        <Column width={200} align="center" fixed>
                            <HeaderCell>Status</HeaderCell>
                            <Cell dataKey="internal_status"/>
                        </Column>
                        <Column width={50} align="center" fixed>
                            <HeaderCell></HeaderCell>
                            <Cell>
                                {rowData => (
                                    <div>
                                        <button
                                            className="reject-review-button"
                                            type="button"
                                            onClick={event => {
                                                event.preventDefault(); // Prevent the default behavior
                                                handleSendToMro(rowData);
                                            }}
                                        >
                                            <img src={PersonIcon} alt="Person" />
                                        </button>
                                    </div>
                                )}
                            </Cell>
                        </Column>
                        <Column width={250} align="center">
                            <HeaderCell>Process Type</HeaderCell>
                            <Cell dataKey="process_type"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Collection Date</HeaderCell>
                            <Cell dataKey="collection_date"/>
                        </Column>
                        <Column width={100} align="center">
                            <HeaderCell>Product Name</HeaderCell>
                            <Cell dataKey="product_name"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Drugs Confirmed</HeaderCell>
                            <Cell dataKey="approved_drugs_confirmed"/>
                        </Column>
                        <Column width={200} align="center">
                            <HeaderCell>Unapproved Drugs Confirmed</HeaderCell>
                            <Cell dataKey="qty_unapproved_drugs_confirmed"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Lab Result</HeaderCell>
                            <Cell dataKey="lab_result"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Lab Status Override</HeaderCell>
                            <Cell dataKey="lab_status_override"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>MRO Result</HeaderCell>
                            <Cell dataKey="mro_result"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>First Name</HeaderCell>
                            <Cell dataKey="donor_first_name"/>
                        </Column>
                        <Column width={150} align="center">
                            <HeaderCell>Last Name</HeaderCell>
                            <Cell dataKey="donor_last_name"/>
                        </Column>
                        <Column width={100} align="center">
                            <HeaderCell></HeaderCell>
                            <Cell dataKey="screen_system_link">
                                {rowData => (
                                    <div onClick={() => window.open(rowData.screen_system_link)}
                                         className="screen-system-link-icon">
                                        <img src={ReportCheckIcon} alt="System Link" />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                </div>
            );
        }
        return null;
    };


    return (
        <div className="live-screens-table-frame">
            <div style={{width: "100%", overflowX: "auto"}}>

                <Table
                    rowKey="id"
                    data={mroSummaryDetails}
                    expandedRowKeys={expandedRowKeys}
                    shouldUpdateScroll={false}
                    rowExpandedHeight={totalHeight}
                    autoHeight
                    renderRowExpanded={renderScreensCell}
                >
                    <Column width={300} align="left" fixed>
                        <HeaderCell>Client</HeaderCell>
                        <Cell dataKey="client_name"/>
                    </Column>
                    <Column width={150} align="center" fixed>
                        <HeaderCell>Screens</HeaderCell>
                        <ExpandCell dataKey="device_id" expandedRowKeys={expandedRowKeys}
                                    onChange={handleRowExpanded}>{renderScreensCell}</ExpandCell>
                    </Column>
                </Table>
            </div>
        </div>
    );
};

export default MroSummaryTreeTable;
