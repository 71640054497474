import './Donor.css';
import { useState } from "react";

const Donor = ({ donorDetails }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [twoFA, setTwoFA] = useState("");


    return (
        <div>
            <section>
                <div className="donor">
                    <div className="donor_header">
                        <p className="donor_header_text">Donor Details</p>
                    </div>
                    <div className="dark_grey_strip" />
                    {showDetails && (
                        <>
                            <div className="donor_info_section">
                                <p className="donor_info_text">Info:</p>
                                <p>{donorDetails?.donor_first_name} {donorDetails?.donor_last_name}</p>
                                <p>{donorDetails?.donor_phone}</p>
                                <p>{donorDetails?.donor_email}</p>
                            </div>
                            <div className="donor_info_section">
                                <p className="donor_info_text">Address:</p>
                                <p>{donorDetails?.donor_address_line_one}</p>
                                <p>{donorDetails?.donor_address_line_two}</p>
                                <p>{donorDetails?.donor_city}</p>
                                <p>{donorDetails?.donor_state}</p>
                                <p>{donorDetails?.donor_zip_code}</p>
                            </div>
                            <div className="donor_info_section">
                                <p className="donor_info_text">Identification:</p>
                                <p>{donorDetails?.donor_id_state}</p>
                                <p>{donorDetails?.donor_id_number}</p>
                                <p>{donorDetails?.donor_id_expiry}</p>
                            </div>
                        </>
                    )}
                    <div className="donor_toggle_button">
                        <button>
                            {authenticated ? (showDetails ? "Hide Details" : "Show Details") : "Authenticate"}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Donor;
