import React, {useEffect, useState} from "react";
import './Inspectors.css';
import {config} from "../../Constants";
import UserInitial from "../../user/initials/UserInitial";

export default function Inspectors({batchInfo}) {
    const axios = require('axios').default;
    const url = config.url.FREDDY_API_URL
    const formData = new FormData();
    const [inspectorsInitials, setInspectorsInitials] = useState([]);
    formData.set('batch_report_id', batchInfo.batch_report_id);

    function getInspectorInitials() {

        const accessToken = localStorage.getItem("accessToken");
        axios({
            method: "POST",
            baseURL: url,
            url: "/inspector-initials",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setInspectorsInitials(response.data)
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }


    // useEffect(() => getInspectorInitials(), []);



    return (
        <div className="batch-card-inspector-frame">
            <div className="batch-card-inspector-header">
                <h3 style={{color: "#58585A", fontWeight: "bold", fontSize: "19px", display: "inline"}}>{batchInfo.inspectors_reviewed}</h3>
                <h3 className="batch-card-inspector-header-text"> of 6 inspectors complete</h3>
            </div>
            <div className="batch-card-vertical-line-break"/>
                <UserInitial inspectorsInitials={inspectorsInitials} batchInfo={batchInfo}/>
        </div>
    )
}
