import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingTable from '../Loading/LoadingTable';
import './JiraNotifications.css';
import { config } from '../Constants';

const JiraNotifications = () => {
    const url = config.url.FREDDY_API_URL;
    const accessToken = localStorage.getItem('accessToken');
    const [jiraNotifications, setJiraNotifications] = useState([]);
    const [loadingJiraNotifications, setLoadingJiraNotifications] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchNotifications = async () => {
        axios({
            method: 'GET',
            baseURL: url,
            url: '/get-jira-notifications',
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        })
            .then((response) => {
                setJiraNotifications(response.data.reverse());
                setLoadingJiraNotifications(false);
            })
            .catch((error) => {
                setErrorMessage(`Error: ${error.message}`);
                console.error('There was an error!', error);
            });
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    if (loadingJiraNotifications) {
        return <LoadingTable />;
    }

    return (
        <div className="jira-notifications-container">
            <h1 className="notifications-heading">Jira Notifications</h1>
            {jiraNotifications.map((notification) => (
                <div key={notification.id} className="notification-item">
                    <h3>{notification.subject}</h3>
                    <p>Created Date: {notification.create_date}</p>
                    <p>Last Modified Date: {notification.last_modified_date}</p>
                    <p>Author: {notification.author}</p>
                    <p>Content: {notification.content}</p>
                </div>
            ))}
        </div>
    );
};

export default JiraNotifications;