import axios from "axios";

export default async function getUserEmail(emailAccessToken) {
    try {
        const response = await axios.get('https://graph.microsoft.com/v1.0/me?$select=userPrincipalName', {
            headers: {
                Authorization: `Bearer ${emailAccessToken}`,
            },
        });
        return response.data.userPrincipalName;
    } catch (error) {
        console.error('Error retrieving user email:', error);
        return null;
    }
}
