import React, { useEffect, useState } from 'react';
import { htmlToText } from 'html-to-text';
import AssociateSpecimenEmails from '../fetch_mail/associate_specimen_email/AssociateSpecimenEmails';
import { default as axios } from 'axios';
import { config } from '../../Constants';

const UnprocessedEmails = ({
                               unprocessedEmails,
                               setSpecimenLinkedEmails,
                               setSpecimenUnlinkedEmails,
                               setSupportEmailMessages,
                               supportEmailMessages,
                               initialState,
                               newLinkedEmail,
                               setNewLinkedEmail,
                               newUnlinkedEmail,
                               setNewUnlinkedEmail,
                               errorMessage,
                               setErrorMessage,
                               setSuccessMessage,
                               successMessage,
                           }) => {
    const accessToken = localStorage.getItem('accessToken');
    const url = config.url.FREDDY_API_URL;
    const [linkedEmails, setLinkedEmails] = useState([]);
    const [unlinkedEmails, setUnlinkedEmails] = useState([]);
    const updatedNewLinkedEmails = [...newLinkedEmail];
    const updatedNewUnlinkedEmails = [...newUnlinkedEmail];

    useEffect(() => {
        const parser = new DOMParser();
        const updatedLinkedEmails = [];
        const updatedUnlinkedEmails = [];

        function setNewLinkedEmailStatus(message) {
            const formData = new FormData();
            formData.append('email_message_id', message.id);
            formData.append('email_message_status_id', 1);
            axios({
                method: 'POST',
                baseURL: url,
                data: formData,
                url: '/set-new-email-status',
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('success');
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }

        unprocessedEmails.forEach((message) => {
            let subject = '';

            if (message.subject) {
                subject = message.subject.toLowerCase();
            }

            let body = '';
            if (message.body && message.body.content) {
                body = message.body.content.toLowerCase();
            }

            const doc = parser.parseFromString(body, 'text/html');
            const emailContent = doc.documentElement.textContent;

            const filteredContent = emailContent.replace(/<[^>]+>/g, '');
            const cleanContent = filteredContent.replace(/[\r\n]+/g, ' ');
            const matchPattern = /<!-- p {margin-top:0; margin-bottom:0} -->/g;

            const plainTextContent = htmlToText(cleanContent, {
                ignoreHref: true,
                ignoreImage: true,
            });

            const firstIndex = plainTextContent.indexOf(matchPattern);
            const secondIndex = plainTextContent.indexOf(matchPattern, firstIndex + 1);

            let extractedContent;
            if (firstIndex !== -1 && secondIndex !== -1) {
                extractedContent = plainTextContent.substring(firstIndex + matchPattern.length, secondIndex);
            } else {
                if (firstIndex !== -1) {
                    extractedContent = plainTextContent.substring(firstIndex + matchPattern.length);
                } else {
                    extractedContent = plainTextContent;
                }
            }

            const regex = /sd(\d{8})/gi;
            const subjectMatches = subject.match(regex);
            const bodyMatches = extractedContent.match(regex);
            const specimenId = subjectMatches ? subjectMatches[0].substring(2) : bodyMatches ? bodyMatches[0].substring(2) : null;

            if (specimenId) {
                message.select_screen_id = specimenId;

                const emailRegex = /from: .+?[\r\n]+to: .+?[\r\n]+subject: .+?[\r\n]+date: .+?[\r\n]+[\r\n]+([\s\S]+?)(?=from: .+?[\r\n]+to: .+?[\r\n]+subject: .+?[\r\n]+date: .+?[\r\n]+[\r\n]+|$)/gi;

                const mostRecentContentMatches = extractedContent.match(emailRegex);
                if (mostRecentContentMatches && mostRecentContentMatches.length > 0) {
                    const mostRecentContent = mostRecentContentMatches[mostRecentContentMatches.length - 1];
                    message.body.filtered_content = htmlToText(mostRecentContent, {
                        ignoreHref: true,
                        ignoreImage: true,
                    });
                    message.email_linked = true;
                } else {
                    message.body.filtered_content = extractedContent;
                    message.email_linked = true;
                }
                updatedNewLinkedEmails.push(message);
                updatedLinkedEmails.push(message);
                setNewLinkedEmail((prevLinkedEmails) => [...prevLinkedEmails, ...updatedLinkedEmails]);
                setNewLinkedEmailStatus(message);
            } else {
                message.body.filtered_content = extractedContent;
                message.email_linked = false;
                updatedNewUnlinkedEmails.push(message);
                updatedUnlinkedEmails.push(message);
                setNewUnlinkedEmail((prevUnlinkedEmails) => [...prevUnlinkedEmails, ...updatedUnlinkedEmails]);
            }
        });

        setLinkedEmails(updatedLinkedEmails);
        setUnlinkedEmails(updatedUnlinkedEmails);
        setSupportEmailMessages(supportEmailMessages);
    }, [unprocessedEmails]);

    useEffect(() => {
        setSpecimenLinkedEmails(linkedEmails);
        setSpecimenUnlinkedEmails(unlinkedEmails);
        setSupportEmailMessages(supportEmailMessages);
    }, [
        linkedEmails,
        unlinkedEmails,
        supportEmailMessages,
        setSpecimenLinkedEmails,
        setSpecimenUnlinkedEmails,
        setSupportEmailMessages,
    ]);

    return (
        <div>
            {linkedEmails.map((message) => (
                <div key={message.id}>
                    <AssociateSpecimenEmails
                        emailMessages={[message]}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                </div>
            ))}
            {unlinkedEmails.map((message) => (
                <div key={message.id}>
                    <AssociateSpecimenEmails
                        emailMessages={[message]}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                </div>
            ))}
            {supportEmailMessages.map((message) => (
                <div key={message.id}>
                    <AssociateSpecimenEmails
                        emailMessages={[message]}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                </div>
            ))}
        </div>
    );
};

export default UnprocessedEmails;
