import React, {useState} from "react";
import "./Email.css";
import ManageInbox from "./manage_templates/manage_inbox/ManageInbox";
import ManageTemplates from "./manage_templates/ManageTemplates";
import HubspotTickets from "../hubspot_tickets/HubspotTickets";
import JiraNotifications from "../jira/JiraNotifications";
import LinkSpecimenEmail from "./manage_templates/manage_inbox/link_specimen_email/LinkSpecimenEmail";

export default function Email({
                                  specimenLinkedEmails,
                                  specimenUnlinkedEmails,
                                  supportEmailMessages,
                                  userRoles,
                                  newLinkedEmail,
                                  setNewLinkedEmail,
                                  newUnlinkedEmail,
                                  setNewUnlinkedEmail,
                                  newSpecimenNotes,
                                  setNewSpecimenNotes,
                                  setSpecimenShowEmailModal,
                                  setEmailTemplateOptions,
                                  emailTemplateOptions,
                                  setFilteredTemplates,
                                  filteredTemplates,
                                  selectedTemplate,
                                  setSelectedTemplate,
                              }) {
    const [currentTab, setCurrentTab] = useState("manage-inbox");
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [isSendEmailTemplateActive, setIsSendEmailTemplateActive] = useState(false);
    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState([])

    const handleTabClick = (tab) => {
        setCurrentTab(tab);
    };


    const handleItemClick = (message) => {
        setSelectedMessage(message);
        setShowNoteModal(true);

    };

    const handleCloseLinkSpecimenEmail = () => {
        setSelectedMessage(null);
        setShowNoteModal(false)
    };
    const handleOpenSendEmailModal = () => {
        setIsSendEmailModalOpen(true);
    };


    return (
        <div>
            {showNoteModal && selectedMessage && (
                <div
                    className="add-email-notes-modal">
                    <LinkSpecimenEmail
                        specimenId={selectedMessage.select_screen_id}
                        userRoles={userRoles}
                        onClose={handleCloseLinkSpecimenEmail}
                        handleItemClick={handleItemClick}
                        showNoteModal={showNoteModal}
                        setShowNoteModal={setShowNoteModal}
                        handleOpenSendEmailModal={handleOpenSendEmailModal}
                        setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        setEmailTemplateOptions={setEmailTemplateOptions}
                        emailTemplateOptions={emailTemplateOptions}
                        filteredTemplates={filteredTemplates}
                        setFilteredTemplates={setFilteredTemplates}
                        select_screen_id={selectedMessage.select_screen_id}
                        isSendEmailModalOpen={isSendEmailModalOpen}
                        isSendEmailTemplateActive={isSendEmailTemplateActive}
                        setSpecimenShowEmailModal={setSpecimenShowEmailModal}
                    />
                </div>
            )}
            <div className="email-container">
                <div className="email-tabs-container">
                    <div className="email-tabs">
                        <div
                            className={`email-tab ${currentTab === "manage-templates" ? "active" : ""}`}
                            onClick={() => handleTabClick("manage-templates")}
                        >
                            MANAGE TEMPLATES
                        </div>
                        <div
                            className={`email-tab ${currentTab === "manage-inbox" ? "active" : ""}`}
                            onClick={() => handleTabClick("manage-inbox")}
                        >
                            INBOX
                        </div>
                        <div
                            className={`email-tab ${currentTab === "manage-hubspot-tickets" ? "active" : ""}`}
                            onClick={() => handleTabClick("manage-hubspot-tickets")}
                        >
                            HUBSPOT TICKETS
                        </div>
                        <div
                            className={`email-tab ${currentTab === "manage-jira-notifications" ? "active" : ""}`}
                            onClick={() => handleTabClick("manage-jira-notifications")}
                        >
                            JIRA NOTIFICATIONS
                        </div>
                    </div>
                    <div className="email-content">
                        {currentTab === "manage-templates" && (
                            <ManageTemplates
                                emailTemplateOptions={emailTemplateOptions}
                                setEmailTemplateOptions={setEmailTemplateOptions}
                                setFilteredTemplates={setFilteredTemplates}
                                filteredTemplates={filteredTemplates}
                            />
                        )}
                        {currentTab === "manage-inbox" &&
                            <ManageInbox specimenLinkedEmails={specimenLinkedEmails}
                                         specimenUnlinkedEmails={specimenUnlinkedEmails}
                                         supportEmailMessages={supportEmailMessages}
                                         setSelectedMessage={setSelectedMessage}
                                         handleItemClick={handleItemClick}
                                         newLinkedEmail={newLinkedEmail}
                                         setNewLinkedEmail={setNewLinkedEmail}
                                         newUnlinkedEmail={newUnlinkedEmail}
                                         setNewUnlinkedEmail={setNewUnlinkedEmail}
                                         newSpecimenNotes={newSpecimenNotes}
                                         setNewSpecimenNotes={setNewSpecimenNotes}
                            />}
                        {currentTab === "manage-hubspot-tickets" &&
                            <HubspotTickets userRoles={userRoles} selectedAccountId={selectedAccountId}/>}
                        {currentTab === "manage-jira-notifications" &&
                            <JiraNotifications/>}
                    </div>
                </div>
            </div>
        </div>

    );
}
