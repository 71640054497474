import React from 'react';
import './BatchCard.css';
import ActionItems from "./active_card/ActionItems";
import AgreementFrame from "./active_card/AgreementFrame";
import Inspectors from "./Inspectors";
import BatchData from "./BatchData";


export default function BatchCard({batchInfo, token}) {

    if (batchInfo.batch_status === 2) {
        return (
            <div style={{backgroundColor: "#FFD2DA"}} className="batch-card-main-body">
                <div className="batch-card-data-frame">
                    <BatchData batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <Inspectors token={token} batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <ActionItems batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <AgreementFrame batchInfo={batchInfo}/>
                </div>
            </div>
        )
    } else if (batchInfo.batch_status === 1) {
        return(
            <div className="batch-card-main-body">
                <div className="batch-card-data-frame">
                    <BatchData batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <Inspectors token={token} batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <ActionItems batchInfo={batchInfo} />
                    <div className="batch-card-horizontal-line-break"/>
                    <AgreementFrame batchInfo={batchInfo}/>
                </div>
            </div>
        )
    } else if (batchInfo.batch_status === null) {
        return(
            <div style={{backgroundColor: "#FFD2DA"}} className="batch-card-main-body">
                <div className="batch-card-data-frame">
                    <BatchData batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <Inspectors token={token} batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <ActionItems batchInfo={batchInfo}/>
                    <div className="batch-card-horizontal-line-break"/>
                    <AgreementFrame batchInfo={batchInfo}/>
                </div>
            </div>
        )
    } else {
        return null
    }
}



