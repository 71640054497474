import React, {useEffect} from 'react';
import {socket} from '../socket';
import './MonitorFreddy.css'
import LatestSelectScreenSides from "./latest_select_screen/LatestSelectScreenSides";


export default function MonitorFreddy({latestSelectScreen, updateLatestSelectScreen}) {


    useEffect(() => {
        // open socket connection
        socket.emit('latest-select-screen');

        // handle incoming data
        const handleData = (data) => {
            updateLatestSelectScreen(data);
        }
        socket.on('latest-select-screen-data', handleData);

        // close socket connection on unmount
        return () => {
            socket.off('latest-select-screen-data', handleData);
            socket.disconnect();
        }
    }, []);

    return(
        <div className="monitor-freddy-main-grid">
            {
                latestSelectScreen ?
                    latestSelectScreen.map((sideInfo) => {
                        return (
                            <div className="latest-select-screen-sides">
                                <LatestSelectScreenSides key={sideInfo} sideInfo={sideInfo}/>
                            </div>

                        )
                    }) : null
            }
        </div>
    )
}
