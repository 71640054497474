import React from 'react';
import './LoadingSpinningCup.css'

export default function LoadingSpinningCup() {
    return (
        <center>
            <div className="loading-table-container">
                <div className="loading-table-image-container">
                    <img className="loading-table-image" src="https://static.wixstatic.com/media/40f727_4dcbd9810a6b414eaa085da9721d50e3~mv2.gif" alt="Loading Animation" />
                </div>
            </div>
        </center>
    )
}
