import "./LabBillingReports.css"
import "rsuite/dist/rsuite.min.css";
import makeAnimated from 'react-select/animated';
import "rsuite/dist/rsuite.min.css";
import React, {useEffect, useState} from "react";
import {default as axios} from "axios";
import {subDays, subMonths} from 'date-fns';
import {useNavigate} from "react-router-dom";
import LoadingTable from "../../Loading/LoadingTable";
import {config} from "../../Constants";
import SpecimenModalOverlays from "../../reports/lab_billing_reports/specimen_modal_overlays/SpeicmenModalOverlays";
import ReportTabs from "../../reports/ReportTabs";
import LabBillingReportHeader from "./lab_billing_report_header/LabBillingReportHeader";
import BillingSummaryTreeTable from "./billing_summary_tree/BillingSummaryTree";


function LabBillingReports({
                               userRoles,
                               setErrorMessage,
                               setSuccessMessage,
                               setLabStatusScreens,
                               labStatusScreens,
                               setSortedScreens,
                               setSpecimenShowEmailModal,
                           }) {
    const accessToken = localStorage.getItem("accessToken");
    const url = config.url.FREDDY_API_URL;
    const [clientOptions, setClientOptions] = useState([]);
    const [selectDateOptions, setSelectDateOptions] = useState([]);
    const [selectClientOptions, setSelectClientOptions] = useState([]);
    const [invoiceSummaryUrl, setInvoiceSummaryUrl] = useState('');
    const [billingSummaryUrl, setBillingSummaryUrl] = useState('');
    const [billingSummaryDetails, setBillingSummaryDetails] = useState([]);
    const updateBillingSummaryDetails = billingSummaryDetails => {
        setBillingSummaryDetails(billingSummaryDetails)
    }
    const [loadingTable, setLoadingTable] = useState(false)
    const updateLoadingTable = () => setLoadingTable(value => !value)
    const [download, setDownload] = useState(false);
    const updateDownload = () => setDownload(value => !value);
    const [invoiceDownload, setInvoiceDownload] = useState(false);
    const [labErrorStatusScreens, setLabErrorStatusScreens] = useState([]);
    const updateInvoiceDownload = () => setInvoiceDownload(value => !value);
    const hiddenFileInput = React.useRef(null);
    const animatedComponents = makeAnimated();
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [isSendEmailTemplateActive, setIsSendEmailTemplateActive] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(false);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [recentSpecimenIds, setRecentSpecimenIds] = useState([]);
    const [selectedSpecimenIds, setSelectedSpecimenIds] = useState([]);
    const [isErrorScreenTableAlert, setIsErrorScreensTableAlert] = useState(false);
    const [isAttentionRequiredLabScreensTableActive, setIsAttentionRequiredLabScreensTableActive] = useState(false);
    const [isAttentionRequiredLabScreensTableAlert, setIsAttentionRequiredLabScreensTableAlert] = useState(false);


    useEffect(() => {
        if (searchTerm) {
            const filtered = emailTemplateOptions.filter(
                (template) =>
                    template.email_template_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredTemplates(filtered);
        } else {
            setFilteredTemplates(emailTemplateOptions);
        }
    }, [emailTemplateOptions, searchTerm]);


    const loadClientOptions = () => {
        const axios = require('axios').default;
        axios({
            method: "POST",
            baseURL: url,
            url: "/get-client-options",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            setClientOptions(response.data)
        })).catch(error => {
            console.error('There was an error!', error.message);
            setErrorMessage("There was an error!", error.message)
        });
    }

    useEffect(() => loadClientOptions(), []);


    function getErrorLabScreens() {
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-error-screens",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            const res = response.data
            setLabErrorStatusScreens(res)
            setIsErrorScreensTableAlert(res.length > 0);
        })).catch(error => {
            error.parentElement.innerHTML = `Error: ${error.message}`;
            console.error('There was an error!', error);
        });
    }


    useEffect(() => getErrorLabScreens(), []);




    const loadRecentSpecimenIds = () => {
        const axios = require('axios').default;
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-recent-specimen-id",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                setRecentSpecimenIds(response.data);
            })
            .catch(error => {
                setErrorMessage("There was an error!", error)
                console.error('There was an error!', error);
            });
    };

    useEffect(() => loadRecentSpecimenIds(), []);


    const handleClientChange = (selectedOption) => {
        setSelectClientOptions(selectedOption)
    };

    const handleDateChange = (e) => {
        setSelectDateOptions(e)
    };


    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];

    const [isBillingReportsTableActive, setIsBillingReportsTableActive] = useState(true);

    function goToBillingSummaryTable() {
        setIsBillingReportsTableActive(true)
    }


    const handleSpecimenIdSearch = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedSpecimenIds(selectedIds);
    };


    // Calculate the height of the virtualized list
    const calculateListHeight = () => {
        // Adjust this value to determine the desired height of each option
        const optionHeight = 35;
        // Adjust this value to determine the desired number of visible options
        const visibleOptions = 5;
        return optionHeight * visibleOptions;
    };



    return (
        <div className="lab-billing-reports-main-frame">
            <SpecimenModalOverlays
                showNoteModal={showNoteModal}
                userRoles={userRoles}
                selectedRow={selectedRow}
                setShowNoteModal={setShowNoteModal}
                setSelectedRow={setSelectedRow}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setIsSendEmailTemplateActive={setIsSendEmailTemplateActive}
                emailTemplateOptions={emailTemplateOptions}
                setEmailTemplateOptions={setEmailTemplateOptions}
                selectedTemplate={selectedTemplate}
                filteredTemplates={filteredTemplates}
                setSelectedTemplate={setSelectedTemplate}
                setFilteredTemplates={setFilteredTemplates}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                labStatusScreens={labStatusScreens}
                setSortedScreens={setSortedScreens}
                showEmailModal={showEmailModal}
                setBillingSummaryUrl={setBillingSummaryUrl}
                setSpecimenShowEmailModal={setSpecimenShowEmailModal}
            />
            <ReportTabs
                goToBillingSummaryTable={goToBillingSummaryTable}
                isBillingReportsTableActive={isBillingReportsTableActive}
            />

            {
                isBillingReportsTableActive ?
                    <LabBillingReportHeader
                        loadingTable={loadingTable}
                        updateLoadingTable={updateLoadingTable}
                        invoiceDownload={invoiceDownload}
                        invoiceSummaryUrl={invoiceSummaryUrl}
                        setInvoiceSummaryUrl={setInvoiceSummaryUrl}
                        setInvoiceDownload={setInvoiceDownload}
                        selectDateOptions={selectDateOptions}
                        handleDateChange={handleDateChange}
                        lastWeek={lastWeek}
                        lastMonth={lastMonth}
                        last3Months={last3Months}
                        clientOptions={clientOptions}
                        handleClientChange={handleClientChange}
                        selectClientOptions={selectClientOptions}
                        download={download}
                        updateBillingSummaryDetails={updateBillingSummaryDetails}
                        setBillingSummaryUrl={setBillingSummaryUrl}
                        updateDownload={updateDownload}
                        setErrorMessage={setErrorMessage}
                        selectedSpecimenIds={selectedSpecimenIds}
                    />
                    : null
            }

            {isBillingReportsTableActive ?
                <div className="lab-billing-reports-body">
                    <div className="live-screens-table-frame">
                        {
                            loadingTable ? <LoadingTable/> :
                                <BillingSummaryTreeTable billingSummaryDetails={billingSummaryDetails}
                                                         calculateListHeight={calculateListHeight}
                                />
                        }
                    </div>
                </div> :
                null
            }
        </div>
    )

}

export default LabBillingReports;