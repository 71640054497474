import './App.css';
import './components/font/Geomanist-Regular.ttf';
import './components/font/Comfortaa-Bold.ttf';
import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router, Navigate} from 'react-router-dom';
import {MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import Login from './components/user/login/Login';
import jwt_decode from "jwt-decode";
import Profile from "./components/user/profile/Profile";
import LinkedApp from "./components/LinkedApp";
import {default as axios} from "axios";
import {config} from "./components/Constants";
import {msalInstance} from "./index";
import SuccessMessage from "./components/messages/success/SuccessMessage";
import ErrorMessage from "./components/messages/error/ErrorMesasge";
import LoadingTable from "./components/Loading/LoadingTable";
// import PushNotification from "react-push-notification";
import {sendPushNotification} from "./components/user/notifications/push_notificaitons/PushNotificaiotnUtils";



function App({props}) {
    const url = config.url.FREDDY_API_URL;
    const [isAccessTokenExpired, setIsAccessTokenExpired] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [specimenUnlinkedEmails, setSpecimenUnlinkedEmails] = useState([]);
    const [specimenLinkedEmails, setSpecimenLinkedEmails] = useState([]);
    const [supportEmailMessages, setSupportEmailMessages] = useState([]);
    const [unprocessedEmails, setUnprocessedEmails] = useState([]);
    const exclusionsEmailList = [''];
    const exclusionsSubjectList = ['Daily list of registered specimens'];
    const [newLinkedEmail, setNewLinkedEmail] = useState([]);
    const [newUnlinkedEmail, setNewUnlinkedEmail] = useState([]);
    const [newSpecimenNotes, setNewSpecimenNotes] = useState([]);
    const [isMicrosoftAccountLinked, setIsMicrosoftAccountLinked] = useState();
    const [userLoginStatus, setUserLoginStatus] = useState(false);
    const [userNotifications, setUserNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState([]);
    const [selectedNotificationDetails, setSelectedNotificationDetails] = useState([]);
    const [specimenNotificationNotes, setSpecimenNotificationNotes] = useState([]);
    const [specimenUserNotifications, setSpecimenUserNotifications] = useState([]);
    const [selectSpecimenId, setSelectSpecimenId] = useState([]);
    const [labStatusScreens, setLabStatusScreens] = useState([]);
    const [sortedScreens, setSortedScreens] = useState([]);
    const [mroReviewScreens, setMroReviewScreens] = useState([{},]);
    const [labTestCreatingScreens, setLabTestCreatingScreens] = useState([]);
    const [labTestCreatedScreens, setLabTestCreatedScreens] = useState([]);
    const [labTestCompletedScreens, setLabTestCompletedScreens] = useState([]);
    const [labTestCancelledScreens, setLabTestCancelledScreens] = useState([]);
    const [approvedScreens, setApprovedScreens] = useState([]);
    const [completeScreens, setCompleteScreens] = useState([]);
    const [sendToLabScreens, setSendToLabScreens] = useState([]);
    const [sentToLabScreens, setSentToLabScreens] = useState([]);
    const [sentToMroScreens, setSentToMroScreens] = useState([]);
    const [sendToMroScreens, setSendToMroScreens] = useState([]);
    const [labTestReceivedScreens, setLabTestReceivedScreens] = useState([]);
    const [labDeliveredScreens, setLabDeliveredScreens] = useState([]);
    const [labTestTransitScreens, setLabTestTransitScreens] = useState([]);
    const [pendingUploadScreens, setPendingUploadScreens] = useState([]);
    const [pendingReviewScreens, setPendingReviewScreens] = useState([]);
    const [attentionRequiredScreens, setAttentionRequiredScreens] = useState([]);
    const [specimenShowEmailModal, setSpecimenShowEmailModal] = useState(false);
    const [showClientNoteModal, setShowClientNoteModal] = useState("")
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const prevUserNotifications = useRef([]);
    const [hasNewNotification, setHasNewNotification] = useState(false);

    const updateShowNotificationModal = () => {
        setShowNotificationModal(!showNotificationModal)
    }


    useEffect(() => {
        // Check if the access token exists
        if (accessToken) {
            // Decode the JWT token
            const tokenParts = accessToken.split('.');
            if (tokenParts.length === 3) {
                const tokenPayload = JSON.parse(atob(tokenParts[1]));

                // Extract the expiration time from the token payload
                const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds

                // Compare with the current time
                const currentTime = Date.now();
                const isExpired = expirationTime < currentTime;

                setIsAccessTokenExpired(isExpired);
            }
        } else {
            setIsAccessTokenExpired(true); // Token doesn't exist, consider it expired
        }
    }, [accessToken]);


    const updatedProps = {
        ...props,
        msalInstance: msalInstance,
        errorMessage: errorMessage,
        successMessage: successMessage,
        userRoles: userRoles,
        specimenUnlinkedEmails: specimenUnlinkedEmails,
        specimenLinkedEmails: specimenLinkedEmails,
        supportEmailMessages: supportEmailMessages,
        unprocessedEmails: unprocessedEmails,
        newLinkedEmail: newLinkedEmail,
        newUnlinkedEmail: newUnlinkedEmail,
        newSpecimenNotes: newSpecimenNotes,
        isMicrosoftAccountLinked: isMicrosoftAccountLinked,
        userLoginStatus: userLoginStatus,
        selectedNotification: selectedNotification,
        selectedNotificationDetails: selectedNotificationDetails,
        specimenNotificationNotes: specimenNotificationNotes,
        specimenUserNotifications: specimenUserNotifications,
        selectSpecimenId: selectSpecimenId,
        exclusionsEmailList: exclusionsEmailList,
        exclusionsSubjectList: exclusionsSubjectList,
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage,
        setUserRoles: setUserRoles,
        setSpecimenUnlinkedEmails: setSpecimenUnlinkedEmails,
        setSpecimenLinkedEmails: setSpecimenLinkedEmails,
        setSupportEmailMessages: setSupportEmailMessages,
        setUnprocessedEmails: setUnprocessedEmails,
        setNewLinkedEmail: setNewLinkedEmail,
        setNewUnlinkedEmail: setNewUnlinkedEmail,
        setNewSpecimenNotes: setNewSpecimenNotes,
        isAccessTokenExpired: isAccessTokenExpired,
        setIsMicrosoftAccountLinked: setIsMicrosoftAccountLinked,
        setUserLoginStatus: setUserLoginStatus,
        setSelectedNotification: setSelectedNotification,
        setSelectedNotificationDetails: setSelectedNotificationDetails,
        setSpecimenNotificationNotes: setSpecimenNotificationNotes,
        setSpecimenUserNotifications: setSpecimenUserNotifications,
        setSelectSpecimenId: setSelectSpecimenId,
        handleGetUserNotifications: handleGetUserNotifications,
        labStatusScreens: labStatusScreens,
        setLabStatusScreens: setLabStatusScreens,
        sortedScreens: sortedScreens,
        setSortedScreens: setSortedScreens,
        setMroReviewScreens: setMroReviewScreens,
        setLabTestCreatingScreens: setLabTestCreatingScreens,
        setLabTestCreatedScreens: setLabTestCreatedScreens,
        setLabTestTransitScreens: setLabTestTransitScreens,
        setLabDeliveredScreens: setLabDeliveredScreens,
        setLabTestReceivedScreens: setLabTestReceivedScreens,
        mroReviewScreens: mroReviewScreens,
        labTestCreatingScreens: labTestCreatingScreens,
        approvedScreens: approvedScreens,
        setApprovedScreens: setApprovedScreens,
        completeScreens: completeScreens,
        setCompleteScreens: setCompleteScreens,
        sendToLabScreens: sendToLabScreens,
        setSendToLabScreens: setSendToLabScreens,
        sentToLabScreens: sentToLabScreens,
        setSentToLabScreens: setSentToLabScreens,
        sentToMroScreens: sentToMroScreens,
        setSentToMroScreens: setSentToMroScreens,
        sendToMroScreens: sendToMroScreens,
        setSendToMroScreens: setSendToMroScreens,
        labTestReceivedScreens: labTestReceivedScreens,
        labDeliveredScreens: labDeliveredScreens,
        labTestTransitScreens: labTestTransitScreens,
        pendingUploadScreens: pendingUploadScreens,
        setPendingUploadScreens: setPendingUploadScreens,
        pendingReviewScreens: pendingReviewScreens,
        setPendingReviewScreens: setPendingReviewScreens,
        attentionRequiredScreens: attentionRequiredScreens,
        setAttentionRequiredScreens: setAttentionRequiredScreens,
        labTestCreatedScreens: labTestCreatedScreens,
        labTestCompletedScreens: labTestCompletedScreens,
        setLabTestCompletedScreens: setLabTestCompletedScreens,
        labTestCancelledScreens: labTestCancelledScreens,
        setLabTestCancelledScreens: setLabTestCancelledScreens,
        setSpecimenShowEmailModal: setSpecimenShowEmailModal,
        specimenShowEmailModal: specimenShowEmailModal,
        showClientNoteModal: showClientNoteModal,
        setShowClientNoteModal: setShowClientNoteModal,
        showNotificationModal: showNotificationModal,
        updateShowNotificationModal: updateShowNotificationModal,
        hasNewNotification: hasNewNotification,
        setHasNewNotification: setHasNewNotification,
    };

    // if (updatedProps.isAccessTokenExpired) {
    //     return <Navigate to="/login"/>;
    // }



    function getAccessToken() {
        if (accessToken === null) {
            setUserRoles([]);
            setIsAccessTokenExpired(true);
        } else {
            const decodedJwtToken = jwt_decode(accessToken);
            const rolesClaims = decodedJwtToken.roles;
            setUserRoles(rolesClaims);
            setIsAccessTokenExpired(false);
            getUsername();
        }
    }

    useEffect(() => {
        getAccessToken();
    }, [accessToken]);


    // useEffect(() => {
    //     // Function to trigger push notification on new notifications
    //     if (hasNewNotification) {
    //         sendPushNotification("New Notification", "You have a new notification!");
    //     }
    // }, [hasNewNotification]);


    async function handleGetUserNotifications() {
        try {
            const response = await axios({
                method: "POST",
                baseURL: url,
                url: "/get-user-notifications",
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
            if (response.status === 200) {
                // const newNotifications = response.data.specimen_user_notifications
                // const diffNotifications = newNotifications.filter(
                //     (notification) =>
                //         !prevUserNotifications.current.some(
                //             (prevNotification) =>
                //                 prevNotification.user_notification_id ===
                //                 notification.user_notification_id
                //         )
                // );
                //
                // if (diffNotifications.length > 0) {
                //     updatedProps.setHasNewNotification(true);
                // }
                updatedProps.setSpecimenNotificationNotes(response.data.specimen_notification_notes)
                updatedProps.setSpecimenUserNotifications(response.data.specimen_user_notifications)
            } else {
                updatedProps.setErrorMessage("An error occurred: " + response.data);
            }
        } catch (error) {
            updatedProps.setErrorMessage("An error occurred: " + error.message);
            console.log(error); // log the upload error

        }
    }

    useEffect(() => {
        handleGetUserNotifications()
        // Set up the interval
        const intervalId = setInterval(() => {
            handleGetUserNotifications();
        }, 15000); // 10000 milliseconds = 10 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    const getUsername = () => {
        axios({
            method: "GET",
            baseURL: url,
            url: "/get-linked-user-name",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            if (response.status === 200) {
                const isAccountLinked = response.data[0];
                updatedProps.setIsMicrosoftAccountLinked(isAccountLinked);
            } else {
                updatedProps.setIsMicrosoftAccountLinked(false);
            }

        })).catch(error => {
            updatedProps.setIsMicrosoftAccountLinked(false);
            updatedProps.setErrorMessage('There was an error!', error)
            console.error('There was an error!', error);
        });
    };

    function linkMicrosoftAccount() {
        return (
            <div>
                {
                    updatedProps.isMicrosoftAccountLinked ?
                        <>
                            {
                                errorMessage ?
                                    <ErrorMessage errorMessage={errorMessage} onClose={() => setErrorMessage('')}/> : null
                            }

                            {
                                successMessage ?
                                    <SuccessMessage successMessage={successMessage}
                                                    onClose={() => setSuccessMessage('')}/> : null
                            }
                            <LinkedApp updatedProps={updatedProps} />
                        </> : <LoadingTable />
                }
            </div>
        );
    }


    return (
        <MsalProvider instance={msalInstance}>
            <Router>
                <div>
                    <AuthenticatedTemplate>
                        {linkMicrosoftAccount()}
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <center>
                            <div>
                                <h4>
                                    <Login instance={msalInstance}  {...updatedProps} />
                                </h4>
                            </div>
                        </center>
                    </UnauthenticatedTemplate>
                </div>
            </Router>
        </MsalProvider>
    );
}

export default App;
