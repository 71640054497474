import React from "react";


import './InspectorRead.css'

export default function InspectorRead({inspector_results}) {

    return (
            <h3 key={inspector_results.id}
                className="screen-info-result">{inspector_results[0]} - {inspector_results[1]}</h3>
    )
}

