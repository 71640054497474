import React from 'react';
import './ReviewPastBatch.css';
import {useNavigate} from "react-router-dom";
import {config} from "../../Constants";
import InspectorAgreement from "./InspectorAgreement";


export default function ReviewPastBatch({nextTestInfo, updateNextTest}) {
    const navigate = useNavigate();
    const url = config.url.FREDDY_API_URL;
    const closeReview = () => {
        navigate('/reports');
    };


    function handleNext(e) {
        const formData = new FormData();
        const axios = require('axios').default;
        const accessToken = localStorage.getItem("accessToken");
        formData.set('batch_position', nextTestInfo.batch_position);
        formData.set('batch_report_id', nextTestInfo.batch_report_id);
        formData.set('select_test_id', nextTestInfo.select_test_id);
        e.preventDefault();
        axios({
            method: "POST",
            baseURL: url,
            url: "/next-screen",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            updateNextTest(({
                select_test_status: response.data[0].select_test_status,
                total_inspectors: response.data[0].total_inspectors,
                inspectors_reviewed: response.data[0].inspectors_reviewed,
                batch_position: response.data[0].batch_position,
                select_test_id: response.data[0].select_test_id,
                batch_report_id: response.data[0].batch_report_id,
                kit_count: response.data[0].kit_count,
                freddy_version: response.data[0].freddy_version,
                create_date: response.data[0].create_date,
                select_inference_status: response.data[0].select_inference_status,
                select_test_url: response.data[0].select_test_url
            }))
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }


    function handlePrevious(e) {
        const formData = new FormData();
        const axios = require('axios').default;
        const accessToken = localStorage.getItem("accessToken");
        formData.set('batch_position', nextTestInfo.batch_position);
        formData.set('batch_report_id', nextTestInfo.batch_report_id);
        formData.set('select_test_id', nextTestInfo.select_test_id);
        e.preventDefault();
        axios({
            method: "POST",
            baseURL: url,
            url: "/previous-screen",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            updateNextTest(({
                total_inspectors: response.data[0].total_inspectors,
                inspectors_reviewed: response.data[0].inspectors_reviewed,
                batch_position: response.data[0].batch_position,
                select_test_id: response.data[0].select_test_id,
                batch_report_id: response.data[0].batch_report_id,
                kit_count: response.data[0].kit_count,
                freddy_version: response.data[0].freddy_version,
                create_date: response.data[0].create_date,
                select_inference_status: response.data[0].select_inference_status,
                select_test_url: response.data[0].select_test_url
            }))
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    return (
        <div className="review-past-batch-main-body">
            <button onClick={closeReview} className="review-past-batch-close-button">
                <svg className="review-past-batch-close-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <div className="review-past-batch-data-frame">
                <div className="review-past-batch-spacer"/>
                <div className="review-past-batch-id">
                    <h2 className="review-past-batch-header-text" style={{
                        fontSize: "22px",
                        display: "inline",
                        color: "#58585A"
                    }}>{nextTestInfo.select_test_id}</h2>
                    <br/>
                    <h2 className="review-past-batch-header-text" style={{
                        fontSize: "22px",
                        display: "inline",
                        color: "#58585A"
                    }}>{nextTestInfo.batch_position}</h2>
                    <h2 className="review-past-batch-header-text">of {nextTestInfo.kit_count} scans</h2>
                </div>
                <div className="review-past-batch-id">
                    <h2 className="review-past-batch-header-text" style={{
                        fontSize: "22px",
                        display: "inline",
                        color: "#58585A"
                    }}>{nextTestInfo.batch_report_id}</h2>
                    <h2 className="review-past-batch-header-text">.FV{nextTestInfo.freddy_version}</h2>
                </div>
                <div className="review-past-batch-id">
                    <div className="review-past-date">
                        <h4 className="review-past-batch-sub-text">{nextTestInfo.create_date}</h4>
                    </div>
                </div>
                <div className="review-past-batch-id">
                    <h2 className="review-past-batch-header-text" style={{
                        fontSize: "22px",
                        display: "inline",
                        color: "#58585A"
                    }}>{nextTestInfo.select_inference_status}</h2>
                </div>
                <div className="review-past-batch-id">
                    <h2 className="review-past-batch-header-text">Freddy's Analysis</h2>
                </div>
                <div className="review-past-inspectors-frame">
                    <InspectorAgreement nextTestInfo={nextTestInfo}/>
                </div>
                <div className="review-past-action-button-grid">
                    <div className="review-active-action-button-row">
                        <form onSubmit={handlePrevious}>
                            <div className="review-past-action-button-frame">
                                <button className="review-past-batch-button" name="previous" type="submit">PREVIOUS
                                </button>
                            </div>
                        </form>
                        <form onSubmit={handleNext}>
                            <div className="review-past-action-button-frame">
                                <button className="review-past-batch-button" name="next" type="submit">NEXT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}
