import React from 'react';
import './ReadFilter.css';

export default function ReadFilter() {


    return (
        <div className="read-filter-items-main-body">
            <div className="read-filter-items-main-frame">
                <button className="read-filter-button"><h3 className="read-filter-text">ACTIVE ONLY</h3></button>
                <button className="read-filter-button"><h3 className="read-filter-text">ALL SCANS</h3></button>
            </div>
        </div>
    )
}
