import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList } from 'react-window';

const ClientSearchComponent = ({
                                   allClients,
                                   handleClientSearchSubmit,
                                   handleClientIdSearch,
                               }) => {
    // Calculate the height of the virtualized list
    const calculateListHeight = () => {
        // Adjust this value to determine the desired height of each option
        const optionHeight = 35;
        // Adjust this value to determine the desired number of visible options
        const visibleOptions = 10;
        return optionHeight * visibleOptions;
    };

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleClientSearchSubmit();
                }}
            >
                <div className="labb-clients-search">
                    {allClients ? (
                        <CreatableSelect
                            components={{
                                MenuList: (props) => {
                                    const { options, children, maxHeight, getValue } = props;
                                    const [value] = getValue();
                                    const initialOffset = options.indexOf(value) * 35;

                                    return (
                                        <FixedSizeList
                                            height={calculateListHeight()} // Invoke calculateListHeight function
                                            itemCount={options.length}
                                            itemSize={35}
                                            initialScrollOffset={initialOffset}
                                            width="100%" // Assign a valid width value here
                                        >
                                            {({ index, style }) => (
                                                <div style={style}>{children[index]}</div>
                                            )}
                                        </FixedSizeList>
                                    );
                                },
                            }}
                            placeholder="Search Client..."
                            isClearable
                            closeMenuOnSelect={true}
                            isMulti
                            onChange={handleClientIdSearch}
                            options={allClients.map(({ client_name, id, uuid }) => ({
                                label: client_name,
                                value: id,
                            }))}
                            className="search-input"
                        />
                    ) : null}
                    <button type="submit" className="search-button">
                        SEARCH
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ClientSearchComponent;

