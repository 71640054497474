import React, {useEffect, useState} from "react";
import './ActionItems.css'
import { useNavigate } from 'react-router-dom';
import {config} from "../../../Constants";

export default function ActionItems({batchInfo}) {
    const navigate = useNavigate();
    const [batchExport, setBatchExport] = useState([]);
    const [download, setDownload] = useState(false);
    const updateDownload = () => setDownload(value => !value);
    const url = config.url.FREDDY_API_URL;

    const handleViewBatch = () => {
        navigate('/past-batch', { state: { batch_report_id: batchInfo.batch_report_id } });
    };

    function handleSuccess() {
        updateDownload()
    }

    const handleReviewBatch = () => {
        navigate('/active-batch', { state: { batch_report_id: batchInfo.batch_report_id } });
    };

    const handleViewBatchSummary = () => {
        navigate('/batch-summary', { state: { batch_report_id: batchInfo.batch_report_id } });
    };


    function handleDownloadBatchSummary() {
        const formData = new FormData();
        const element = document.querySelector('#post-request-error-handling .formData-id');
        const axios = require('axios').default;
        formData.set('batch_report_id', batchInfo.batch_report_id);
        const accessToken = localStorage.getItem("accessToken");
        axios({
            method: "POST",
            baseURL: url,
            url: "/export-batch-summary",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            const res = response.data
            setBatchExport(res)
        })).then(updateDownload).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    if (download) {
        return (
            <div className="batch-card-review-action-items-body">
                <button onClick={handleViewBatch} className="batch-card-action-button">
                    VIEW
                </button>
                <button onClick={handleViewBatchSummary} className="batch-card-action-summary-text">
                    SUMMARY
                </button>
                <a className="batch-card-action-download-text" href={batchExport} download onClick={handleSuccess}>
                    DOWNLOAD NOW
                </a>
            </div>
        );
    }

    const status = batchInfo.batch_status === 2 ? 'review' : 'view';

    return (
        <div className="batch-card-action-items-body">
            <button onClick={handleReviewBatch} className="batch-card-action-button">
                {status.toUpperCase()}
            </button>
            <button onClick={handleViewBatchSummary} className="batch-card-action-summary-text">
                SUMMARY
            </button>
            <button onClick={handleDownloadBatchSummary} className="batch-card-action-download-text">
                DOWNLOAD
            </button>
        </div>
    );

}
