import React, {useEffect, useState} from 'react';
import './ReadHistory.css';
import ReadTiles from "../read_tiles/ReadTiles";
import HistoryActionItems from "./HistoryActionItems";
import {config} from "../../Constants";
import LoadingTable from "../../Loading/LoadingTable";

export default function ReadHistory() {
    const [readHistoryInfo, setReadHistoryInfo] = useState("")
    const url = config.url.FREDDY_API_URL


    useEffect(() => getReadHistory(), []);


    const getReadHistory = () => {
        const axios = require('axios').default;
        const element = document.querySelector('#post-request-error-handling .formData-id');
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
        axios({
            method: "GET",
            baseURL: url,
            url: "/read-history",
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then(((response) => {
            const res = response.data
            setReadHistoryInfo(res)
        })).catch(error => {
            window.alert("An error occurred: " + error.message);
            console.error('There was an error!', error);
        });
    }

    if (readHistoryInfo) {

        return (
            <div className="results-history-main-body">
                <HistoryActionItems />
                <ReadTiles readHistory={readHistoryInfo}/>
            </div>
        )

    } else {
        return (
            <LoadingTable />
        )
    }
}
