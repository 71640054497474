import './LabbClient.css'

const LabbClient = ({clientDetails}) => {

    return (
        <div>
            <div className="client">
                <div className="client_header">
                    <p className="client_header_text">Client</p>
                </div>
                <div className="dark_grey_strip"/>
                <div>
                    <p className="client_info_text">Client:</p>
                    <p className="client_info_text_input">{clientDetails?.client_name}</p>
                </div>

                <div className="client_info_section">
                    <p className="client_info_text">Address:</p>
                    <p>{clientDetails?.client_address_line_one}</p>
                    <p>{clientDetails?.client_address_line_two}</p>
                    <p>{clientDetails?.client_city}</p>
                    <p>{clientDetails?.client_state}</p>
                    <p>{clientDetails?.client_zip_code}</p>
                </div>

                <div className="client_info_section">
                    <p className="client_info_text">Reseller:</p>
                    <p>{clientDetails?.reseller_name}</p>
                </div>
            </div>
        </div>
    )
}

export default LabbClient;

