import React from 'react';
import './ReadScan.css';
import DrugsGrid from "../drug_map/DrugsGrid";

export default function ReadScan({side, readInfo, imgUrl, index, checkedItems, updateCheckedItems}) {


    return (
        <div className="read-scan-main-body">
            <div className="read-scan-image-frame">
                <div className="read-scan-side-header-text-frame">
                    <h3 className="read-scan-side-header-text">SIDE {side}</h3>
                </div>
                <img className="read-scan-image" src={imgUrl[index]}/>
            </div>
            <div className="read-scan-drugs-grid-frame">
                <DrugsGrid checkedItems={checkedItems} updateCheckedItems={updateCheckedItems} readInfo={readInfo}/>
            </div>
        </div>
    )
}
