import React from 'react';
import './AddBatch.css';
import {config} from "../../../Constants";
import {socket} from "../../../socket";
// import {getAccessToken} from "../../../user/GetToken";

export default function AddBatch({
                                     token,
                                     selectFreddyVersion,
                                     selectFreddyDataset,
                                     batchSize,
                                     setProcessingStatus,
                                     isProcessing,
                                     isPolling,
                                     setPollingStatus,
                                     batchInfo
                                 }) {
    const url = config.url.FREDDY_API_URL
    const formData = new FormData();
    const axios = require('axios').default;
    formData.set('freddy_version', selectFreddyVersion);
    formData.set('freddy_dataset', selectFreddyDataset);
    formData.set('batch_size', batchSize);

    // if (isPolling) {
    //     setInterval(pollCreateBatch, 7000);
    //     console.log("polling will begin now")
    // }

    async function pollCreateBatch() {
        const accessToken = localStorage.getItem("accessToken");
        const batchId = new FormData();
        batchId.set('batch_id', batchInfo[0]['batch_report_id']);
        axios({
            method: "POST",
            baseURL: url,
            url: "/poll-create-batch",
            data: batchId,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then((response) => {
            if (response) {
                setProcessingStatus(!isProcessing)
            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })

    }

    async function createBatch() {
        const accessToken = await getAccessToken();
        setProcessingStatus(true)
        axios({
            method: "POST",
            baseURL: url,
            url: "/create-batch",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }).then((response) => {
            if (response) {
                setPollingStatus(!isPolling)
            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })
    }

    if (selectFreddyVersion && selectFreddyDataset && batchSize) {
    return (
        <div className="gen-card-batch-data">
            <div className="gen-card-batch-new-batch">
                <h3 className="gen-card-batch-new-batch-text">ADD</h3>
                    <button onClick={createBatch} className="gen-card-batch-generate-batch">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5V19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M5 12H19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </button>

            </div>
        </div>
    )
    } else {

    }

}
