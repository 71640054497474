import './LatestSelectScreen.css';
import React from "react";

function LatestSelectScreen({selectScreen}) {
    return (
        <div className="select-screen-card-main-body">
            <div>
                <div className="select-screen-header-spacer"/>
                <center>
                    <img className="select-screen-card-image" src={selectScreen[0].img_url} alt="Kit"/>
                    <div className="select-screen-card-text-frame">
                        <center>
                            <div>
                                <h4 className="select-screen-card-test-id">SD0{selectScreen[0].select_screen_id}</h4>
                                <h4 className="select-screen-card-text">FREDDY ANALYSIS</h4>
                                <h4 className="select-screen-card-initial-result">{selectScreen[0].initial_result}</h4>
                            </div>
                        </center>
                    </div>
                </center>
            </div>
        </div>
    )
}

export default LatestSelectScreen;