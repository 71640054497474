import React, {useState} from 'react';
import './LabbClientTables.css';
import LabbClientSettingsTable from "./labb_client_settings_table/LabbClientSettingsTable";

const LabbClientTables = ({
                              allClientData,
                              handleClientClick,
                              setShowTicketModal,
                              handleClientName,
                              setShowClientEmailModal,
                          }) => {

    return (
        <div className="client-tables-container">
            <div className="client-tables-settings">
                <LabbClientSettingsTable
                    allClientData={allClientData}
                    handleClientClick={handleClientClick}
                    setShowTicketModal={setShowTicketModal}
                    handleClientName={handleClientName}
                    setShowClientEmailModal={setShowClientEmailModal}
                />
            </div>
        </div>
    );
};

export default LabbClientTables;
